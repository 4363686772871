import { useMemo, useState, useEffect } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';

import services from '../utils/services';
import { Chat } from './Chat';
import { CHAT_TYPES } from './constants';

export const useLastMessage = (chat) => {
  const [lastMessageData] = useCollectionData(
    chat && chat.getLastMessageQuery(),
  );
  const lastMessage = useMemo(() => lastMessageData?.[0], [lastMessageData]);

  return lastMessage;
};

/**
 * Finds or creates a chat instance.
 * @param {DocumentReference} guestRef DocumentReference for the chat guest (client or profile).
 * @param {string} type Type of the chat
 * @returns {Chat}
 */
export const useChatInstance = (guestRef, type = CHAT_TYPES.CONSULTANT) => {
  const [chat, setChat] = useState(() => {
    const doc = services.get('db').collection('chats').doc();
    return Chat.fromId(doc.id, {
      draft: true,
      guestRef,
      type,
    });
  });

  useEffect(() => {
    return Chat.getChatQuery(type, guestRef).onSnapshot((snaps) => {
      if (snaps.size) {
        const foundChat = Chat.fromSnapshot(snaps.docs[0]);
        if (foundChat.id !== chat?.id) {
          setChat(foundChat);
        }
      }
    });
  }, [guestRef, type, chat, setChat]);

  return chat;
};
