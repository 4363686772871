import { Button, Typography } from 'antd';
import styled from 'styled-components';
import { useMemo } from 'react';

import { MailLarge, Spinner } from '@yougig/ui/icons';
import { Assessment } from '@yougig/shared/talents/Assessment';

import { AssessmentList } from './AssessmentList';
import { useTalent } from '../../../components/common/TalentProvider';

const SpinnerContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  color: var(--c_description);
`;

const SpinnerAnimated = styled(Spinner)`
  animation: spin 1s infinite;
  width: 16px;
  height: 16px;
`;

const Content = styled.div`
  margin-top: 32px;
`;

const MailIcon = styled(MailLarge)`
  display: block;
  margin: 40px auto 0;
  font-size: 114px;
`;

const CenteredParagraph = styled(Typography.Paragraph)`
  &.ant-typography {
    text-align: center;
    font-size: 18px;
    line-height: 1.4;
    margin-top: 32px;
    margin-bottom: 32px;
  }
`;

export const StartAssessments = ({ onDone, taken }) => {
  const { profile } = useTalent();
  const { data, isLoading } = Assessment.useAssessmentsList();
  const {
    isLoading: isSubmitting,
    isSuccess,
    error,
    sendInvite,
  } = Assessment.useSendInvite(profile);

  const filtered = useMemo(
    () => data?.filter((i) => !taken.includes(i.id)),
    [data, taken],
  );

  if (isSuccess) {
    return (
      <div className="drawer-section">
        <Typography.Title level={3}>Invitation is sent</Typography.Title>
        <MailIcon />
        <CenteredParagraph>
          Thank you for your application.
          <br />
          <br />
          An invitation has been sent to your email. To start the test, follow
          the link in the letter.
        </CenteredParagraph>
        <Button type="ghost" block onClick={onDone}>
          Done
        </Button>
      </div>
    );
  }

  let content = null;

  if (isLoading) {
    content = (
      <SpinnerContainer>
        <SpinnerAnimated />
      </SpinnerContainer>
    );
  } else if (!filtered.length) {
    content = (
      <CenteredParagraph>
        There is no available assessments for you.
      </CenteredParagraph>
    );
  } else if (isSubmitting) {
    content = (
      <CenteredParagraph>
        Wait a moment, while we are sending you an invitation...
      </CenteredParagraph>
    );
  } else if (error) {
    content = (
      <>
        <CenteredParagraph>{error.message}</CenteredParagraph>
        <Button type="ghost" block onClick={onDone}>
          Back
        </Button>
      </>
    );
  } else {
    content = <AssessmentList items={filtered} onClick={sendInvite} />;
  }

  return (
    <div className="drawer-section">
      <Typography.Title level={3}>Skill assessment</Typography.Title>
      <Content>{content}</Content>
    </div>
  );
};
