import { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { ApiProvider } from '@yougig/shared/api/Api';
import { AuthProvider } from '@yougig/shared/auth/AuthProvider';
import { QueryProvider } from '@yougig/shared/common/Query';
import { setupThemeVariables } from '@yougig/ui/utils/themes';

import { Routing } from './pages';

function App() {
  useEffect(() => {
    setupThemeVariables();
  }, []);

  return (
    <>
      <Helmet titleTemplate="%s | Yougig Talent" defaultTitle="Yougig Talent" />
      <Router>
        <AuthProvider>
          <ApiProvider baseUrl={process.env.REACT_APP_API_URL}>
            <QueryProvider>
              <Routing />
            </QueryProvider>
          </ApiProvider>
        </AuthProvider>
      </Router>
    </>
  );
}

export default App;
