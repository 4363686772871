import { Button, Typography } from 'antd';
import format from 'date-fns/format';
import styled from 'styled-components';

import {
  REQUEST_TYPES,
  REQUEST_TYPES_OPTIONS,
} from '@yougig/shared/actions/constants';

import { Card } from './Card';
import { Edit } from '../../icons';

const CardStyled = styled(Card)`
  background-color: var(--c_magnolia);
  border: 1px solid var(--c_light_violet);
  padding: 15px;
  font-size: 12px;
  margin-bottom: 0;
  height: 100%;
`;

const CardHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
`;

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
`;

const CardTitle = styled.div`
  font-weight: 600;
  margin-right: auto;
  font-size: 14px;
`;

const CardEdit = styled(Button)`
  margin: -5px -5px -5px 8px;
`;

const CardDate = styled.div`
  font-size: 12px;
  text-transform: uppercase;
`;

const CardNotes = styled(Typography.Paragraph)`
  &.ant-typography {
    margin: 8px 0 0 !important;
    width: 70%;
    color: var(--c_hint);
  }
`;

const CardCompanyName = styled.span`
  font-size: 12px;
  color: var(--c_description);
  margin-top: 10px;
`;

const DuePrefix = styled.span`
  margin-right: 4px;
  color: var(--c_hint);
`;

export const RequestCard = ({
  request,
  className,
  canEdit = true,
  onEdit,
  statuses,
}) => {
  return (
    <CardStyled className={className}>
      <CardHeader>
        <CardTitle>{REQUEST_TYPES_OPTIONS.getLabel(request.type)}</CardTitle>
        <div
          style={{
            color: statuses.get(request.status)?.color,
          }}>
          {statuses.getLabel(request.status)}
        </div>
        {canEdit && (
          <CardEdit
            type="link"
            htmlType="button"
            size="small"
            onClick={() => onEdit(request)}
            icon={<Edit width={12} height={12} />}
          />
        )}
      </CardHeader>
      <CardBody>
        <CardDate>
          {request.type === REQUEST_TYPES.INFO && <DuePrefix>DUE</DuePrefix>}
          <time dateTime={request.dateTime?.toDateString()}>
            {request.dateTime && format(request.dateTime, 'd LLL p')}
          </time>
        </CardDate>
        {request?.companyName && (
          <CardCompanyName>{request.companyName}</CardCompanyName>
        )}
        <CardNotes type="secondary" ellipsis={{ rows: 1 }}>
          {request.notes}
        </CardNotes>
      </CardBody>
    </CardStyled>
  );
};
