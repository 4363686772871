import { Collapse } from 'antd';
import styled from 'styled-components';

export const Pipeline = styled(Collapse)`
  &:not(.ant-collapse__custom).ant-collapse {
    & > .ant-collapse-item {
      background-color: #fff;
      border: 1px solid var(--c_light_grey);
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
      overflow: hidden;

      &:not(:last-child) {
        margin-bottom: 16px;
      }

      & > .ant-collapse-header .ant-collapse-arrow {
        margin-top: -8px;
      }
    }

    .ant-collapse-content {
      & > .ant-collapse-content-box {
        padding: 0;

        .ant-table {
          border: 0;
        }

        table > thead > tr:first-child th:first-child,
        table > thead > tr:first-child th.cell-heading + th,
        table > thead > tr:first-child th:last-child {
          border-radius: 0;
        }
      }
    }
  }

  &.ant-collapse__custom {
    & > .ant-collapse-item {
      border: 0;

      & > .ant-collapse-header {
        padding: 12px 16px 12px 40px;
        margin: 0 -16px 4px;
        border: 0;
        font-size: 24px;
        line-height: 33px;
        color: var(--c_text);
        position: sticky;
        top: 0;
        z-index: 30;
        background-color: #fff;
        .ant-collapse-arrow {
          left: 16px;
          margin-top: -8px;
        }
        @media (max-width: 767px) {
          top: 64px;
          z-index: 1;
        }
      }
    }

    .ant-collapse-content {
      border: 0;
      background-color: transparent;
      & > .ant-collapse-content-box {
        padding: 0;

        .ant-table {
          border: 0;
          box-shadow: none;
        }
      }
    }

    .ant-table-content table {
      @media (min-width: 768px) {
        .ant-table-thead {
          top: 57px;
        }
        .ant-table-row-level-0 {
          top: 97px;

          body.safari & {
            top: 57px;
          }
        }
      }
    }
  }

  .ant-collapse-extra {
    margin-right: 24px;
  }
`;
