import * as React from 'react';

export const Hamburger = (props) => (
  <svg width={28} height={28} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M3 12h18M3 6h18M3 18h18"
      stroke="#270F56"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
