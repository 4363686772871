import { Fragment, useMemo, useEffect, useRef } from 'react';
import isToday from 'date-fns/isToday';
import formatDate from 'date-fns/format';
import debounce from 'lodash/debounce';
import uniq from 'lodash/uniq';
import styled from 'styled-components';

import { Loading } from '@yougig/ui/icons';

import { Message } from './Message';

const List = styled.div`
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  max-height: 100%;
  margin-top: auto;
`;

export const MessageList = ({ messages, hasMore, loadMore, loading }) => {
  const grouped = useMemo(() => {
    const reverseMessages = messages
      .slice()
      .reverse()
      .map((item) => ({
        ...item,
        date: isToday(item.dateTime)
          ? 'Today'
          : formatDate(item.dateTime, 'dd.MM.yyyy'),
      }));
    return uniq(reverseMessages.map(({ date }) => date)).map((date) => ({
      date,
      items: reverseMessages.filter((m) => m.date === date),
    }));
  }, [messages]);

  const scrollContainer = useRef();
  const loadAboveItem = useRef();

  useEffect(() => {
    if (scrollContainer) {
      const el = scrollContainer.current;

      const observer = new MutationObserver((mutationList) => {
        for (let mutation of mutationList) {
          if (mutation.addedNodes.length) {
            // we need to scroll to top or bottom only if new messages have been added.
            const isAddingMessages = Array.from(
              mutation.addedNodes.values(),
            ).some((n) => n.classList.contains('chat-messages--message'));
            if (!isAddingMessages) {
              continue;
            }

            if (loadAboveItem.current) {
              // if we are loading old messages, restore the scroll after
              // rendering new portion of messages.
              const dateHeaderHeight = 32;
              const itemPosition =
                loadAboveItem.current.getBoundingClientRect().top;
              const containerPosition = el.getBoundingClientRect().top;
              const offsetPosition =
                itemPosition - containerPosition - dateHeaderHeight - 30;
              el.scroll({ top: offsetPosition });
              loadAboveItem.current = null;
            } else {
              // otherwise scroll to the bottom.
              el.scroll({ top: el.scrollHeight });
            }
            break;
          }
        }
      });

      observer.observe(el, { childList: true });
      el.scroll({ top: el.scrollHeight });

      return () => observer.disconnect();
    }
  }, [scrollContainer]);

  useEffect(() => {
    if (!scrollContainer.current || !hasMore || loading || !messages.length) {
      return;
    }
    const el = scrollContainer.current;

    const handler = debounce(() => {
      const scrollPct = el.scrollTop / (el.scrollHeight - el.clientHeight);
      if (scrollPct <= 0.2) {
        // find and save in ref the topmost message element to be able
        // to scroll to it once new messages are rendered.
        for (let i = 0; i < el.childNodes.length; i++) {
          if (el.childNodes[i].classList.contains('chat-messages--message')) {
            loadAboveItem.current = el.childNodes[i];
            break;
          }
        }
        loadMore();
      }
    }, 500);

    el.addEventListener('scroll', handler);

    return () => {
      handler.cancel();
      el.removeEventListener('scroll', handler);
    };
  }, [scrollContainer, hasMore, loadMore, loading, messages.length]);

  return (
    <List ref={scrollContainer}>
      {grouped.map(({ date, items }, index) => (
        <Fragment key={date}>
          <Message.Date className="chat-messages--date-header">
            {date}
            {loading && index === 0 && <Loading width={10} height={10} />}
          </Message.Date>
          {items.map((item) => (
            <Message
              className="chat-messages--message"
              key={item.id}
              {...item}
            />
          ))}
        </Fragment>
      ))}
    </List>
  );
};
