import { Button } from 'antd';

import { useDrawerContext } from '@yougig/ui/components/common/DrawerProvider';

export const CreateProfileButton = () => {
  const { setDrawerView } = useDrawerContext();
  return (
    <Button
      type="primary"
      className="page-heading__btn"
      onClick={() => setDrawerView('createProfile')}>
      Create Profile
    </Button>
  );
};
