import { useEffect, useState, useCallback } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';

import services from '../utils/services';
import { Candidate } from '../candidates/Candidate';
import { useAuth } from '../auth/AuthProvider';
import { CV_STATUS } from '../talents/constants';
import { Profile } from '../talents/Profile';

export const getAssigment = async (profile, job) => {
  const defaultCandidate = new Candidate({ consultant: profile.ref });

  if (!job?.client) {
    return { profile, candidate: defaultCandidate, applied: false };
  }

  const snaps = await services
    .get('db')
    .collection('candidates')
    .where('client', '==', job.client)
    .where('consultant', '==', profile.ref)
    .withConverter(Candidate.converter)
    .get();
  const docs = snaps.docs;

  let candidate = docs[0]?.data();
  const assignmentSnap =
    candidate && (await candidate.getAssignmentsQuery().doc(job.key).get());

  const applied = !!assignmentSnap?.get('applied');

  return {
    profile: profile,
    applied,
    candidate: candidate || defaultCandidate,
  };
};

export const useTeamCandidates = (job) => {
  const [candidates, setCandidates] = useState([]);
  const [loading, setLoading] = useState(false);
  const { profile: account } = useAuth();
  const [members] = useCollectionData(
    account?.tenant &&
      services
        .get('db')
        .collection('profiles')
        .where('team', '==', account.tenant)
        .withConverter(Profile.converter),
  );

  const refresh = useCallback(() => {
    if (members) {
      setLoading(true);

      Promise.all(
        members
          // we have to filter the status not in the query because not every
          // profile has this field but Firestore doesn't much documents
          // where this field is missed.
          .filter((p) => p.status !== CV_STATUS.DELETED)
          .map((member) => getAssigment(member, job)),
      )
        .then(setCandidates)
        .finally(() => setLoading(false));
    }
  }, [job, members]);

  useEffect(() => {
    members?.length && refresh();
  }, [refresh, members]);

  return { candidates, refresh, loading };
};
