export const Vetted = ({ color, ...props }) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M4.666 14.666h-2a1.333 1.333 0 01-1.333-1.333V8.666a1.333 1.333 0 011.333-1.333h2M9.333 6V3.333a2 2 0 00-2-2l-2.667 6v7.333h7.52a1.333 1.333 0 001.334-1.133l.92-6A1.333 1.333 0 0013.106 6H9.333z"
      stroke={color ? color : 'currentColor'}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
