import { Typography, Space } from 'antd';

import { useAuth } from '@yougig/shared/auth/AuthProvider';
import { ReportsService } from '@yougig/shared/reports/ReportsService';
import { Report as Reports } from '@yougig/ui/components/reports/Reports';
import { useDrawerContext } from '@yougig/ui/components/common/DrawerProvider';

export const ReportsList = () => {
  const { profile } = useAuth();
  const { setDrawerView } = useDrawerContext();
  const [reports] = ReportsService.useTalentReports(profile.profile);

  return (
    <div className="drawer-section">
      <Typography.Title level={3}>Reports</Typography.Title>
      <Reports.List>
        {reports.map((report) => (
          <Reports.Item
            key={report.id}
            report={report}
            secondaryText={
              <Space size="large">
                {!!report.totalTime && <span>{report.totalTime}h</span>}
                <span>{report.businessName}</span>
              </Space>
            }
            onClick={() => setDrawerView('report', { report })}
          />
        ))}
      </Reports.List>
    </div>
  );
};
