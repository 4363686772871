import React from 'react';
import ReactDOM from 'react-dom';

import { servicesRef } from '@yougig/shared/utils/services';

import App from './App';
import reportWebVitals from './reportWebVitals';
import firebaseContext from './utils/firebase';

import 'antd/dist/antd.css';
import './styles/index.scss';

servicesRef.current = firebaseContext;
servicesRef.current.config = Object.entries(process.env).reduce(
  (acc, [key, value]) => {
    if (key.startsWith('REACT_APP_')) {
      acc[key.substring(10)] = value;
    }
    return acc;
  },
  {},
);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
