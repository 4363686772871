import styled from 'styled-components';
import { Button, Col, Row, Typography } from 'antd';

import { SENIORITY_LEVEL } from '@yougig/shared/constants';
import { PROJECT_STATUS_OPTIONS } from '@yougig/shared/projects/constants';

import { SkillsList } from '../common/SkillsList';
import { AttachmentsList } from '../elements/AttachmentsList';
import { AssignmentDetailsTopInfoCol } from './AssignmentDetailsTopInfoCol';
import { StatusLabel } from '../elements/Status';
import { Edit } from '../../icons';

const TopInfoLayout = styled.div`
  padding-bottom: 50px;
`;

const EditBtn = styled(Button)`
  margin: 2px 0;
  align-self: flex-start;
  flex: 0 0 41px;
`;

const TitleRow = styled.div`
  display: flex;
  flex-basis: 0;
  flex-grow: 1;
  min-width: 1px;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px 0;
  h1.ant-typography .ant-typography-secondary {
    color: var(--c_hint);
  }
`;

const TopInfoRow = styled(Row)`
  &:not(:last-child) {
    margin-bottom: 32px;
  }
`;

const Description = styled.div`
  margin-top: 32px;
  white-space: pre-wrap;
  &:not(:last-child) {
    margin-bottom: 32px;
  }
`;

const RightCol = styled.div`
  flex: 0 0 40%;
  min-width: 1px;
  margin-left: 45px;
  padding-left: 60px;
  border-left: 1px solid var(--c_light_blue);
  @media (max-width: 767px) {
    min-width: 100%;
    margin: 32px 0 0;
    padding: 32px 0 0;
    border-left: 0;
    border-top: 1px solid var(--c_light_blue);
  }
`;

export const AssignmentDetailsTopInfo = ({
  children,
  data,
  highlightSkills = [],
  breadcrumbs,
  visibleStatus,
  onEdit,
}) => {
  return (
    <TopInfoLayout>
      {breadcrumbs}
      <div className="page-heading">
        <TitleRow>
          <Typography.Title>
            {data.quantity > 1 && `${data.quantity} × `}
            {data.name || data.title}{' '}
            {data.seniority > 0 && (
              <Typography.Text type="secondary">
                {SENIORITY_LEVEL[data.seniority].toLowerCase()}
              </Typography.Text>
            )}
          </Typography.Title>
          {visibleStatus && data.status && (
            <StatusLabel {...PROJECT_STATUS_OPTIONS.get(data.status)} />
          )}
        </TitleRow>
        {onEdit && (
          <EditBtn
            onClick={onEdit}
            className="ml-auto"
            type="ghost"
            size="large"
            icon={<Edit />}
          />
        )}
      </div>
      <TopInfoRow>
        <Col flex="1 1">
          {!!data?.skills?.length && (
            <SkillsList
              label="Must-have"
              skills={data.skills}
              highlighted={highlightSkills}
            />
          )}

          {(!!data.description || !!data.notes) && (
            <Description
              dangerouslySetInnerHTML={{
                __html: data.description || data.notes,
              }}
            />
          )}

          {!!data?.attachments?.length && (
            <AttachmentsList items={data.attachments || []} />
          )}
        </Col>
        <RightCol>
          <AssignmentDetailsTopInfoCol data={data} />
        </RightCol>
      </TopInfoRow>
      {children}
    </TopInfoLayout>
  );
};
