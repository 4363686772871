import { Typography } from 'antd';
import styled from 'styled-components';

import { AVAILABILITY } from '@yougig/shared/candidates/constants';
import services from '@yougig/shared/utils/services';

import { Scoring } from '../elements/Scoring';
import { Proven, Vetted } from '../../icons';

const GroupTitle = styled.span`
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  gap: 4px;
  font-size: 20px;
  line-height: 24px;
  vertical-align: top;
  .ant-typography-secondary {
    color: var(--c_hint);
  }
  .ant-btn {
    padding: 1px 4px;
    svg {
      margin: 1px 4px 1px 0;
      vertical-align: top;
    }
  }
`;

const CandidateName = styled.span`
  display: inline-flex;
  line-height: 16px;
  vertical-align: top;
  width: 100%;
`;

const CandidateIcon = styled.span`
  flex: 0 0 16px;
  height: 16px;
  margin-right: 8px;
  svg {
    vertical-align: top;
  }
`;

const CandidateNameColumn = ({ profile }) => {
  const isClientApp = services.get('config').PROJECT === 'yougig';

  const icon = profile.worked_with_us ? (
    <Proven width={16} height={16} />
  ) : (
    profile.vetted && <Vetted width={16} height={16} />
  );
  return (
    <CandidateName>
      <CandidateIcon>{icon}</CandidateIcon>
      <span>
        {isClientApp ? profile.firstName : profile.fullName}
        {isClientApp
          ? !!profile.country_code && (
              <>
                <br />
                <Typography.Text type="secondary">
                  {profile.country_code}
                </Typography.Text>
              </>
            )
          : !!profile.company && (
              <>
                <br />
                <Typography.Text type="secondary">
                  {profile.company.name}
                </Typography.Text>
              </>
            )}
      </span>
    </CandidateName>
  );
};

export const noRenderCell = {
  children: null,
  props: { colSpan: 0 },
};

export const baseColumns = {
  groupHeading: (length = 1) => ({
    className: 'cell-heading',
    dataIndex: 'groupTitle',
    render: (groupTitle, { groupLength, actionsButton }) => {
      if (groupTitle) {
        return {
          children: (
            <GroupTitle>
              {groupTitle}
              <Typography.Text type="secondary">
                {groupLength || 0}
              </Typography.Text>
              {actionsButton}
            </GroupTitle>
          ),
          props: {
            colSpan: length,
          },
        };
      }

      return {
        children: null,
        props: { colSpan: 0 },
      };
    },
  }),
  candidate: {
    title: 'Consultant',
    className: 'cell-candidate',
    key: 'name',
    render: (_, { profile, groupTitle }) => {
      if (groupTitle) {
        return noRenderCell;
      }
      return <CandidateNameColumn profile={profile} />;
    },
  },
  occupation: {
    title: 'Profession',
    className: 'cell-occupation',
    key: 'title',
    render: (_, { profile, groupTitle }) => {
      if (groupTitle) {
        return noRenderCell;
      }

      return (
        <>
          {profile.title}
          {!!profile.experience && (
            <>
              <br />
              <span className="info">{profile.experience} yrs</span>
            </>
          )}
        </>
      );
    },
  },
  scoring: {
    title: 'Scoring',
    className: 'cell-scoring',
    key: 'scoring',
    render: (_, { profile, groupTitle }) => {
      if (groupTitle) {
        return noRenderCell;
      }

      return <Scoring {...profile.scoring} />;
    },
  },
  location: {
    title: 'From',
    className: 'cell-location',
    key: 'country',
    render: (_, { profile, groupTitle }) => {
      if (groupTitle) {
        return noRenderCell;
      }

      return <span title={profile.countryName}>{profile.countryCode}</span>;
    },
  },
  clientRate: {
    title: 'Rate',
    className: 'cell-rate',
    key: 'rate',
    render: (_, { profile, groupTitle }) => {
      if (groupTitle) {
        return noRenderCell;
      }

      return profile.rate ? `$${profile.rate.min}-${profile.rate.max}/h` : null;
    },
  },
  rate: {
    title: 'Rate',
    className: 'cell-rate',
    dataIndex: 'rate',
    render: (_, { profile, groupTitle }) => {
      if (groupTitle) {
        return noRenderCell;
      }

      return (
        <>
          {!!profile.rate && `$${profile.rate.min}-${profile.rate.max}/h`}
          {!!profile.internalRate && (
            <>
              {!!profile.rate && <br />}
              <span className="info">Internal: </span>
              {`$${profile.internalRate}/h`}
            </>
          )}
        </>
      );
    },
  },
  availability: {
    title: 'Availability',
    className: 'cell-availability',
    key: 'availability',
    render: (_, { profile, groupTitle }) => {
      if (groupTitle) {
        return noRenderCell;
      }
      if (!profile.availability) {
        return null;
      }
      return profile.availability.status === AVAILABILITY.AVAILABLE ? (
        <>
          <Typography.Text type="success">Available</Typography.Text>
          {!!profile.availability.availability && (
            <>
              <br />
              <Typography.Text type="secondary">{`${profile.availability.availability}h/week`}</Typography.Text>
            </>
          )}
        </>
      ) : (
        <Typography.Text type="secondary">Not Available</Typography.Text>
      );
    },
  },
  skills: {
    title: 'Skills',
    className: 'cell-skills',
    key: 'skills',
    render: (_, { profile, groupTitle }) => {
      if (groupTitle) {
        return noRenderCell;
      }
      return profile.skills.join(', ');
    },
  },
  notes: {
    title: 'Notes',
    className: 'cell-notes',
    key: 'notes',
    render: (_, { profile, groupTitle }) => {
      if (groupTitle) {
        return noRenderCell;
      }
      return profile.notesFirstLine;
    },
  },
  email: {
    title: 'Email',
    className: 'cell-email',
    key: 'email',
    render: (_, { profile, groupTitle }) => {
      if (groupTitle) {
        return noRenderCell;
      }
      return (
        !!profile.email && (
          <a href={`mailto:${profile.email}`}>{profile.email}</a>
        )
      );
    },
  },
};
