import { useDrawerContext } from '@yougig/ui/components/common/DrawerProvider';
import { Drawer } from '@yougig/ui/components/elements/Drawer';

import { InterviewsList } from './InterviewsList';
import { ReportDetails } from './ReportDetails';
import { ReportsList } from './ReportsList';

export const OverviewDrawer = () => {
  const { viewMode, closeDrawer } = useDrawerContext();

  return (
    <Drawer
      className="drawer-no-padding"
      visible={!!viewMode}
      onClose={() => closeDrawer()}>
      {viewMode === 'interviews' && <InterviewsList />}
      {viewMode === 'reports' && <ReportsList />}
      {viewMode === 'report' && <ReportDetails />}
    </Drawer>
  );
};
