import services from '../utils/services';

export class Client {
  /**
   * Tries to find or create a new client based on assignment object from API.
   * @param {Object} assignment Assignment object, returned by API
   * @returns {Firestore.DocumentReference} Client document reference in Firestore
   */
  static async fromExternalAssignment(assignment) {
    let clientsQuery =
      assignment.company_name &&
      services
        .get('db')
        .collection('clients')
        .where('businessName', '==', assignment.company_name);
    if (assignment.source) {
      clientsQuery = clientsQuery.where(
        'source',
        '==',
        assignment.source.collection,
      );
    }
    const clients = clientsQuery && (await clientsQuery.get());
    if (clients?.size) {
      return clients.docs[0].ref;
    } else {
      return assignment.company_name
        ? await services
            .get('db')
            .collection('clients')
            .add({
              businessName: assignment.company_name,
              source: assignment.source?.collection || null,
              city: assignment.city || null,
              country: assignment.country_code || null,
              created: services.get('now')(),
            })
        : services.get('db').doc('clients/48iWCY3Va5zBQ2IMiA9q');
    }
  }
}
