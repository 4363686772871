import { Formik } from 'formik';
import { Form } from 'formik-antd';
import { Typography, Input } from 'antd';
import formatDate from 'date-fns/format';
import classNames from 'classnames';
import { useMemo } from 'react';

import {
  REPORT_FEEDBACK_VALUE,
  REPORT_INCOMING_FEEDBACK_ITEMS,
  REPORT_OUTCOMING_FEEDBACK_ITEMS,
  REPORT_STATUS,
  REPORT_STATUS_OPTIONS,
  REPORT_TYPE,
} from '@yougig/shared/reports/constants';
import { reportFormSchema } from '@yougig/shared/reports/schema';
import { Attachments } from '@yougig/shared/common/Attachments';
import services from '@yougig/shared/utils/services';

import { Report } from './Reports';
import { TextAreaWithUpload } from '../form/TextAreaWithUpload';
import { FeedbackItem } from '../form/FeedbackItem';
import { StatusDropdown } from '../elements/StatusDropdown';
import { StatusLabel } from '../elements/Status';

/**
 * @typedef {object} ReportFormProps
 * @prop {string} title - title of the form
 * @prop {string} subHeading - Display a sub heading (grey color) below the main title
 * @prop {'label'|'dropdown'} statusType - Type of the status element.
 * @prop {import('@yougig/shared/reports/Report').Report} report - Report model
 * @prop {() => void} onClose - Callback to call on successful submit
 * @prop {(formikBag: import('formik').FormikBag) => void} renderButtons - Callback to render form buttons.
 *
 * @param {ReportFormProps} props
 * @returns {JSX.Element}
 */
export const ReportForm = ({
  report,
  title,
  subHeading,
  statusType = 'label',
  onClose,
  renderButtons,
}) => {
  const initialValues = useMemo(() => {
    const { outcomingAttachments, incomingAttachments, ...values } =
      report.getFormValues();
    if (outcomingAttachments?.length) {
      values.outcomingAttachments = outcomingAttachments.map(
        Attachments.toRcUpload,
      );
    }
    if (incomingAttachments?.length) {
      values.incomingAttachments = incomingAttachments.map(
        Attachments.toRcUpload,
      );
    }
    return values;
  }, [report]);

  const disableOutcomingSide =
    report.type === REPORT_TYPE.CLIENT
      ? services.get('config').PROJECT === 'yougig' ||
        !REPORT_STATUS_OPTIONS.get(report.status).active
      : services.get('config').PROJECT === 'manager' ||
        report.status !== REPORT_STATUS.REQUESTED;
  const disableIncomingSide =
    report.type === REPORT_TYPE.CLIENT
      ? services.get('config').PROJECT === 'manager' ||
        report.status !== REPORT_STATUS.PENDING
      : services.get('config').PROJECT === 'youpro' ||
        !REPORT_STATUS_OPTIONS.get(report.status).active;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={reportFormSchema}
      onSubmit={async (
        { outcomingAttachments, incomingAttachments, ...values },
        { setSubmitting },
      ) => {
        if (outcomingAttachments?.length) {
          values.outcomingAttachments = outcomingAttachments.map(
            Attachments.fromRcUpload,
          );
        }
        if (incomingAttachments?.length) {
          values.incomingAttachments = incomingAttachments.map(
            Attachments.fromRcUpload,
          );
        }
        await report.save(values);
        setSubmitting(false);
        onClose(report);
      }}>
      {(formik) => (
        <Form layout="vertical">
          <div className="drawer-section-heading">
            <Typography.Title level={3}>{title}</Typography.Title>

            {statusType === 'dropdown' && (
              <StatusDropdown
                name="status"
                options={REPORT_STATUS_OPTIONS.filter((o) =>
                  o.group.includes(report.type),
                )}
                className="ml-auto"
              />
            )}
            {statusType === 'label' && report.status && (
              <StatusLabel
                className="ml-auto"
                {...REPORT_STATUS_OPTIONS.get(report.status)}
              />
            )}
          </div>
          {!!subHeading && <Report.ListTitle>{subHeading}</Report.ListTitle>}
          <div className="row">
            <Form.Item name="startDate" label="From" className="col-6">
              <Input
                name="startDate"
                value={formatDate(formik.values.startDate, 'yyyy-MM-dd')}
                readOnly
              />
            </Form.Item>

            <Form.Item name="endDate" label="To" className="col-6">
              <Input
                name="endDate"
                value={formatDate(formik.values.endDate, 'yyyy-MM-dd')}
                readOnly
              />
            </Form.Item>
          </div>

          <div className="row">
            <div className="col-6">
              <Form.Item
                name="outcomingFeedback"
                validate={(value) => {
                  if (
                    !disableOutcomingSide &&
                    !Object.values(REPORT_FEEDBACK_VALUE).includes(
                      value?.status,
                    )
                  ) {
                    return 'Please, select the overall status';
                  }
                }}
                label={`Feedback from ${
                  report.type === REPORT_TYPE.CLIENT ? 'manager' : 'consultant'
                }`}>
                <div
                  className={classNames('ant-input', {
                    readonly: disableOutcomingSide,
                  })}>
                  {REPORT_OUTCOMING_FEEDBACK_ITEMS.map(({ name, ...item }) => (
                    <FeedbackItem
                      key={name}
                      name={`outcomingFeedback.${name}`}
                      {...item}
                      readOnly={disableOutcomingSide}
                    />
                  ))}
                </div>
              </Form.Item>
            </div>
            <div className="col-6">
              <TextAreaWithUpload
                label="&nbsp;"
                messageFieldName="outcomingMessage"
                attachmentsFieldName="outcomingAttachments"
                readOnly={disableOutcomingSide}
                textAreaProps={{
                  placeholder: 'Feedback or other notes',
                }}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-6">
              <Form.Item
                name="incomingFeedback"
                validate={(value) => {
                  if (
                    !disableIncomingSide &&
                    !Object.values(REPORT_FEEDBACK_VALUE).includes(
                      value?.status,
                    )
                  ) {
                    return 'Please, select the overall status';
                  }
                }}
                label={`Feedback from ${
                  report.type === REPORT_TYPE.CLIENT ? 'client' : 'manager'
                }`}>
                <div
                  className={classNames('ant-input', {
                    readonly: disableIncomingSide,
                  })}>
                  {REPORT_INCOMING_FEEDBACK_ITEMS.map(({ name, ...item }) => (
                    <FeedbackItem
                      key={name}
                      name={`incomingFeedback.${name}`}
                      {...item}
                      readOnly={disableIncomingSide}
                    />
                  ))}
                </div>
              </Form.Item>
            </div>
            <div className="col-6">
              <TextAreaWithUpload
                label="&nbsp;"
                messageFieldName="incomingMessage"
                attachmentsFieldName="incomingAttachments"
                readOnly={disableIncomingSide}
                textAreaProps={{
                  placeholder: 'Feedback or other notes',
                }}
              />
            </div>
          </div>
          <div className="form-actions">{renderButtons(formik)}</div>
        </Form>
      )}
    </Formik>
  );
};
