import { NavLink } from 'react-router-dom';

export const Menu = ({ items }) => (
  <ul className="menu">
    {items.map(({ title, link, ...attributes }) => (
      <li className="menu-item" key={title}>
        <NavLink {...attributes} activeClassName="active" to={link}>
          {title}
        </NavLink>
      </li>
    ))}
  </ul>
);
