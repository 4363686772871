export const Proven = (props) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.55 1.25h14.9l4.456 8.355L12 23.135.094 9.605 4.55 1.25zm-2.3 7.5h4.285l4.2-6H5.45l-3.2 6zm6.116 0h7.268L12 3.558 8.366 8.75zm9.1 0h4.284l-3.2-6h-5.285l4.2 6zm-1.974 1.5H8.508L12 18.98l3.492-8.73zm1.616 0l-3.528 8.819 7.761-8.819h-4.233zm-14.449 0h4.233l3.528 8.819-7.76-8.819z"
      fill="currentColor"
    />
  </svg>
);
