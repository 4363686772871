import styled from 'styled-components';

const Label = styled.div.withConfig({
  shouldForwardProp: (prop, defaultValidatorFn) =>
    !['label', 'large', 'medium', 'color', 'background'].includes(prop) &&
    defaultValidatorFn(prop),
})`
  padding: ${(props) => (props.large ? '10px 36px' : '5px 10px')};
  border-radius: ${(props) => (props.large ? 200 : 12)}px;
  font-weight: 600;
  font-size: ${(props) => (props.large ? 24 : 12)}px;
  line-height: 1.2;
  color: ${(props) => props.color || '#fff'};
  background-color: ${(props) => props.background || 'transparent'};
  white-space: nowrap;
  @media (min-width: 767px) {
    ${(props) =>
      props.medium &&
      `
    font-size: 16px;
    line-height: 25px;
    padding: 8px 16px;
    border-radius: 21px;
  `}
  }
`;

export const StatusLabel = ({ label, ...props }) => (
  <Label {...props}>{label}</Label>
);
