import React, { useMemo } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

import { useApi } from '@yougig/shared/api/Api';

export const QueryProvider = ({ children, onError }) => {
  const { entityQueryFn } = useApi();

  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: 600000,
            queryFn: entityQueryFn,
            onError: (err) => {
              onError && onError(err);
            },
            refetchInterval: false,
            refetchOnWindowFocus: false,
          },
        },
      }),
    [entityQueryFn, onError],
  );

  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};
