import axios from 'axios';

/**
 * @typedef {{
 *  link: string;
 *  domainUriPrefix: string;
 *  android: {
 *    packageName: string;
 *  };
 *  ios: {
 *    bundleId: string;
 *    iosAppStoreId: number;
 *  }
 * }} BuildLinkParams
 */

export class DynamicLinks {
  constructor(apiKey) {
    this.apiKey = apiKey;
  }

  /**
   * Generates dynamic link
   *
   * @param {BuildLinkParams} linkParams Link params
   * @returns {string} Full link
   */
  static buildLink({ link, domainUriPrefix, android, ios }) {
    const url = new URL(domainUriPrefix);
    url.searchParams.set('link', link);
    url.searchParams.set('apn', android.packageName);
    url.searchParams.set('isi', ios.iosAppStoreId);
    url.searchParams.set('ibi', ios.bundleId);
    return url.toString();
  }

  /**
   * Generates dynamic link
   *
   * @param {BuildLinkParams} linkParams Link params
   * @returns {string} Full link
   */
  buildLink(...args) {
    DynamicLinks.buildLink(...args);
  }

  /**
   * Generates short link
   *
   * @param {BuildLinkParams} linkParams Link params
   * @param {'SHORT' | 'UNGUESSABLE'} shortLinkType Type of the link to generate.
   * @returns {Promise<string>} Short link
   */
  async buildShortLink(
    { link, domainUriPrefix, android, ios },
    shortLinkType = 'SHORT',
  ) {
    const payload = {
      dynamicLinkInfo: {
        domainUriPrefix,
        link,
        androidInfo: {
          androidPackageName: android.packageName,
        },
        iosInfo: {
          iosBundleId: ios.bundleId,
          iosAppStoreId: ios.appStoreId,
        },
      },
      suffix: {
        option: shortLinkType,
      },
    };

    const response = await axios.post(
      'https://firebasedynamiclinks.googleapis.com/v1/shortLinks',
      payload,
      { params: { key: this.apiKey } },
    );

    return response.data.shortLink;
  }
}
