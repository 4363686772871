import { useMemo } from 'react';
import { Upload } from 'antd';
import { useField } from 'formik';
import { Form } from 'formik-antd';

export const AttachmentsField = ({
  name,
  label,
  cta = 'Drag your files here',
  ...props
}) => {
  const [field, , helpers] = useField(name);

  const draggerProps = useMemo(
    () => ({
      multiple: true,
      fileList: field.value?.filter((a) => !a.deleted),
      onChange({ file, fileList, error }) {
        if (file.status === 'error') {
          helpers.setError(error.message);
        }
        helpers.setValue(fileList);
      },
      customRequest({ file, onSuccess }) {
        onSuccess({}, file);
      },
    }),
    [helpers, field.value],
  );

  return (
    <Form.Item name={name} {...props}>
      <Upload.Dragger {...draggerProps} listType="picture">
        {!!label && <label htmlFor={name}>{label}</label>}
        <p className="ant-upload-text">
          {cta} or <span className="text-link">Upload</span>
        </p>
      </Upload.Dragger>
    </Form.Item>
  );
};
