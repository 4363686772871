import { useMemo } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { useQuery } from 'react-query';

import { useApi } from '../api/Api';
import { useAuth } from '../auth/AuthProvider';
import { Profile } from '../talents/Profile';
import services from '../utils/services';
import { CV_STATUS } from '../talents/constants';
import { TEAM_PEOPLE_GROUPS, TEAM_PEOPLE_GROUPS_OPTIONS } from './constants';

export const useTeamMembers = () => {
  const { apiClient } = useApi();
  const { profile, tenant } = useAuth();
  const isImporting = !!tenant?.linkedinCompany && tenant?.import;

  const { data } = useQuery(
    ['/linkedin/company', tenant?.linkedinCompany],
    async ({ queryKey: [endpoint, url, teamRef] }) => {
      const result = (await apiClient.get(`${endpoint}/${url}`)).data;

      if (result.candidates) {
        return {
          ...result,
          candidates: result.candidates.map(Profile.fromApi).map((p) => {
            p.team = profile.tenant;

            return p;
          }),
        };
      }

      return result;
    },
    { enabled: isImporting },
  );

  const profilesQuery = useMemo(() => {
    return (
      profile.tenant &&
      services
        .get('db')
        .collection('profiles')
        .where('team', '==', profile.tenant)
        .withConverter(Profile.converter)
    );
  }, [profile.tenant]);

  const [profiles = []] = useCollectionData(profilesQuery);

  const members = useMemo(() => {
    if (!tenant?.import || !data?.candidates?.length) {
      return profiles;
    }

    // 1. take profiles
    // 2. extend them with API data if available
    // 3. concat with API data not available in firebase (profiles)

    const apiProfiles = data.candidates;
    return profiles
      .map((profile) => {
        const apiProfile = apiProfiles.find(
          (p) => p.externalId === profile.externalId,
        );
        if (apiProfile) {
          profile.apiData = apiProfile.apiData;
        }
        return profile;
      })
      .concat(
        apiProfiles.filter(
          (p) =>
            !profiles.some((profile) => profile.externalId === p.externalId),
        ),
      );
  }, [data, tenant, profiles]);

  const groups = useMemo(() => {
    const result = [];

    const createGroup = (groupName, condition) => {
      const filtered = members.filter(condition);
      if (filtered.length) {
        result.push({
          key: groupName,
          groupTitle: TEAM_PEOPLE_GROUPS_OPTIONS.getLabel(groupName),
          groupLength: filtered.length,
          data: filtered.map((profile) => ({ profile, key: profile.key })),
        });
      }
    };

    createGroup(
      TEAM_PEOPLE_GROUPS.MANAGED_BY_TEAM,
      (profile) => !profile.account && profile.status !== CV_STATUS.DELETED,
    );

    createGroup(
      TEAM_PEOPLE_GROUPS.SELF_MANAGED,
      (profile) => !!profile.account && profile.status !== CV_STATUS.DELETED,
    );

    createGroup(
      TEAM_PEOPLE_GROUPS.REMOVED,
      (profile) => profile.status === CV_STATUS.DELETED,
    );

    return result;
  }, [members]);

  /**
   * Count of active (non-deleted) members of the team.
   * @type {number}
   */
  const activeMembersCount = useMemo(
    () => members.filter((m) => m.status !== CV_STATUS.DELETED).length,
    [members],
  );

  return {
    isImporting,
    data,
    members,
    profiles,
    groups,
    activeMembersCount,
  };
};
