import React, { createContext, useMemo, useContext, useCallback } from 'react';
import { useCollectionData } from 'react-firebase-hooks/firestore';

import services from '../utils/services';
import { useAuth } from '../auth/AuthProvider';
import { useCandidatesData } from './useCandidatesData';

/**
 * @typedef {Object} Assignment
 * @prop {string} id - Assignment ID (either firebase position ID or external assignment id)
 * @prop {string} candidate — ID of the related Candidates document
 */

/**
 * @typedef {Object} CandidateContextValue
 * @prop {import('./useCandidatesData').CandidateDataItem[]} candidates
 * @prop {Assignment[]} assignments
 * @prop {boolean} candidatesLoading
 * @prop {boolean} assignmentsLoading
 * @prop {boolean} loading
 * @prop {Function} getCandidateAssignments
 * @prop {Function} getCandidateById
 * @prop {Function} findCandidatesByStatus
 */

export const CandidatesContext = createContext({});

/**
 * @implements {FirestoreDataConverter}
 * Convert a document at `candidates/{id}/assignments` to object with
 * the assignment id and parent candidate {id}
 */
const assignmentsConverter = {
  fromFirestore(snap) {
    return {
      ...snap.data(),
      id: snap.id,
      candidate: snap.ref.parent.parent.id,
    };
  },
  toFirestore({ id, candidate, ...data }) {
    return data;
  },
};

export const CandidatesProvider = ({ children }) => {
  const { profile } = useAuth();

  // query all the candidates related to the candidate (assigned to positions, or just saved)
  const candidatesQuery = useMemo(
    () =>
      !!profile?.tenant &&
      services
        .get('db')
        .collection('candidates')
        .where('client', '==', profile.tenant),
    [profile?.tenant],
  );
  /**
   * @type {[Array<import('./useCandidatesData').CandidateDataItem>,boolean,string]}
   */
  const [candidates, candidatesLoading] = useCandidatesData(candidatesQuery);

  // query all the assignments of the client
  const assignmentsQuery = useMemo(() => {
    if (!profile?.tenant) {
      return null;
    }
    return services
      .get('db')
      .collectionGroup('assignments')
      .where('client', '==', profile.tenant)
      .withConverter(assignmentsConverter);
  }, [profile?.tenant]);
  const [assignments = [], assignmentsLoading] =
    useCollectionData(assignmentsQuery);

  // Helpers
  const getCandidateAssignments = useCallback(
    (candidate) => assignments.filter((a) => a.candidate === candidate.id),
    [assignments],
  );
  const getCandidateById = useCallback(
    (id) =>
      candidates.find((c) => c.candidate.id === id || c.profile.id === id),
    [candidates],
  );
  const findCandidatesByStatus = useCallback(
    (status) => candidates.filter((c) => c.candidate.status === status),
    [candidates],
  );

  const contextValue = useMemo(
    () => ({
      candidates,
      assignments,
      candidatesLoading,
      assignmentsLoading,
      loading: candidatesLoading || assignmentsLoading,
      getCandidateAssignments,
      getCandidateById,
      findCandidatesByStatus,
    }),
    [
      candidates,
      assignments,
      candidatesLoading,
      assignmentsLoading,
      getCandidateAssignments,
      getCandidateById,
      findCandidatesByStatus,
    ],
  );

  return (
    <CandidatesContext.Provider value={contextValue}>
      {children}
    </CandidatesContext.Provider>
  );
};

/**
 *
 * @returns {CandidateContextValue}
 */
export const useCandidates = () => useContext(CandidatesContext);

export const waitForCandidates = (WrappedComponent) => (props) => {
  const { loading } = useCandidates();
  if (loading) {
    return null;
  }
  return <WrappedComponent {...props} />;
};
