import { Input, Form } from 'antd';
import styled from 'styled-components';

import { Magnifier } from '../../icons';

const SearchContainer = styled(Form.Item)`
  margin-left: auto;
  flex-basis: 0;
  flex-grow: 1;
  min-width: 260px;
  max-width: 410px;
  margin-bottom: 0;
`;

const Search = styled(Input.Search)`
  .ant-input-group {
    & > .ant-input {
      border: 1px solid #eeefff;
      box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
      border-radius: 100px;
      height: 45px;
      padding: 5px 24px;
      font-size: 18px;
    }
  }

  .ant-input-group-addon:last-child {
    position: absolute;
    left: auto !important;
    right: 50px;
    top: 8px;

    & > .ant-btn.ant-input-search-button {
      min-width: auto;
      border: none;
      background: none;
      padding: 0;
      margin: 0;
      box-shadow: none;

      svg {
        width: 16px;
        height: 16px;
        margin: 0;

        path {
          stroke: --var(c_hint);
        }
      }
    }
  }
`;

export const InputSearch = ({ ...props }) => {
  return (
    <SearchContainer>
      <Search placeholder="Search" enterButton={<Magnifier />} {...props} />
    </SearchContainer>
  );
};
