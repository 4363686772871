import { useDrawerContext } from '@yougig/ui/components/common/DrawerProvider';
import { Drawer } from '@yougig/ui/components/elements/Drawer';

import { InviteForm } from '../../../components/talent/InviteForm';
import { ProfileForm } from '../../../components/talent/ProfileForm';
import { TalentProfile } from '../../../components/talent/TalentProfile';
import { TalentReport } from './TalentReport';

export const PeopleDrawer = () => {
  const { viewMode, width = 708, closeDrawer } = useDrawerContext();

  return (
    <Drawer
      visible={!!viewMode}
      onClose={closeDrawer}
      width={width}
      className="drawer-no-padding">
      {viewMode === 'profile' && <TalentProfile />}
      {(viewMode === 'createProfile' || viewMode === 'editCandidate') && (
        <ProfileForm />
      )}
      {viewMode === 'report' && <TalentReport />}
      {viewMode === 'invite' && <InviteForm />}
    </Drawer>
  );
};
