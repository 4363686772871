export const Company = (props) => {
  return (
    <svg
      width={114}
      height={114}
      viewBox="0 0 114 114"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g clipPath="url(#prefix__clip0)">
        <rect
          x={3.182}
          y={86.01}
          width={4}
          height={13}
          rx={2}
          transform="rotate(-45 3.182 86.01)"
          fill="#19BFA1"
        />
        <rect
          x={12.374}
          y={83.182}
          width={4}
          height={13}
          rx={2}
          transform="rotate(45 12.374 83.182)"
          fill="#19BFA1"
        />
        <rect x={93.5} y={89} width={4} height={13} rx={2} fill="#573D8B" />
        <rect
          x={102}
          y={93.5}
          width={4}
          height={13}
          rx={2}
          transform="rotate(90 102 93.5)"
          fill="#573D8B"
        />
        <rect
          x={97}
          y={15}
          width={4}
          height={13}
          rx={2}
          transform="rotate(90 97 15)"
          fill="#573D8B"
        />
        <rect
          x={104}
          y={22.5}
          width={4}
          height={13}
          rx={2}
          transform="rotate(90 104 22.5)"
          fill="#573D8B"
        />
        <circle cx={61} cy={102} r={4} stroke="#19BFA1" strokeWidth={4} />
        <circle cx={104} cy={58} r={4} stroke="#977DE0" strokeWidth={4} />
        <circle cx={33} cy={13} r={4} stroke="#977DE0" strokeWidth={4} />
        <rect
          x={13}
          y={40}
          width={4}
          height={13}
          rx={2}
          transform="rotate(90 13 40)"
          fill="#19BFA1"
        />
        <rect
          x={20}
          y={47.5}
          width={4}
          height={13}
          rx={2}
          transform="rotate(90 20 47.5)"
          fill="#19BFA1"
        />
        <path
          d="M80.035 44.68c-2.219 2.644-6.64 2.039-9.285-.18 0 0 3.049-2.568 5.267-5.212l4.018-4.788c2.644 2.219 2.219 7.536 0 10.18zM38.25 34.5V47a6.25 6.25 0 110-12.5zM33.25 67l-3.75-1.25-.28-1.4C28.026 58.38 32.2 52.671 38.25 52v28.75L34.5 79.5 33.25 67zM62 43.25V52l6.25 2.5L55.75 67V57l-6.25-1.25L62 43.25z"
          fill="#D8E6FC"
        />
        <path
          clipRule="evenodd"
          d="M38.248 47.625a7.5 7.5 0 100-15 7.5 7.5 0 000 15z"
          stroke="#1C1033"
          strokeWidth={3.75}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M41.563 51.977a9.382 9.382 0 00-12.69 8.773v5.625h3.75l1.875 15H42l1.875-15h3.75"
          stroke="#1C1033"
          strokeWidth={3.75}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          clipRule="evenodd"
          d="M75.748 47.625a7.5 7.5 0 100-15 7.5 7.5 0 000 15z"
          stroke="#1C1033"
          strokeWidth={3.75}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M74.547 51.45a9.375 9.375 0 0110.575 9.3v5.625h-3.75l-1.874 15H72l-1.875-15h-3.75"
          stroke="#1C1033"
          strokeWidth={3.75}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          clipRule="evenodd"
          d="M62.072 42l-13.4 13.4a.937.937 0 00.666 1.6h5.234v11.25l13.4-13.4a.937.937 0 00-.662-1.6h-5.238V42z"
          stroke="#1C1033"
          strokeWidth={3.75}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" d="M0 0h114v114H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
