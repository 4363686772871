import { Options } from '../utils/Options';

export const TEAM_PEOPLE_GROUPS = {
  MANAGED_BY_TEAM: 'managed_by_team',
  SELF_MANAGED: 'self_managed',
  REMOVED: 'removed',
};

export const TEAM_PEOPLE_GROUPS_OPTIONS = new Options(
  {
    value: TEAM_PEOPLE_GROUPS.MANAGED_BY_TEAM,
    label: 'Managed by team',
  },
  {
    value: TEAM_PEOPLE_GROUPS.SELF_MANAGED,
    label: 'Self-managed',
  },
  {
    value: TEAM_PEOPLE_GROUPS.REMOVED,
    label: 'Removed',
  },
);

export const LINKED_IN_TEAM_STATUS = {
  SCRAPPING: 'scrapping',
  DONE: 'done',
  ERROR: 'error',
};
