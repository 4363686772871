export const Calendar = (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <rect
      x="0.75"
      y="3.75"
      width="22.5"
      height="19.5"
      rx="1.3125"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M1 9.79992H23"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.86682 6.13333V1"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.1334 6.13333V1"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.76721 13.4667C5.5647 13.4667 5.40054 13.6309 5.40054 13.8334C5.40054 14.0359 5.5647 14.2 5.76721 14.2C5.96971 14.2 6.13388 14.0359 6.13388 13.8334C6.13388 13.6309 5.96971 13.4667 5.76721 13.4667"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.76721 18.6C5.5647 18.6 5.40054 18.7642 5.40054 18.9667C5.40054 19.1692 5.5647 19.3333 5.76721 19.3333C5.96971 19.3333 6.13388 19.1692 6.13388 18.9667C6.13388 18.7642 5.96971 18.6 5.76721 18.6"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.0006 13.4667C11.7981 13.4667 11.6339 13.6309 11.6339 13.8334C11.6339 14.0359 11.7981 14.2 12.0006 14.2C12.2031 14.2 12.3673 14.0359 12.3673 13.8334C12.3673 13.6309 12.2031 13.4667 12.0006 13.4667"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.0006 18.6C11.7981 18.6 11.6339 18.7642 11.6339 18.9667C11.6339 19.1692 11.7981 19.3333 12.0006 19.3333C12.2031 19.3333 12.3673 19.1692 12.3673 18.9667C12.3673 18.7642 12.2031 18.6 12.0006 18.6"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.233 13.4667C18.0305 13.4667 17.8664 13.6309 17.8664 13.8334C17.8664 14.0359 18.0305 14.2 18.233 14.2C18.4355 14.2 18.5997 14.0359 18.5997 13.8334C18.5997 13.6309 18.4355 13.4667 18.233 13.4667"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.233 18.6C18.0305 18.6 17.8664 18.7642 17.8664 18.9667C17.8664 19.1692 18.0305 19.3333 18.233 19.3333C18.4355 19.3333 18.5997 19.1692 18.5997 18.9667C18.5997 18.7642 18.4355 18.6 18.233 18.6"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
