import { useMemo } from 'react';
import compareAsc from 'date-fns/compareAsc';

import { REQUEST_STATUS_OPTIONS } from './constants';

const sortByActiveStatus = (a, b) => {
  if (a.active && !b.active) {
    return -1;
  } else if (!a.active && b.active) {
    return 1;
  } else {
    return 0;
  }
};

const sortByDateTime = (a, b) => {
  return compareAsc(a.dateTime, b.dateTime);
};

export const useSortRequests = (actions) => {
  return useMemo(() => {
    return actions
      .map((request) => {
        request.active = REQUEST_STATUS_OPTIONS.get(request.status).active;
        return request;
      })
      .sort(sortByDateTime)
      .sort(sortByActiveStatus);
  }, [actions]);
};
