import { useMemo, useState, memo } from 'react';
import { Button as AntButton } from 'antd';
import styled from 'styled-components';

import { Chat } from '@yougig/ui/components/chat/Chat';
import { useChatInstance } from '@yougig/shared/chat/useChat';
import { useChatMessages } from '@yougig/shared/chat/useChatMessages';
import { SENDER_ROLES } from '@yougig/shared/chat/constants';
import { Drawer } from '@yougig/ui/components/elements/Drawer';

const Button = styled(AntButton)`
  &.ant-btn {
    margin-left: 20px;
    margin-right: 20px;
  }
  &.m-0 {
    display: block;
    margin-left: 0;
    margin-right: 0;
  }
`;

const ChatUnread = styled.span`
  width: 6px;
  height: 6px;
  margin-left: 3px;
  margin-bottom: 6px;
  display: inline-block;
  border-radius: 10px;
  background-color: var(--c_green);
`;

export const ChatButton = memo(({ guestRef, chatType, className }) => {
  const [isDrawerVisible, toggleDrawerVisibility] = useState(false);
  const chat = useChatInstance(guestRef, chatType);
  const chatProps = useChatMessages(chat, SENDER_ROLES.GUEST);

  const hasUnreadMessage = useMemo(() => {
    if (!chatProps.messages.length) {
      return false;
    }
    const [lastMessage] = chatProps.messages;
    return lastMessage.senderRole !== SENDER_ROLES.GUEST;
  }, [chatProps.messages]);

  return (
    <>
      <Button
        type="ghost"
        className={className}
        onClick={() => toggleDrawerVisibility(true)}>
        Chat {hasUnreadMessage && <ChatUnread />}
      </Button>
      <Drawer
        className="drawer-no-padding"
        visible={isDrawerVisible}
        onClose={() => toggleDrawerVisibility(false)}>
        <Chat title="Your Personal Manager" {...chatProps} />
      </Drawer>
    </>
  );
});
