import merge from 'lodash/merge';
import pick from 'lodash/pick';
import uniqBy from 'lodash/uniqBy';
import lowerCase from 'lodash/lowerCase';

import services from '../utils/services';
import { Attachments } from '../common/Attachments';
import { AVAILABILITY } from '../candidates/constants';
import { CV_STATUS } from './constants';
import { EmailService } from '../utils/EmailService';

export class Profile {
  /**
   * @private
   */
  _apiData;
  /**
   * @private
   */
  _firebaseData;

  _id;
  _ref;

  status = CV_STATUS.NOT_INVITED;

  /**
   * Wether this profile is not saved yet in firebase.
   * @private
   */
  isDraft = true;

  constructor() {
    // Proxify instance to add the magic getter for profile fields if there is not appropriate getter.
    return new Proxy(this, {
      get(profile, fieldName) {
        return fieldName in profile
          ? profile[fieldName]
          : profile.firebaseData[fieldName] || profile.apiData[fieldName];
      },
    });
  }

  static converter = {
    /**
     * Returns the payload to put into firebase.
     * NOTE: use Profile.save() method instead.
     *
     * @param {Profile|Object} data Profile instance or payload
     * @returns {Object}
     */
    toFirestore(data) {
      return data instanceof Profile ? data.firebaseData : data;
    },
    fromFirestore(snapshot) {
      return Profile.fromSnapshot(snapshot);
    },
  };

  /**
   * Returns data of the firebase-stored profile.
   */
  get firebaseData() {
    return this._firebaseData || {};
  }

  /**
   * Set the data for firebase profile.
   */
  set firebaseData({ id, __ref, _ref, availability, ...data }) {
    if (availability) {
      for (let key in availability) {
        if (availability[key] instanceof services.get('firestore').Timestamp) {
          availability[key] = availability[key].toDate();
        }
      }
      data.availability = availability;
    }

    this._firebaseData = data;

    if (id) {
      this._id = id;
    }
    if (__ref || _ref) {
      this._ref = (__ref || _ref).withConverter(Profile.converter);
    }
    if (!this._ref && this._id) {
      this._ref = services
        .get('db')
        .doc(`profiles/${id}`)
        .withConverter(Profile.converter);
    }
  }

  get apiData() {
    return this._apiData || {};
  }

  set apiData(data) {
    this._apiData = data;
    if (data.external_id && !this._id) {
      this._id = data.external_id;
      this._ref = services
        .get('db')
        .doc(`profiles/${data.external_id}`)
        .withConverter(Profile.converter);
      this.isDraft = false;
    }
  }

  get id() {
    return this._id;
  }

  get externalId() {
    return this.apiData.id || this.firebaseData.externalId;
  }

  get ref() {
    return this._ref;
  }

  get experienceRef() {
    return this._ref?.collection('experience');
  }

  get educationRef() {
    return this._ref?.collection('education');
  }

  get portfolioRef() {
    return this._ref?.collection('portfolio');
  }

  static fromSnapshot(snapshot) {
    const profile = new Profile();
    profile.firebaseData = snapshot.data();
    profile._id = snapshot.id;
    profile._ref = snapshot.ref.withConverter(Profile.converter);
    profile.isDraft = false;
    profile.status = profile.firebaseData.status || CV_STATUS.PUBLISH;

    return profile;
  }

  static fromApi(data) {
    const profile = new Profile();
    profile.apiData = data;
    profile._firebaseData = {
      firstName: profile.firstName,
      lastName: profile.lastName,
      title: profile.title,
      email: profile.email,
      externalId: profile.externalId,
    };

    // Unhandled fields with getters
    // experience,
    // experience_info,
    // contacts,
    // source,
    // region,
    // vetted,
    // worked_with_us,
    return profile;
  }

  /**
   * Generates profile DocumentReference if not exists yet.
   */
  ensureProfile() {
    if (!this._ref) {
      const ref = services.get('db').collection('profiles').doc();
      this._id = ref.id;
      this._ref = ref.withConverter(Profile.converter);
    }
  }

  async transformValues(values) {
    this.ensureProfile();

    const handleAttachments = async (fieldName) => {
      const files = values[fieldName];
      if (!Array.isArray(files)) {
        return;
      }
      const att = new Attachments(files, `/profiles/${this._id}/${fieldName}`);
      await att.uploadNew();
      if (Array.isArray(this.firebaseData[fieldName])) {
        await att.removeOrphaned(this.firebaseData[fieldName]);
      }
      const dbValue = att.toFirestore();
      if (
        !dbValue.length &&
        (this.isDraft || !this.firebaseData[fieldName]?.length)
      ) {
        delete values[fieldName];
        return;
      }
      values[fieldName] = dbValue;
    };

    await handleAttachments('internalAttachments');
    await handleAttachments('attachments');
    await handleAttachments('talentAttachments');

    if (values.availability?.availability) {
      switch (values.availability.status) {
        case AVAILABILITY.AVAILABLE:
          values.availability = pick(values.availability, [
            'status',
            'availability',
            'fromDate',
            'untilDate',
          ]);
          values.availability.availability = Number(
            values.availability.availability,
          );
          break;
        case AVAILABILITY.NOT_AVAILABLE:
          values.availability = pick(values.availability, [
            'status',
            'notAvailableUntilDate',
          ]);
          break;
        default:
          delete values.availability;
          break;
      }
    }

    return values;
  }

  /**
   * Creates or updates the profile in firebase.
   *
   * Creates the document and updates the id and _ref fields.
   * Ensures the correct status for profile.
   *
   * @param {Object} values Payload of fields to write into firestore.
   */
  async save(values = {}) {
    const payload = await this.transformValues(
      // if it's not yet stored in firebase, we need to save also basic data from apiData.
      this.isDraft ? { ...this.firebaseData, ...values } : values,
    );

    // overwrite status
    payload.status = payload.status || this.status;
    if (!payload.status || payload.status === CV_STATUS.NOT_INVITED) {
      payload.status = CV_STATUS.PUBLISH;
    }

    if (this.apiData.id) {
      payload.externalId = this.apiData.id;
    }

    if (this.isDraft) {
      await this._ref.withConverter(null).set(payload);
    } else {
      await this._ref.withConverter(null).update(payload);
    }
    this.firebaseData = merge({}, this.firebaseData, payload);

    this.isDraft = false;

    return this;
  }

  async addExperience(data) {
    this.ensureProfile();

    await this.addToCollection(this.experienceRef, data);

    return this;
  }

  async addEducation(data) {
    this.ensureProfile();

    await this.addToCollection(this.educationRef, data);

    return this;
  }

  async addToPortfolio(data) {
    if (this.isDraft) {
      await this.save();
    }

    await this.portfolioRef.add(data);
  }

  async removeFromPortfolio(docId) {
    await this.portfolioRef?.doc(docId).delete();
  }

  //#region PROFILE ACTIONS
  /**
   * Sends invite into the team to the given email.
   *
   * @param {string} email Email to send invite to.
   * @param {DocumentReference} team Document reference to the team.
   */
  async invite(email, team) {
    const emailCheck = services.get('functions').httpsCallable('accountExists');
    const accountExists = await emailCheck({ email });

    if (accountExists.data) {
      const error = new Error('Account with this email already exists');
      error.code = 'email-exists';
      throw error;
    }

    const payload = { email, status: CV_STATUS.INVITED, team };
    await this.save(payload);

    try {
      await EmailService.sendTeamInvite(this, email);
    } catch (err) {
      console.log('Cannot send invitation email');
      console.error(err);
    }
  }

  /**
   * Restore profiles from deleted status.
   */
  restore() {
    // const status = this.firebaseData.account?.id
    //   ? CV_STATUS.PUBLISH
    //   : services.get('firestore').FieldValue.delete();
    return this.save({ status: CV_STATUS.PUBLISH });
  }

  async addToCollection(collectionRef, { id, ...data }) {
    if (this.isDraft) {
      await this.save();
    }

    if (id) {
      await collectionRef.doc(id).set(data);
    } else {
      await collectionRef.add(data);
    }

    return this;
  }

  /**
   * Marks the profile as deleted.
   * @param {DocumentReference} team Reference to the team's document.
   */
  async removeFromTeam(team) {
    const payload = { team, status: CV_STATUS.DELETED };
    return this.save(payload);
  }
  //#endregion

  //#region Field getters
  /**
   * This is a unique key for the instance. can be used for lists key.
   */
  get key() {
    return this.id || this.externalId;
  }

  get team() {
    return this._firebaseData.team;
  }

  set team(teamRef) {
    this._firebaseData.team = teamRef;
  }

  get firstName() {
    return this.firebaseData.firstName || this.apiData.name || '';
  }

  get lastName() {
    return (
      this.firebaseData.lastName ||
      this.apiData.full_name?.replace(this.apiData.name, '').trim() ||
      ''
    );
  }

  get fullName() {
    return `${this.firstName} ${this.lastName}`;
  }

  /**
   * @deprecated
   */
  get full_name() {
    return this.fullName;
  }

  get title() {
    return this.firebaseData.title || this.apiData.title || '';
  }

  get email() {
    return (
      this.firebaseData.email ||
      this.apiData.contacts?.find((c) => c.includes('@')) ||
      ''
    );
  }

  get phoneObject() {
    return this.firebaseData.phone || null;
  }

  get phone() {
    const phone = this.phoneObject;
    let phoneString = phone
      ? `${phone.code}${phone.number}`
      : this.apiData.contacts?.find((i) => /^[0-9- +]$/.test(i)) || '';
    // TODO: this formatting conflicts with unique values of concatenation with api data.
    // if (phoneString && phoneString[0] !== '+') {
    //   phoneString = `+${phoneString}`;
    // }
    return phoneString;
  }

  get countryCode() {
    return this.firebaseData.country || this.apiData.country_code;
  }

  get countryName() {
    // TODO: consider to use countries.json for mobile app as well.
    return this.apiData.country || this.apiData.country_code;
  }

  get bio() {
    return this.firebaseData.introduction || this.apiData.description;
  }

  get skills() {
    return uniqBy(
      (this.apiData.skills || [])
        .concat(this.firebaseData.skills)
        .filter(Boolean),
      lowerCase,
    );
  }

  get availability() {
    if (this.firebaseData.availability) {
      return this.firebaseData.availability;
    } else if (this._apiData) {
      return {
        status: this.apiData.available
          ? AVAILABILITY.AVAILABLE
          : AVAILABILITY.NOT_AVAILABLE,
        availability: this.apiData.availability,
      };
    }
    return null;
  }

  get scoring() {
    return Object.assign(
      {},
      this.apiData.scoring,
      this.apiData.manual_scoring,
      this.firebaseData.manual_scoring,
    );
  }

  get rate() {
    return this.apiData.rate;
  }

  get internalRate() {
    return this.firebaseData.internalRate || this.apiData.internal_rate;
  }

  /**
   * First line of notes truncated up to 10 words
   */
  get notesFirstLine() {
    if (this.firebaseData.notes) {
      const firstLine = this.firebaseData.notes.toString().split('\n').shift();
      let truncated = firstLine.split(' ').splice(0, 10).join(' ');
      if (truncated.length !== firstLine.length) {
        truncated += '...';
      }
      return truncated;
    }
    return null;
  }
  //#endregion

  // #region Static methods for backward compatibility.
  // TODO: Remove after refacting existing code
  static async create(values) {
    const profile = new Profile();
    await profile.save(values);

    return profile;
  }

  static async update(profileData, { id, __ref, _ref, ...values }) {
    const profile = new Profile();
    profile.firebaseData = profileData;

    await profile.save(values);

    return profile._ref;
  }
  //#endregion
}
