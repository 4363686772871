import { Button } from 'antd';

import { useDrawerContext } from '@yougig/ui/components/common/DrawerProvider';
import { ReportForm } from '@yougig/ui/components/reports/ReportForm';
import { REPORT_STATUS } from '@yougig/shared/reports/constants';

import { ReportTimesheet } from '../../../components/reports/ReportTimesheet';

export const TalentReport = () => {
  const { report, profile, setDrawerView } = useDrawerContext();

  return (
    <>
      <div className="drawer-section">
        <ReportForm
          report={report}
          title="Consultant report"
          subHeading={`${profile.fullName} for ${report.businessName}`}
          statusType="label"
          onClose={() => {
            setDrawerView('profile', { report: null });
          }}
          renderButtons={(formik) => (
            <>
              {report.status === REPORT_STATUS.REQUESTED && (
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={formik.isSubmitting}
                  onClick={() =>
                    formik.setFieldValue('status', REPORT_STATUS.PENDING)
                  }>
                  Send Report
                </Button>
              )}
              {report.status === REPORT_STATUS.PENDING && (
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={formik.isSubmitting}
                  onClick={() =>
                    formik.setFieldValue('status', REPORT_STATUS.REQUESTED)
                  }>
                  Withdraw
                </Button>
              )}
            </>
          )}
        />
      </div>
      <ReportTimesheet report={report} />
    </>
  );
};
