export const Warning = ({ color, ...props }) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M5.09983 1.53158L0.641089 9.34255C0.54916 9.50961 0.500519 9.69901 0.500004 9.89192C0.499489 10.0848 0.547119 10.2745 0.638155 10.4421C0.729191 10.6097 0.86046 10.7494 1.0189 10.8472C1.17734 10.945 1.35744 10.9976 1.54126 10.9998H10.4587C10.6426 10.9976 10.8227 10.945 10.9811 10.8472C11.1395 10.7494 11.2708 10.6097 11.3618 10.4421C11.4529 10.2745 11.5005 10.0848 11.5 9.89192C11.4995 9.69901 11.4508 9.50961 11.3589 9.34255L6.90017 1.53158C6.80633 1.36923 6.67419 1.235 6.51652 1.14185C6.35884 1.04869 6.18095 0.999756 6 0.999756C5.81905 0.999756 5.64116 1.04869 5.48348 1.14185C5.32581 1.235 5.19367 1.36923 5.09983 1.53158V1.53158Z"
      stroke={color ? color : 'currentColor'}
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 4.5L6 7"
      stroke={color ? color : 'currentColor'}
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6 8.49976L6 8.99976"
      stroke={color ? color : 'currentColor'}
      strokeWidth="0.75"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
