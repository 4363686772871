export const ArrowRight = (props) => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M15 7.998l.323-.381a.5.5 0 010 .763L15 7.998zM9.01 3.591a.5.5 0 01.646-.764l-.645.764zm.646 9.579a.5.5 0 11-.645-.764l.645.764zM1 8.498a.5.5 0 010-1v1zm13.677-.118L9.011 3.59l.645-.763 5.667 4.79-.646.763zm-5.666 4.026l5.666-4.79.646.764-5.667 4.79-.645-.764zM1 7.498h14v1H1v-1z"
        fill="currentColor"
      />
    </svg>
  );
};
