import { Checkbox, Form, Input } from 'formik-antd';
import { Collapse } from 'antd';
import { useFormikContext } from 'formik';

import { CountryField } from '@yougig/ui/components/form/CountryField';
import { SkillsToolsField } from '@yougig/ui/components/form/SkillsToolsField';
import { ArrowBottom, Creator, Owner } from '@yougig/ui/icons';
import { PhoneField } from '@yougig/ui/components/form/PhoneField';

export const TeamForm = () => {
  const { values } = useFormikContext();

  return (
    <>
      <div className="row">
        <Form.Item name="team.name" label="Team Name" className="col-6">
          <Input name="team.name" placeholder="Team Name" />
        </Form.Item>
        <Form.Item name="team.legalName" label="Legal Name" className="col-6">
          <Input name="team.legalName" placeholder="Legal Name" />
        </Form.Item>
      </div>
      <div className="row">
        <Form.Item name="team.location" label="Location" className="col-6">
          <CountryField name="team.location" />
        </Form.Item>
      </div>
      <div className="row">
        <SkillsToolsField
          name="team.speciality"
          label="Team Speciality"
          placeholder="Add Technology Stack, etc."
        />
      </div>
      <div className="row">
        <Form.Item
          name="team.linkedinCompany"
          label="Linkedin Company page"
          className="col-12">
          <Input name="team.linkedinCompany" placeholder="Link" />
        </Form.Item>
      </div>
      <Form.Item name="team.import">
        <Checkbox name="team.import">Import Employees from Linkedin</Checkbox>
      </Form.Item>
      <Collapse
        expandIcon={ArrowBottom}
        className="mt-32"
        defaultActiveKey={['creator', 'owner']}
        expandIconPosition="right">
        <Collapse.Panel
          key="creator"
          showArrow={false}
          header={
            <>
              <Creator width={24} height={24} />
              Creator Info
            </>
          }>
          <>
            <div className="row">
              <Form.Item name="firstName" label="First Name" className="col-6">
                <Input name="firstName" placeholder="First Name" />
              </Form.Item>
              <Form.Item name="lastName" label="Last Name" className="col-6">
                <Input name="lastName" placeholder="Last Name" />
              </Form.Item>
              <Form.Item name="title" label="Role" className="col-6">
                <Input
                  name="title"
                  placeholder="CEO / Head of... / HR Manager"
                />
              </Form.Item>
            </div>
            <div className="row">
              <Form.Item name="email" label="Email" className="col-6">
                <Input name="email" placeholder="email@example.com" />
              </Form.Item>
              <PhoneField label="Mobile phone" name="phone" className="col-6" />
              <Form.Item
                name="password"
                label="Create Password"
                className="col-6">
                <Input.Password
                  name="password"
                  placeholder="Password"
                  iconRender={(visible) =>
                    visible ? <span>Hide</span> : <span>Show</span>
                  }
                />
              </Form.Item>
            </div>
            <Form.Item name="right">
              <Checkbox name="right">
                I have the right to sign documents
              </Checkbox>
            </Form.Item>
          </>
        </Collapse.Panel>
        {!values.right && (
          <Collapse.Panel
            key="owner"
            showArrow={false}
            header={
              <>
                <Owner width={24} height={24} outlined={false} />
                Owner Info
              </>
            }>
            <>
              <div className="row">
                <Form.Item
                  name="signPerson.firstName"
                  label="First Name"
                  className="col-6">
                  <Input name="signPerson.firstName" placeholder="First Name" />
                </Form.Item>
                <Form.Item
                  name="signPerson.lastName"
                  label="Last Name"
                  className="col-6">
                  <Input name="signPerson.lastName" placeholder="Last Name" />
                </Form.Item>
                <Form.Item
                  name="signPerson.title"
                  label="Role"
                  className="col-6">
                  <Input
                    name="signPerson.title"
                    placeholder="CEO / Head of... / HR Manager"
                  />
                </Form.Item>
              </div>
              <div className="row">
                <Form.Item
                  name="signPerson.email"
                  label="Email"
                  className="col-6">
                  <Input
                    name="signPerson.email"
                    placeholder="email@example.com"
                  />
                </Form.Item>
                <PhoneField
                  label="Mobile phone"
                  name="signPerson.phone"
                  className="col-6"
                />
              </div>
            </>
          </Collapse.Panel>
        )}
      </Collapse>
    </>
  );
};
