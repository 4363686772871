import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Row } from 'antd';

import { useProfileJobs } from '@yougig/shared/jobs/useProfileJobs';
import { CANDIDATE_STATUS_OPTIONS } from '@yougig/shared/candidates/constants';
import { StatusLabel } from '@yougig/ui/components/elements/Status';

const JobsList = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  gap: 10px;
`;

export const JobsTab = ({ profile }) => {
  const appliedJobs = useProfileJobs(profile._ref);

  return (
    <JobsList>
      {appliedJobs?.map(({ candidateStatus, job }) => {
        const { background, ...statusProps } =
          CANDIDATE_STATUS_OPTIONS.get(candidateStatus) || {};
        return (
          <Row
            key={job.key}
            justify="space-between"
            wrap={false}
            align="center">
            <Link to={`/jobs/${job.key}`}>{job.title}</Link>
            <StatusLabel {...statusProps} />
          </Row>
        );
      })}
    </JobsList>
  );
};
