export const Flag = ({ outlined = false, ...props }) => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M2 5h56l-7 16.5L58 39H2V5z"
        fill={outlined ? '#fff' : 'currentColor'}
      />
      <path
        d="M2.26 1.875v56.25M2.25 5.625h53.374a1.872 1.872 0 011.713 2.625l-5.99 13.5a1.878 1.878 0 000 1.523l6 13.477a1.875 1.875 0 01-1.712 2.638H2.25"
        stroke="currentColor"
        strokeWidth={3.75}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
