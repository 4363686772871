export const Creator = ({ width, height, ...props }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M19.951 23.001c.054.55-.398.999-.95.999h-6C12.448 24 12 23.552 12 23v-7.002c0-.553.45-1.005.997-.929 3.658.512 6.549 3.8 6.954 7.932zM12 11.5V6.23.5c3.314 0 5.5 1.559 5.5 5s-2.186 6-5.5 6z"
        fill="#DDFFF9"
      />
      <path
        clipRule="evenodd"
        d="M12 11.25a5.25 5.25 0 100-10.5 5.25 5.25 0 000 10.5z"
        stroke="#1C1033"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.477 3.334A8.711 8.711 0 0017.2 5.289M2.25 23.25c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75"
        stroke="#1C1033"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
