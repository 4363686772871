const VARIANTS = {
  green: '#DDFFF9',
  blue: '#D8E6FC',
};

export const CV = ({ variant = 'green', color = '#353535', props }) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M21.5 5l-19 18 3.5.5h3l3.25.5h6.25l3-.5.5-2V9l-.5-4z"
        fill={VARIANTS[variant] || 'none'}
      />
      <path
        clipRule="evenodd"
        d="M9.75 4.25h-6.5a1.5 1.5 0 00-1.5 1.5v16.5a1.5 1.5 0 001.5 1.5h17.5a1.5 1.5 0 001.5-1.5V5.75a1.5 1.5 0 00-1.5-1.5h-6.5"
        stroke={color}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.25 3.5a2.25 2.25 0 00-4.5 0v2c0 .414.336.75.75.75h3a.75.75 0 00.75-.75v-2z"
        stroke={color}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.25 19.5a3.25 3.25 0 013.25-3.25h1a3.25 3.25 0 013.25 3.25V21a.25.25 0 01-.25.25h-7a.25.25 0 01-.25-.25v-1.5z"
        stroke={color}
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx={12} cy={11.5} r={2.75} stroke={color} strokeWidth={1.5} />
    </svg>
  );
};
