import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useCollectionData,
  useDocumentData,
} from 'react-firebase-hooks/firestore';
import uniqBy from 'lodash/uniqBy';
import maxDate from 'date-fns/max';
import unionBy from 'lodash/unionBy';
import sumBy from 'lodash/sumBy';

import { Profile } from '@yougig/shared/talents/Profile';

import services from '../utils/services';
import { Report } from './Report';
import { CANDIDATE_STATUS } from '../candidates/constants';
import { REPORT_STATUS, REPORT_STATUS_OPTIONS, REPORT_TYPE } from './constants';

/**
 * @param {Report} a
 * @param {Report} b
 */
function sortReportsByStatus(a, b) {
  const aActive = REPORT_STATUS_OPTIONS.get(a.status).active;
  const bActive = REPORT_STATUS_OPTIONS.get(b.status).active;
  if (bActive && !aActive) {
    return 1;
  } else if (aActive && !bActive) {
    return -1;
  }
  return 0;
}

/**
 * Load client reports.
 * @param {FirebaseDocumentReference} client Client's user reference.
 * @param {Object} [options] Config options for the query
 * @param {boolean} [options.active] Whether to display only active (non-draft reports)
 * @param {number} [options.limit] Limit the number of loaded reports (no limit by default)
 * @return {[Array<Report>, boolean, string | null]}
 */
function useClientReports(client, { active = false, limit } = {}) {
  const reportsQuery = useMemo(() => {
    let query = services
      .get('db')
      .collection('reports')
      .where('client', '==', client)
      .where('type', '==', REPORT_TYPE.CLIENT);
    if (active) {
      query = query
        .where('status', '!=', REPORT_STATUS.DRAFT)
        .orderBy('status');
    }
    if (limit) {
      query = query.limit(limit);
    }
    return query.orderBy('startDate', 'desc').withConverter(Report.converter);
  }, [client, active, limit]);

  const [reports, loading, error] = useCollectionData(reportsQuery);

  const sortedReports = useMemo(
    () => reports?.sort(sortReportsByStatus) || [],
    [reports],
  );
  return [sortedReports, loading, error];
}

/**
 * Returns boolean value whether the timesheet (clockify integration) feature is enabled for the client.
 * @param {DocumentReference} client Client document reference
 * @returns {boolean}
 */
function useTimesheetEnabled(client) {
  const [integration] = useDocumentData(
    client.collection('integrations').doc('clockify'),
    { refField: '_ref' },
  );
  return !!integration?.enabled;
}

/**
 * Load talent reports.
 * @param {DocumentReference} talent Talent's profile reference.
 * @param {Object} [options] Config options for the query
 * @param {number} [options.limit] Limit the number of loaded reports (no limit by default)
 * @return {[Array<Report>, boolean, string | null]}
 */
function useTalentReports(talent, { limit } = {}) {
  const [loading, setLoading] = useState(true);
  const [reports, setReports] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    let query = services
      .get('db')
      .collection('reports')
      .where('type', '==', REPORT_TYPE.TALENT)
      .where('consultant', '==', talent)
      .withConverter(Report.converter);
    if (limit) {
      query = query.limit(limit);
    }
    return query.orderBy('startDate', 'desc').onSnapshot((snap) => {
      snap &&
        Promise.all(
          snap.docs.map(async (r) => {
            const report = r.data();
            const client = await report.client.get();
            report.businessName = client.get('businessName');

            return report;
          }),
        )
          .then((res) => {
            setReports(res);
            setLoading(false);
          })
          .catch((e) => setError(e.message));
    });
  }, [talent, limit]);

  return [reports, loading, error];
}

/**
 * Load existing talent reports and available talents to create report for.
 * @param {Report} report Client report to load talent reports for.
 * @return {[Array<{ profile: Profile, report?: Report }>, boolean, string | null]}
 */
function useChildReportsForManager(report) {
  /**
   * @type {[Array<{ profile: Profile, report: Report }>, Function]}
   */
  const [existingReports, setExistingReports] = useState([]);
  const [loadingReports, setLoadingReports] = useState(true);

  useEffect(() => {
    return services
      .get('db')
      .collection('reports')
      .where('type', '==', REPORT_TYPE.TALENT)
      .where('parent', '==', report.ref)
      .withConverter(Report.converter)
      .onSnapshot(async (snaps) => {
        const reports = await Promise.all(
          snaps.docs.map(async (snap) => {
            const report = snap.data();
            const profileSnap = await report.consultant
              .withConverter(Profile.converter)
              .get();
            const profile = profileSnap.data();
            return { report, profile };
          }),
        );
        setExistingReports(reports);
        setLoadingReports(false);
      });
  }, [report.ref]);

  /**
   * @type {[Profile[], Function]}
   */
  const [acceptedProfiles, setAcceptedProfiles] = useState([]);
  const [loadingProfiles, setLoadingProfiles] = useState(true);

  useEffect(() => {
    services
      .get('db')
      .collection('candidates')
      .where('client', '==', report.client)
      .where('status', '==', CANDIDATE_STATUS.ACCEPTED)
      .get()
      .then((snaps) =>
        uniqBy(
          snaps.docs.map((snap) => snap.get('consultant')).filter(Boolean),
          (ref) => ref.id,
        ),
      )
      .then((profiles) => {
        return Promise.all(
          profiles.map(async (profileRef) => {
            const profileSnap = await profileRef
              .withConverter(Profile.converter)
              .get();
            /**
             * @type {Profile}
             */
            return profileSnap.data();
          }),
        );
      })
      .then((profiles) => {
        // filter undefined profiles because there might be broken references
        // (`internalId`) in candidates collection.
        setAcceptedProfiles(profiles.filter(Boolean));
        setLoadingProfiles(false);
      });
  }, [report.client]);

  const reports = useMemo(
    () =>
      acceptedProfiles
        .filter((profile) =>
          existingReports.every((r) => !r.profile.ref.isEqual(profile.ref)),
        )
        .map((profile) => ({
          profile,
        }))
        .concat(existingReports),
    [acceptedProfiles, existingReports],
  );

  return [reports, loadingReports || loadingProfiles];
}

/**
 * Load talent reports to display names and billable hours for client.
 * @param {Report} report Client report to load talent reports for.
 * @return {[Array<{ profile: Profile, report?: Report }>, boolean, string | null]}
 */
function useChildReportsForClient(report) {
  const [data, setData] = useState([]);

  useEffect(() => {
    services
      .get('db')
      .collection('reports')
      .where('type', '==', REPORT_TYPE.TALENT)
      .where('parent', '==', report._ref)
      .where('totalBillableTime', '>', 0)
      .withConverter(Report.converter)
      .onSnapshot(async (snaps) => {
        const queryData = await Promise.all(
          snaps.docs.map(async (snap) => {
            /**
             * @type {Report}
             */
            const talentReport = snap.data();
            const profileSnap = await talentReport.consultant
              .withConverter(Profile.converter)
              .get();
            return {
              report: talentReport,
              profile: profileSnap.data(),
            };
          }),
        );
        setData(queryData);
      });
  }, [report]);

  return [data];
}

/**
 * @typedef {object} TimeEntry
 * @prop {string} id ID of the time entry document
 * @prop {string} name The name of the time entry (usually date string in format YYYY-MM-DD)
 * @prop {string} description Description of the time entry
 * @prop {number} duration Original duration (rounded by 2 digits, e.g. 8.50)
 * @prop {number} [billableDuration] Overriden value of hours billed to client (optional)
 */

/**
 * @typedef {object} UseReportTimesheetReturn
 * @prop {Array<TimeEntry} timeentries Timeentries loaded from DB, if available
 * @prop {boolean} loading Loading of time entries flag
 * @prop {string|null} error Loading time entries error
 * @prop {number} totalDuration Total duration of the list of time entries
 * @prop {Date|null} lastSync Date of the last sync
 * @prop {() => Promise<void>} refresh Callback to refresh timesheet from clockify
 * @prop {boolean} syncing Flag indicating the refresh process
 * @prop {string|null} syncError Error message of refresh process (if any)
 *
 * Hook to load and re-sync timesheets for the given report (without extended information)
 * @param {Report} report Report to load timesheet for
 * @returns {UseReportTimesheetReturn}
 */
function useReportTimesheet(report) {
  const [timeentriesRaw, rawLoading] = useCollectionData(
    report.getTimeEntriesRawQuery(),
    { idField: 'id' },
  );

  const timeentries = useMemo(() => timeentriesRaw || [], [timeentriesRaw]);

  const totalDuration = useMemo(
    () => timeentries.reduce((sum, i) => sum + (i.duration || 0), 0).toFixed(1),
    [timeentries],
  );

  const lastSync = useMemo(
    () =>
      timeentries.reduce(
        (max, i) =>
          max ? maxDate([max, i.imported.toDate()]) : i.imported.toDate(),
        null,
      ),
    [timeentries],
  );

  const [syncing, setSyncing] = useState(false);
  const [syncError, setSyncError] = useState(null);
  const refresh = useCallback(async () => {
    setSyncing(true);
    setSyncError(null);
    try {
      console.log('refresh report', report);
      await services
        .get('functions')
        .httpsCallable('clockify-syncReportTimesheet')({
        report: report.id,
      });
    } catch (error) {
      setSyncError(error.message);
    }
    setSyncing(false);
  }, [report]);

  return {
    timeentries,
    totalDuration,
    loading: rawLoading,
    lastSync,
    refresh,
    syncing,
    syncError,
  };
}

/**
 * @typedef {object} ExtendedTimesheetReturn
 * @prop {number} totalBillableDuration Total billable duration of the list of time entries
 *
 * Hook to load and re-sync timesheets for the given report (with extended information)
 * @param {Report} report Report to load timesheet for
 * @returns {UseReportTimesheetReturn & ExtendedTimesheetReturn}
 */
function useExtendedReportTimesheet(report) {
  const {
    timeentries: timeentriesRaw,
    loading: rawLoading,
    ...restReturn
  } = useReportTimesheet(report);

  const [timeentriesExt, extLoading] = useCollectionData(
    report.getTimeEntriesExtQuery(),
    { idField: 'id' },
  );

  const timeentries = useMemo(() => {
    return unionBy(
      timeentriesRaw.map((entry) => {
        const ext = (timeentriesExt || []).find((i) => i.id === entry.id);
        return ext ? { ...ext, ...entry } : entry;
      }),
      timeentriesExt || [],
      'id',
    );
  }, [timeentriesRaw, timeentriesExt]);

  const totalBillableDuration = useMemo(
    () =>
      (timeentriesExt || []).reduce(
        (sum, i) => sum + (i.billableDuration || 0),
        0,
      ),
    [timeentriesExt],
  );

  return {
    timeentries,
    totalBillableDuration,
    loading: rawLoading || extLoading,
    ...restReturn,
  };
}

export class ReportsService {
  /**
   * Creates new draft report for client with given dates.
   * @param {DocumentReference} client Document reference for client entity.
   * @param {Date} startDate Start date of the report
   * @param {Date} endDate End date of the report
   * @returns {Promise<Report>}
   */
  static createDraftReport(client, startDate, endDate) {
    return new Report().save({
      client,
      startDate,
      endDate,
    });
  }
  /**
   * Saves report's timesheet overrides.
   * @param {Report} report Report to extend (override billable duration) time entries for.
   * @param {Array<{ id: string; billableDuration: number }>} timeentries Array of billable duration
   */
  static async extendReportTimesheet(report, timeentries) {
    for await (const { id, billableDuration } of timeentries) {
      await report._ref
        .collection('time_entries_ext')
        .doc(id)
        .set({ billableDuration, name: id }, { merge: true });
    }
    await report._ref.update({
      totalBillableTime: sumBy(timeentries, 'billableDuration'),
    });
  }

  static useClientReports = useClientReports;
  static useTimesheetEnabled = useTimesheetEnabled;
  static useChildReportsForManager = useChildReportsForManager;
  static useChildReportsForClient = useChildReportsForClient;
  static useReportTimesheet = useReportTimesheet;
  static useExtendedReportTimesheet = useExtendedReportTimesheet;
  static useTalentReports = useTalentReports;
}
