import { Typography, Button } from 'antd';

export const LogoutModalBody = ({ onCancel, onConfirm }) => {
  return (
    <>
      <Typography.Paragraph>
        Are you sure you want to Log Out?
      </Typography.Paragraph>
      <div className="modal-footer">
        <Button size="large" type="ghost" onClick={() => onCancel()}>
          Cancel
        </Button>
        <Button size="large" type="primary" onClick={() => onConfirm()}>
          Log Out
        </Button>
      </div>
    </>
  );
};
