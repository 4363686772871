import { useEffect } from 'react';
import { Drawer as DrawerAntd } from 'antd';
import styled from 'styled-components';

import { Close } from '../../icons';

const StyledDrawer = styled(DrawerAntd)`
  max-width: 100%;
  .ant-drawer-content-wrapper {
    max-width: 100%;
  }
  .ant-drawer-body {
    padding: 48px;
  }
  &.drawer-no-padding {
    .ant-drawer-header-no-title .ant-drawer-close {
      padding: 14px calc(14px - var(--scroll-bar)) 14px 14px;
  }
  &.drawer-no-padding .ant-drawer-body {
    padding: 24px 0 0;
  }
  &.ant-drawer-open.drawer-offset {
    transform: translateX(-200px);
  }
  &.drawer-candidate .ant-drawer-body {
    padding: 0;
  }
  .section--heading {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 16px;

    h4 {
      margin-bottom: 0;
    }
    label.toggle {
      span {
        margin-right: 8px;
        line-height: 16px;
        vertical-align: middle;
      }
    }
  }
  .drawer-section {
    padding: 24px 48px;
    position: relative;
    &-heading {
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      .ant-typography {
        margin-bottom: 0;
        margin-right: 32px;
      }
      .ant-btn {
        svg {
          transition: all 0.2s;
        }

        &:not(.ant-btn-icon-only) {
          svg {
            vertical-align: top;
            margin: 1px 0 1px 6px;
          }
        }

        &.ant-popover-open {
          svg {
            transform: rotate(180deg);
          }
        }
      }
    }
    &-greyed {
      background-color: var(--c_ghost_white);
      margin-top: 24px;
      padding-top: 48px;
    }
    &-lock {
      background-color: var(--c_ghost_white);
      margin-top: 24px;
      padding-top: 48px;
      &:before {
        position: absolute;
        content: '';
        left: calc(50% - 23px);
        border-width: 0 23px 23px 23px;
        border-style: solid;
        border-color: transparent transparent var(--c_ghost_white) transparent;
        top: -23px;
      }
    }
    &__icon {
      position: absolute;
      left: 50%;
      top: 0;
      width: 24px;
      height: 24px;
      transform: translateX(-50%);
      z-index: 1;
    }
    &--slider {
      margin: -20px -48px 20px;
      overflow: hidden;
      .slick-track {
        margin: 0;
      }
      .slick-slider {
        padding: 16px 48px;
      }
      .slick-list {
        overflow: visible;
      }
    }

    .ant-form {
      .ant-collapse-item {
        margin-left: -48px;
        margin-right: -48px;

        .ant-collapse-header,
        .ant-collapse-content {
          padding: 12px 48px;
        }

        .ant-collapse-arrow {
          right: 48px;
        }
      }

      .ant-collapse-content-box {
        padding-left: 0;
        padding-right: 0;
      }
      .form-actions {
        display: flex;
        justify-content: flex-end;
        .ant-btn + .ant-btn {
          margin-left: 16px;
        }
      }
    }
  }
  @media (max-width: 767px) {
    .ant-drawer-header-no-title .ant-drawer-close {
      padding: 10px;
    }
    .ant-drawer-body {
      padding: 36px 36px 20px 16px;
    }
    .drawer-section {
      padding: 16px 16px;
      &-lock {
        margin-top: 24px;
        padding-top: 36px;
      }
      &--slider {
        margin-left: -16px;
        margin-right: -16px;
        .slick-slider {
          padding: 16px;
        }
      }
    }
  }
`;

export const Drawer = ({
  children,
  onClose,
  closeOnOutsideClick = true,
  ...props
}) => {
  useEffect(() => {
    if (!closeOnOutsideClick) {
      return;
    }
    const container = document.getElementById('main-container');
    container.addEventListener('click', onClose);
    return () => container.removeEventListener('click', onClose);
  }, [onClose, closeOnOutsideClick]);

  return (
    <StyledDrawer
      placement="right"
      closeIcon={<Close width={16} height={16} />}
      width={708}
      mask={false}
      onClose={onClose}
      {...props}>
      {children}
    </StyledDrawer>
  );
};
