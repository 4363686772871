import { COLORS } from '@yougig/shared/constants';

export const defaultTheme = {
  ff_text: '"Nunito", sans-serif',
  ff_heading: '"Nunito", sans-serif',
  ...Object.entries(COLORS).reduce(
    (acc, [name, value]) => ({ ...acc, [`c_${name.toLowerCase()}`]: value }),
    {},
  ),
};

export const hub71Theme = {
  ff_text: '"Work Sans", sans-serif',
  ff_heading: '"Prompt", sans-serif',
  ...Object.entries(COLORS).reduce(
    (acc, [name, value]) => ({ ...acc, [`c_${name.toLowerCase()}`]: value }),
    {},
  ),
  c_text: '#242627',
  c_deep_pink: '#0026A1',
  c_pink: '#536DFE',
  c_primary: '#0026A1',
  c_persian_blue: '#536DFE',
};

export const THEMES = {
  DEFAULT: 'default',
  HUB71: 'hub71',
};
export const THEMES_CONFIG = {
  [THEMES.DEFAULT]: defaultTheme,
  [THEMES.HUB71]: hub71Theme,
};

export const setupThemeVariables = (theme = THEMES.DEFAULT) => {
  const variables = THEMES_CONFIG[theme];
  if (!variables) {
    console.error(`Theme ${theme} does not exist.`);
    return;
  }
  Object.entries(variables).forEach(([variable, value]) =>
    window.document.documentElement.style.setProperty(`--${variable}`, value),
  );
};
