import { Drawer } from '@yougig/ui/components/elements/Drawer';
import { useDrawerContext } from '@yougig/ui/components/common/DrawerProvider';

import { ApplyDrawerScreeningQuestions } from './components/ApplyDrawerScreeningQuestions';
import { ApplyDrawerSelectConsultant } from './components/ApplyDrawerSelectConsultant';
import { ApplyDrawerNoConsultants } from './components/ApplyDrawerNoConsultants';

export const JobApplyDrawer = () => {
  const { viewMode, closeDrawer } = useDrawerContext();

  return (
    <Drawer
      visible={!!viewMode}
      onClose={closeDrawer}
      width={392}
      className="apply-drawer">
      {viewMode === 'selectConsultant' && <ApplyDrawerSelectConsultant />}
      {viewMode === 'screening' && <ApplyDrawerScreeningQuestions />}
      {viewMode === 'noConsultants' && <ApplyDrawerNoConsultants />}
    </Drawer>
  );
};
