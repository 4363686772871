import { useField } from 'formik';
import { Select } from 'formik-antd';
import { Form } from 'antd';

import { Cross } from '../../icons';

export const SkillsToolsField = ({
  name,
  label,
  placeholder = 'Add Skills / Tools',
  ...props
}) => {
  const [, { error, touched }] = useField(name);

  return (
    <Form.Item
      label={label}
      {...props}
      validateStatus={touched && !!error ? 'error' : undefined}
      help={touched && !!error && <li>{error}</li>}
      className="col-12">
      <Select
        mode="tags"
        name={name}
        placeholder={placeholder}
        tokenSeparators={[',', ';', '\n', '\t']}
        notFoundContent="Start typing to add new skills / tools. Use comma as a separator"
        removeIcon={<Cross width={12} height={12} />}
        getPopupContainer={(node) => node.parentElement}
      />
    </Form.Item>
  );
};
