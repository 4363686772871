import { Button, Typography } from 'antd';
import format from 'date-fns/format';
import styled from 'styled-components';

import { REPORT_STATUS_OPTIONS } from '@yougig/shared/reports/constants';

import { Add, FormBlue, Refresh } from '../../icons';

export const Report = {};

Report.ListTitle = styled.div`
  margin-bottom: 16px;
  color: var(--c_description);
`;

const ReportItem = styled.button`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 16px;
  padding: 16px;
  background: var(--c_magnolia);
  border: 1px solid var(--c_light_violet);
  border-radius: 6px;
  font-size: 12px;
  line-height: 1;
  font-weight: 600;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  outline: none;
  & > svg {
    flex: 0 0 16px;
  }
`;

const ReportDate = styled.span`
  margin-left: 12px;
  margin-right: auto;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
`;

const ReportStatus = styled.div`
  margin-left: 10px;
  font-size: 12px;
  text-align: right;
  color: ${(props) => props.$color};
`;

const ReportItemRow = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
`;

Report.Item = ({ report, secondaryText, ...props }) => {
  return (
    <ReportItem {...props}>
      <ReportItemRow>
        <FormBlue />
        <ReportDate>
          {format(report.startDate, 'LLL dd')} —{' '}
          {format(report.endDate, 'LLL dd')}
        </ReportDate>
        <ReportStatus $color={REPORT_STATUS_OPTIONS.get(report.status).color}>
          {REPORT_STATUS_OPTIONS.getLabel(report.status)}
        </ReportStatus>
      </ReportItemRow>
      {!!secondaryText && (
        <Typography.Text type="secondary">{secondaryText}</Typography.Text>
      )}
    </ReportItem>
  );
};

const ConsultantName = styled.div`
  margin-left: 12px;
  margin-right: auto;
`;

const ReportTime = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: var(--c_hint);
`;

Report.ConsultantItem = ({ report, profile, onRequest, onClick, ...props }) => {
  return (
    <ReportItem onClick={props.disabled ? undefined : onClick} {...props}>
      <ReportItemRow>
        <FormBlue />
        <ConsultantName>{profile.fullName}</ConsultantName>
        {report?.status ? (
          <ReportStatus $color={REPORT_STATUS_OPTIONS.get(report.status).color}>
            {REPORT_STATUS_OPTIONS.getLabel(report.status)}
          </ReportStatus>
        ) : (
          <Button size="small" onClick={onClick}>
            Request
          </Button>
        )}
      </ReportItemRow>
      {!!report?.totalBillableTime && (
        <ReportTime>{report.totalBillableTime}h</ReportTime>
      )}
    </ReportItem>
  );
};

Report.ClientConsultantItem = ({ report, profile, ...props }) => (
  <ReportItem disabled as="div" {...props}>
    <ReportItemRow>
      <FormBlue />
      <ConsultantName>{profile.fullName}</ConsultantName>
      <Typography.Text type="secondary" className="ml-auto">
        {report.totalBillableTime ? `${report.totalBillableTime}h` : ''}
      </Typography.Text>
    </ReportItemRow>
  </ReportItem>
);

Report.List = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  grid-gap: 20px;
  font-size: 14px;
  &:not(:last-child) {
    margin-bottom: 32px;
  }
`;

const ReportCreateBtn = styled(Button)`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  min-width: calc(50% - 10px);
  min-height: 56px;
  border-style: dashed;
  &.ant-btn {
    box-shadow: none;
  }
`;

Report.Create = (props) => {
  return (
    <ReportCreateBtn type="ghost" {...props}>
      <Add />
    </ReportCreateBtn>
  );
};

Report.RefreshIcon = styled(Refresh).attrs({ width: 12, height: 12 })`
  width: 12px;
  height: 12px;
  ${(props) => props.$loading && `animation: spin 1s infinite;`}
`;
