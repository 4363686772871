import { Typography, Button, Form as AntForm } from 'antd';
import { Formik } from 'formik';
import { Form, Input } from 'formik-antd';

import { useDrawerContext } from '@yougig/ui/components/common/DrawerProvider';
import { useAuth } from '@yougig/shared/auth/AuthProvider';
import { object, string } from '@yougig/shared/utils/validation';

const validationSchema = object({
  email: string().email().required(),
});

export const InviteForm = () => {
  /**
   * @typedef {object} DrawerContext
   * @property {import('@yougig/shared/talents/Profile').Profile} profile
   */
  /**
   * @type DrawerContext
   */
  const { profile, closeDrawer } = useDrawerContext();
  const { profile: account } = useAuth();

  return (
    <div className="drawer-section">
      <Typography.Title level={3} className="mb-40">
        Invite consultant
      </Typography.Title>

      <Formik
        initialValues={{ email: profile.email }}
        validationSchema={validationSchema}
        onSubmit={async (
          { email },
          { setSubmitting, setFieldError, setStatus },
        ) => {
          try {
            await profile.invite(email, account.tenant);
          } catch (error) {
            if (error.code === 'email-exists') {
              setFieldError('email', error.message);
            } else {
              setStatus(error.message);
            }
            return;
          }

          closeDrawer();
          setSubmitting(false);
        }}>
        {({ status, isSubmitting, isValid }) => (
          <Form layout="vertical">
            <Form.Item name="email" label="Email">
              <Input name="email" type="email" placeholder="Email" />
            </Form.Item>
            {!!status && (
              <Typography.Text type="danger">{status}</Typography.Text>
            )}
            <AntForm.Item>
              <Button
                htmlType="submit"
                type="primary"
                block
                size="large"
                loading={isSubmitting}
                disabled={!isValid}>
                Send Invite
              </Button>
            </AntForm.Item>
          </Form>
        )}
      </Formik>
    </div>
  );
};
