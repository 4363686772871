export const Skills = (props) => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" {...props}>
    <path d="M11 12L18.5 19.5L16.5 24L11 12Z" fill="#D8E6FC" />
    <path
      d="M17.9751 6.51155C19.6335 8.488 20.0806 10.856 20.147 12.5882C20.1951 13.8437 18.9219 14.5562 17.7601 14.0779L11.9546 11.6874C11.656 11.5644 11.3916 11.3704 11.1828 11.124C9.7558 9.44032 8.4675 8.03255 7.53228 7.04211C6.72201 6.184 6.78124 4.7822 7.84915 4.27975C11.2553 2.67718 15.4414 3.49206 17.9751 6.51155Z"
      fill="#D8E6FC"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5613 12.4919L15.7773 23.8849C15.9074 24.1275 16.1705 24.2681 16.4445 24.2415C16.7185 24.2148 16.9495 24.026 17.0303 23.7629L18.5573 19.5539L22.7663 18.0279C23.0293 17.9467 23.2178 17.7155 23.2445 17.4415C23.2711 17.1675 23.1307 16.9044 22.8883 16.7739L11.5003 11.5579C11.2328 11.4454 10.9237 11.5056 10.7179 11.7103C10.5121 11.915 10.4503 12.2237 10.5613 12.4919Z"
      stroke="#270F56"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.723 13C21.741 12.7533 21.75 12.5033 21.75 12.25C21.75 6.45101 17.049 1.75 11.25 1.75C5.45101 1.75 0.75 6.45101 0.75 12.25C0.75 18.049 5.45101 22.75 11.25 22.75C11.503 22.75 11.75 22.741 12 22.724"
      stroke="#270F56"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M17.0611 10.7472C16.3289 7.91531 13.6613 6.02415 10.7467 6.27056C7.83209 6.51698 5.51991 8.82916 5.27349 11.7438C5.02708 14.6584 6.91824 17.3259 9.75011 18.0582"
      stroke="#270F56"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
