import PropTypes from 'prop-types';

import { Link } from '../../icons';
import { IconValueLink, IconValuesRow } from './IconValue';

export const LinksList = ({ links }) => {
  return (
    <IconValuesRow>
      {links.map(({ url, label }) => {
        let href = url,
          text = label || url;
        if (url.includes('@')) {
          href = `mailto:${url}`;
        } else if (/^[+0-9\- ]+$/.test(url.toString())) {
          href = `tel:${url}`;
        } else if (
          /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gi.test(
            url.toString(),
          )
        ) {
          text = label || new URL(url).host;
        }
        return (
          <IconValueLink key={url} href={href} target="_blank">
            <Link />
            {text}
          </IconValueLink>
        );
      })}
    </IconValuesRow>
  );
};

LinksList.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      label: PropTypes.string,
    }),
  ).isRequired,
};
