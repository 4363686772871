import { Button } from 'antd';

import { useDrawerContext } from '@yougig/ui/components/common/DrawerProvider';
import { useTeamJobCandidatesContext } from '@yougig/shared/teams/TeamJobCandidatesProvider';

export const PartnerApplyButton = () => {
  const { setDrawerView } = useDrawerContext();
  const { notAppliedCandidates } = useTeamJobCandidatesContext();

  const handleApplyClick = () => {
    setDrawerView(
      notAppliedCandidates.length ? 'selectConsultant' : 'noConsultants',
    );
  };

  return (
    <div className="apply-button">
      <Button type="primary" onClick={handleApplyClick}>
        <span>
          Apply for job <sup>beta</sup>
        </span>
      </Button>
    </div>
  );
};
