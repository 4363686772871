export const TimerBlue = (props) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <circle cx={12} cy={13.5} r={9.75} stroke="#353535" strokeWidth={1.5} />
      <path
        d="M18.5 13.5A6.5 6.5 0 1112 7v6.5h6.5z"
        fill="#D8E6FC"
        stroke="#353535"
        strokeWidth={1.5}
      />
      <path fill="#353535" d="M11 1h2v3h-2z" />
      <path
        d="M9.212 1.032C8.953 1.52 9.448 2 10 2h4c.552 0 1.047-.48.788-.968C14.348.2 13.265 0 12 0S9.653.2 9.212 1.032zM18.315 3.703a1 1 0 011.413-.063l.738.675a1 1 0 01.063 1.413l-1.35 1.475-2.214-2.025 1.35-1.475z"
        fill="#353535"
      />
    </svg>
  );
};
