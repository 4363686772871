import { useMemo, useCallback } from 'react';

import { usePaginationData } from '../utils/usePaginationData';
import { useAuth } from '../auth/AuthProvider';

/**
 * @typedef {Object} MessagePayload
 * @prop {string} message
 *  Message text
 * @prop {Array<object>} attachments
 *  List of message's attachments
 *
 * @typedef {Object} MessageMeta
 * @prop {string} authorName - Name of the message's author
 * @prop {Date} dateTime - Created date.
 *
 * @typedef {MessagePayload & MessageMeta} Message
 */

/**
 * Hook to load paginated messages of the given chat and provides a function to send a new message into a chat.
 *
 * @param {import('./Chat').Chat} chat Chat instance
 * @param {import('./constants').SENDER_ROLES} myRole Role of the chat's user.
 * @param {number} perPage messages per page.
 * @returns {{
 *  messages: Array<Message>;
 *  loading: boolean;
 *  error?: string;
 *  loadMore: () => void;
 *  hasMore: boolean;
 *  sendMessage: (values: Partial<Message>) => Promise<void>;
 * }}
 */
export const useChatMessages = (chat, myRole, perPage = 20) => {
  const { userRef, profile } = useAuth();

  const [loadedMessages, { hasMore, loadMore, loading }, error] =
    usePaginationData(chat.getMessagesQuery(), {
      limit: perPage,
      idField: 'id',
    });

  /** @type {Message[]} */
  const messages = useMemo(
    () =>
      loadedMessages.length
        ? loadedMessages.map((m) => ({
            ...m,
            dateTime: m.dateTime.toDate(),
            outcoming: m.senderRole === myRole,
          }))
        : [],
    [loadedMessages, myRole],
  );

  const sendMessage = useCallback(
    /**
     * @param {MessagePayload} values Message values
     */
    async (values) => {
      if (!values.message && !values.attachments?.length) {
        return;
      }

      if (chat.draft) {
        await chat.create();
      }

      await chat.sendMessage({
        ...values,
        author: userRef,
        authorName: profile.firstName,
        senderRole: myRole,
      });
    },
    [chat, userRef, profile, myRole],
  );

  return {
    messages,
    loading,
    error,
    loadMore,
    hasMore,
    sendMessage,
  };
};
