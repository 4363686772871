import services from '../utils/services';
import { CHAT_TYPES } from './constants';
import { Attachments } from '../common/Attachments';

export class Chat {
  constructor(data) {
    Object.entries(data).forEach(([key, value]) => {
      this[key] =
        value instanceof services.get('firestore').Timestamp
          ? value.toDate()
          : value;
    });
  }

  static async create(guestRef, type = CHAT_TYPES.CONSULTANT) {
    const chatRef = await services
      .get('db')
      .collection('chats')
      .add({ guestRef: this.guestRef || guestRef, type: this.type || type });
    const chatSnap = await chatRef.get();

    return Chat.fromSnapshot(chatSnap);
  }

  async create() {
    await this.__ref.set({
      guestRef: this.guestRef,
      type: this.type,
    });

    this.draft = false;

    return this;
  }

  /**
   * Creates new Chat instance from the firestore's DocumentSnapshot.
   * @param {DocumentSnapshot} snapshot Firestore DocumentSnapshot of the candidate.
   */
  static fromSnapshot(snapshot) {
    const chat = new Chat(snapshot.data());
    chat.id = snapshot.id;
    chat.__ref = snapshot.ref;
    return chat;
  }

  static fromId(id, data) {
    const chat = new Chat({ ...data });
    chat.id = id;
    chat.__ref = services.get('db').doc(`chats/${id}`);
    return chat;
  }

  static getChatQuery(type, guestRef) {
    return services
      .get('db')
      .collection('chats')
      .where('guestRef', '==', guestRef)
      .where('type', '==', type);
  }

  getLastMessageQuery() {
    return this.__ref
      .collection('messages')
      .orderBy('dateTime', 'desc')
      .limit(1);
  }

  getMessagesQuery() {
    return this.__ref.collection('messages').orderBy('dateTime', 'desc');
  }

  async sendMessage({ message, attachments, author, authorName, senderRole }) {
    const att = new Attachments(attachments, `messages/${this.id}`);
    await att.uploadNew();

    await this.__ref.collection('messages').add({
      message,
      attachments: att.toFirestore(),
      author,
      authorName,
      dateTime: new Date(),
      senderRole,
    });
  }

  getRef() {
    return this.__ref;
  }
}
