import { Options } from '../utils/Options';

export const ACCOUNT_TYPE = {
  SPECIALIST: 'specialist',
  TEAM: 'team',
};

export const ACCOUNT_TYPE_OPTIONS = new Options(
  {
    value: ACCOUNT_TYPE.SPECIALIST,
    label: 'a Specialist',
  },
  { value: ACCOUNT_TYPE.TEAM, label: 'a Team' },
);

export const CLIENT_BUSINESS_PREFERENCES = {
  TALENT: 'talent',
  SERVICE: 'service',
};

export const CLIENT_BUSINESS_PREFERENCES_OPTIONS = new Options(
  {
    value: CLIENT_BUSINESS_PREFERENCES.TALENT,
    label: 'Talent',
  },
  { value: CLIENT_BUSINESS_PREFERENCES.SERVICE, label: 'Managed Services' },
);
