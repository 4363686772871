import { differenceInDays } from 'date-fns';

import { object, date, string, array, number } from '../utils/validation';

export const reportPeriodSchema = object({
  startDate: date().required(),
  endDate: date()
    .required()
    .test(
      'laterThanStart',
      'End date should be later (or equal) than start date',
      function (value) {
        const { startDate } = this.parent;
        return differenceInDays(value, startDate) >= 0;
      },
    ),
});

export const reportFormSchema = object({
  outcomingFeedback: object().nullable(),
  outcomingMessage: string().nullable(),
  outcomingAttachments: array().nullable(),
  incomingFeedback: object().nullable(),
  incomingMessage: string().nullable(),
  incomingAttachments: array().nullable(),
});

export const timesheetSchema = object({
  timeentries: array().of(
    object({ billableDuration: number().min(0).max(16).required() }),
  ),
});
