import { setLocale, string, ref } from 'yup';
import isEqual from 'date-fns/isEqual';
import isAfter from 'date-fns/isAfter';

setLocale({
  mixed: {
    required: 'Required field.',
  },
});

export const linkedInCompany = () =>
  string().matches(
    /https?:\/\/www.linkedin.com\/company\/[a-zA-z0-9-_]+/g,
    'Please, fill a correct company URL (e.g. https://www.linkedin.com/company/alphabet-inc)',
  );

export const endDateValidation = (endDate, context) => {
  if (!endDate) {
    return true;
  }
  const startDate = context.resolve(ref('fromDate'));
  if (!startDate) {
    return false;
  }

  return isEqual(startDate, endDate) || isAfter(endDate, startDate);
};

export * from 'yup';
