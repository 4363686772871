import { Typography, Button, Row } from 'antd';

import { Company } from '@yougig/ui/icons';
import { useDrawerContext } from '@yougig/ui/components/common/DrawerProvider';

export const ApplyDrawerNoConsultants = () => {
  const { closeDrawer } = useDrawerContext();

  return (
    <Row direction="column" justify="center" gutter={[0, 25]}>
      <Typography.Title level={3}>No available consultants</Typography.Title>
      <Company />
      <Typography.Text>
        To apply for this job you need to add consultants to your team
      </Typography.Text>
      <Button type="ghost" onClick={closeDrawer}>
        OK
      </Button>
    </Row>
  );
};
