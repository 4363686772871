import {
  AVAILABILITY,
  CANDIDATE_TYPE,
} from '@yougig/shared/candidates/constants';
import {
  object,
  string,
  number,
  array,
  boolean,
  date,
  mixed,
  endDateValidation,
} from '@yougig/shared/utils/validation';

const TODAY = new Date();

export const mainSchema = object({
  firstName: string().required(),
  lastName: string(),
  title: string().required(),
  internalRate: number().positive().min(5).nullable(),
  email: string().email(),
  phone: object({
    code: string(),
    number: string().matches(/[0-9- ]+/, {
      excludeEmptyString: true,
      message: 'phone number can contain only digits, hyphens and spaces',
    }),
  }),
  talentLinks: array()
    .nullable()
    .of(object({ url: string().url(), notes: string() })),
  internalLinks: array()
    .nullable()
    .of(object({ url: string().url(), notes: string() })),
  internalAttachments: array().nullable(),
  talentAttachments: array().nullable(),
  attachments: array().nullable(),
});

export const talentLocationLanguagesSchema = object({
  originallyFrom: string(),
  country: string().required(),
  allowedToWorkIn: array().of(string()),
  relocation: boolean(),
  businessTrip: boolean(),
  languages: array()
    .of(
      object({
        language: string().required(),
        level: string().required(),
      }),
    )
    .compact((v) => !v.language && !v.level)
    .min(1)
    .required(),
  introduction: string().nullable(),
});

export const locationLanguagesSchema = object({
  originallyFrom: string(),
  country: string(),
  allowedToWorkIn: array().of(string()),
  relocation: boolean(),
  businessTrip: boolean(),
  languages: array().of(object({ language: string(), level: string() })),
});

export const skillsSchema = object({
  skills: array().of(string()),
  verification: string().nullable().oneOf(Object.values(CANDIDATE_TYPE)),
  manual_scoring: object({
    creative: number().positive().default(0).min(0).max(5).required(),
    technical: number().positive().default(0).min(0).max(5).required(),
    business: number().positive().default(0).min(0).max(5).required(),
  }).nullable(),
});

export const talentSkillsSchema = object({
  skills: array().of(string()).required().min(1),
});

export const talentAvailabilitySchema = object({
  availability: object({
    status: string().oneOf(Object.values(AVAILABILITY)).required(),
    fromDate: mixed().when('status', {
      is: AVAILABILITY.AVAILABLE,
      then: date().required(),
      otherwise: mixed().nullable(),
    }),
    untilDate: mixed().when('status', {
      is: AVAILABILITY.AVAILABLE,
      then: date()
        .nullable()
        .test(
          'fromDate',
          'Until date should be greater than from date.',
          endDateValidation,
        ),
      otherwise: mixed().default(null),
    }),
    availability: mixed().when('status', {
      is: AVAILABILITY.AVAILABLE,
      then: number().positive().max(40).required(),
      otherwise: mixed().default(null),
    }),
    notAvailableUntilDate: mixed().when('status', {
      is: AVAILABILITY.NOT_AVAILABLE,
      then: date().nullable().min(TODAY).default(TODAY),
      otherwise: mixed().default(null),
    }),
  }),
});

export const availabilitySchema = object({
  availability: object({
    status: string().oneOf(Object.values(AVAILABILITY)),
    fromDate: mixed().when('status', {
      is: AVAILABILITY.AVAILABLE,
      then: date().nullable(),
      otherwise: mixed().nullable(),
    }),
    untilDate: mixed().when('status', {
      is: AVAILABILITY.AVAILABLE,
      then: date().nullable(),
      otherwise: mixed().default(null),
    }),
    availability: mixed().when('status', {
      is: AVAILABILITY.AVAILABLE,
      then: number().positive().min(5).nullable().max(40).default(null),
      otherwise: mixed().default(null),
    }),
    notAvailableUntilDate: mixed().when('status', {
      is: AVAILABILITY.NOT_AVAILABLE,
      then: date().nullable(),
      otherwise: mixed().default(null),
    }),
  }),
});

export const consultantProfileSchema = object({
  firstName: string().required(),
  lastName: string().required(),
  title: string().required(),
  email: string().email().nullable(),
  skills: object().nullable(),
});
