export const Checkbox = ({
  checked = false,
  color = 'var(--c_green)',
  ...props
}) => {
  return (
    <svg
      width={12}
      height={12}
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M0 2C0 0.895431 0.895431 0 2 0H10C11.1046 0 12 0.895431 12 2V10C12 11.1046 11.1046 12 10 12H2C0.895431 12 0 11.1046 0 10V2Z"
        fill={checked ? color : '#fff'}
        fillOpacity={0.3}
      />
      <rect
        x="0.5"
        y="0.5"
        width="11"
        height="11"
        rx="1"
        stroke={checked ? color : '#eeefff'}
      />
      {checked ? (
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.75265 4.49049C8.94792 4.29522 8.94792 3.97864 8.75265 3.78338C8.55739 3.58812 8.24081 3.58812 8.04555 3.78338L4.93567 6.89325L3.91166 5.86924C3.7164 5.67398 3.39981 5.67398 3.20455 5.86924C3.00929 6.0645 3.00929 6.38108 3.20455 6.57635L4.57095 7.94274C4.57454 7.94657 4.57821 7.95035 4.58195 7.95409C4.77721 8.14935 5.09379 8.14935 5.28905 7.95409L8.75265 4.49049Z"
          fill={color}
        />
      ) : (
        <rect
          x="5"
          y="5"
          width="2"
          height="2"
          rx="1"
          fill="#FAA60C"
          stroke={color}
        />
      )}
    </svg>
  );
};
