import styled from 'styled-components';
import { Layout } from 'antd';

export const MainLayout = styled(Layout)`
  min-height: 100vh;
`;

export const MainContent = styled(Layout.Content)`
  width: 100vw;
  max-width: 1270px;
  padding-left: 16px;
  padding-right: 16px;
  margin: 40px auto;
  display: flex;
  flex-direction: column;

  @media (max-width: 767px) {
    margin-top: 104px;
  }
`;
