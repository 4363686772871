import { memo } from 'react';
import { Modal as AntModal } from 'antd';
import classNames from 'classnames';

import { Close, Left } from '../../icons';

import styles from '../../styles/components/modal.module.scss';

export const Modal = memo(({ name, className, ...props }) => {
  if (!name) {
    return null;
  }

  const { title, backTo, component: Content, onBack, ...restProps } = props;

  return (
    <AntModal
      visible
      closable
      footer={null}
      centered
      className={classNames(styles.modal, className)}
      closeIcon={<Close />}
      {...restProps}>
      <div className={styles['modal-container']}>
        {title && <h2 className={styles.title}>{title}</h2>}
        {backTo && (
          <a onClick={onBack} className={styles['back-button']}>
            <Left />
          </a>
        )}
        <Content />
      </div>
    </AntModal>
  );
});

Modal.confirm = (options) =>
  AntModal.confirm({
    title: 'Are you sure you want to remove?',
    cancelButtonProps: {
      type: 'ghost',
      danger: true,
    },
    okButtonProps: {
      type: 'ghost',
    },
    centered: true,
    icon: false,
    ...options,
  });
