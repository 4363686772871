import { Options } from '../utils/Options';

export const CHAT_TYPES = {
  CONSULTANT: 'consultant',
  CLIENT: 'client',
};

/**
 * @enum {string}
 */
export const SENDER_ROLES = {
  OWNER: 'owner',
  GUEST: 'guest',
};

export const CHAT_ACTIONS = {
  PUBLISH_CV: 'publish_cv',
  SET_AVAILABILITY: 'set_availability',
};

export const CHAT_ACTIONS_OPTIONS = new Options(
  {
    label: 'Publish your CV',
    description: "The more detailed the better job offers you'll receive",
    icon: 'cv',
    value: CHAT_ACTIONS.PUBLISH_CV,
  },
  {
    label: 'Set Availability',
    description: 'Update your availability regularily',
    icon: 'clock',
    value: CHAT_ACTIONS.SET_AVAILABILITY,
  },
);
