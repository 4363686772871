import { Helmet } from 'react-helmet';
import { Typography } from 'antd';

import { DrawerProvider } from '@yougig/ui/components/common/DrawerProvider';

import { SkillsSection } from './components/SkillsSection';
import { ReportsSection } from './components/ReportsSection';
import { InterviewsSection } from './components/InterviewsSection';

export const Overview = () => {
  return (
    <>
      <Helmet>
        <title>Overview</title>
      </Helmet>
      <div className="jobs">
        <div className="page-heading">
          <Typography.Title>Overview</Typography.Title>
        </div>
      </div>
      <DrawerProvider>
        <InterviewsSection />
        <ReportsSection />
      </DrawerProvider>
      <SkillsSection />
    </>
  );
};
