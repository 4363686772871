import { Options } from '../utils/Options';
import { COLORS } from '../constants';

export const JOB_STATUS = {
  DRAFT: 'draft',
  REQUESTED: 'requested',
  RECRUITING: 'recruiting',
  FINISHED: 'finished',
  WORKING: 'working',
  ARCHIVED: 'archived',
};

export const JOB_STATUS_OPTIONS = new Options(
  {
    value: JOB_STATUS.DRAFT,
    label: 'Draft',
    color: COLORS.DESCRIPTION,
    background: COLORS.LIGHT_GREY,
  },
  {
    value: JOB_STATUS.RECRUITING,
    label: 'Recruiting',
    color: COLORS.ORANGE,
    background: COLORS.LIGHT_ORANGE,
  },
  {
    value: JOB_STATUS.REQUESTED,
    label: 'Requested',
    color: COLORS.PERSIAN_BLUE,
    background: COLORS.LIGHT_BLUE,
  },
  {
    value: JOB_STATUS.ARCHIVED,
    label: 'Archived',
    color: COLORS.TEXT,
    background: COLORS.LIGHT_GREY,
  },
  {
    value: JOB_STATUS.FINISHED,
    label: 'Finished',
    color: COLORS.TEXT,
    background: COLORS.LIGHT_GREY,
  },
  {
    value: JOB_STATUS.WORKING,
    label: 'Working',
    color: COLORS.PRIMARY,
    background: COLORS.LIGHT_GREEN,
  },
);

export const JOB_TYPES = {
  FULL_TIME: 'full_time',
  PART_TIME: 'part_time',
};

export const JOB_TYPES_CONFIG = new Options(
  {
    label: 'Full-Time Job',
    value: JOB_TYPES.FULL_TIME,
    type: 'position',
    icon: 'talent',
  },
  {
    label: 'Part-Time Job',
    value: JOB_TYPES.PART_TIME,
    type: 'position',
    icon: 'cv',
  },
);

export const GROUP_BY = {
  STATUS: 'status',
  PROJECT: 'project',
};

export const GROUP_BY_OPTIONS = new Options(
  {
    label: 'Status',
    value: GROUP_BY.STATUS,
  },
  {
    label: 'Project',
    value: GROUP_BY.PROJECT,
  },
);
