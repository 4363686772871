import { Typography } from 'antd';
import styled from 'styled-components';

import { useInterviews } from '@yougig/shared/interviews/useInterviews';
import { RequestCard } from '@yougig/ui/components/elements/RequestCard';
import { REQUEST_STATUS_OPTIONS } from '@yougig/shared/actions/constants';

const List = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  grid-gap: 20px;
  font-size: 14px;
  &:not(:last-child) {
    margin-bottom: 32px;
  }
`;

export const InterviewsList = () => {
  const { comingInterviews, pastInterviews } = useInterviews();

  return (
    <div className="drawer-section">
      <Typography.Title level={3}>Interviews</Typography.Title>
      <List>
        {[...comingInterviews, ...pastInterviews].map(
          ({ interview, businessName }) => {
            interview.companyName = businessName;

            return (
              <RequestCard
                key={interview.id}
                request={interview}
                statuses={REQUEST_STATUS_OPTIONS}
                canEdit={false}
              />
            );
          },
        )}
      </List>
    </div>
  );
};
