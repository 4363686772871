export const MailLarge = (props) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 114 114"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g clipPath="url(#prefix__clip0)">
        <rect
          x={3.182}
          y={86.012}
          width={4}
          height={13}
          rx={2}
          transform="rotate(-45 3.182 86.012)"
          fill="#19BFA1"
        />
        <rect
          x={12.374}
          y={83.184}
          width={4}
          height={13}
          rx={2}
          transform="rotate(45 12.374 83.184)"
          fill="#19BFA1"
        />
        <rect x={93.5} y={89} width={4} height={13} rx={2} fill="#573D8B" />
        <rect
          x={102}
          y={93.5}
          width={4}
          height={13}
          rx={2}
          transform="rotate(90 102 93.5)"
          fill="#573D8B"
        />
        <rect
          x={97}
          y={15}
          width={4}
          height={13}
          rx={2}
          transform="rotate(90 97 15)"
          fill="#573D8B"
        />
        <rect
          x={104}
          y={22.5}
          width={4}
          height={13}
          rx={2}
          transform="rotate(90 104 22.5)"
          fill="#573D8B"
        />
        <circle cx={61} cy={102} r={4} stroke="#19BFA1" strokeWidth={4} />
        <circle cx={104} cy={58} r={4} stroke="#977DE0" strokeWidth={4} />
        <circle cx={33} cy={13} r={4} stroke="#977DE0" strokeWidth={4} />
        <rect
          x={13}
          y={40}
          width={4}
          height={13}
          rx={2}
          transform="rotate(90 13 40)"
          fill="#19BFA1"
        />
        <rect
          x={20}
          y={47.5}
          width={4}
          height={13}
          rx={2}
          transform="rotate(90 20 47.5)"
          fill="#19BFA1"
        />
        <path d="M32 38.875h50" stroke="#1C1033" strokeWidth={3.75} />
        <mask id="prefix__a" fill="#fff">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M32 37a5 5 0 00-5 5v30a5 5 0 005 5h50a5 5 0 005-5V42a5 5 0 00-5-5L60.345 56.49a5 5 0 01-6.69 0L32 37z"
          />
        </mask>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M32 37a5 5 0 00-5 5v30a5 5 0 005 5h50a5 5 0 005-5V42a5 5 0 00-5-5L60.345 56.49a5 5 0 01-6.69 0L32 37z"
          fill="#DDFFF9"
        />
        <path
          d="M32 37v-3.75h1.439l1.07.963L32 37zm50 0l-2.509-2.787 1.07-.963H82V37zM60.345 56.49l2.508 2.787-2.508-2.787zm-6.69 0l-2.508 2.787 2.508-2.787zM23.25 42A8.75 8.75 0 0132 33.25v7.5c-.69 0-1.25.56-1.25 1.25h-7.5zm0 30V42h7.5v30h-7.5zM32 80.75A8.75 8.75 0 0123.25 72h7.5c0 .69.56 1.25 1.25 1.25v7.5zm50 0H32v-7.5h50v7.5zM90.75 72A8.75 8.75 0 0182 80.75v-7.5c.69 0 1.25-.56 1.25-1.25h7.5zm0-30v30h-7.5V42h7.5zM82 33.25A8.75 8.75 0 0190.75 42h-7.5c0-.69-.56-1.25-1.25-1.25v-7.5zM57.836 53.702l21.655-19.49 5.018 5.575-21.656 19.49-5.017-5.575zm-1.672 0a1.25 1.25 0 001.672 0l5.017 5.575a8.75 8.75 0 01-11.706 0l5.017-5.575zm-21.655-19.49l21.655 19.49-5.017 5.575-21.656-19.49 5.018-5.574z"
          fill="#1C1033"
          mask="url(#prefix__a)"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" d="M0 0h114v114H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
