export const Lock = ({ outlined, ...props }) => (
  <svg
    width={12}
    height={13}
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <g clipPath="url(#prefix__clip0)">
      <path
        d="M10.25 5.75H1.5l8.75 5.75V5.75z"
        fill={outlined ? '#FCD8E6' : '#D8E6FC'}
      />
      <path
        d="M9.5 5.5h-7c-.552 0-1 .53-1 1.182v4.136c0 .653.448 1.182 1 1.182h7c.552 0 1-.53 1-1.182V6.682c0-.653-.448-1.182-1-1.182zM3.5 5.5v-2a2.5 2.5 0 115 0v2"
        stroke={outlined ? '#F90077 ' : '#1C1033'}
        strokeWidth={0.75}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="prefix__clip0">
        <path fill="#fff" transform="translate(0 .5)" d="M0 0h12v12H0z" />
      </clipPath>
    </defs>
  </svg>
);
