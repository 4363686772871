import { Row, Col } from 'antd';
import styled from 'styled-components';

const Experience = styled.div`
  padding: 24px 0;
  &:not(:first-child) {
    border-top: 1px solid #d1d3e5;
  }
`;

const ExperienceHeading = styled(Row)`
  color: var(--c_text);
  font-size: 18px;
`;

const ExperienceSubHeading = styled(Row)`
  color: var(--c_hint);
  font-size: 14px;
`;

const ExperienceSubResponsibility = styled.div`
  color: var(--c_text);
  margin-top: 16px;
  white-space: pre-wrap;
`;

export const ExperienceInfo = ({
  organization,
  position,
  start_year,
  end_year,
  responsibility,
}) => {
  const total_years = (end_year || new Date().getFullYear()) - start_year;

  return (
    <Experience className="talent-experience">
      <ExperienceHeading justify="space-between">
        <Col>{organization}</Col>
        <Col>{total_years < 1 ? '< 1 yr' : `${total_years} yrs`}</Col>
      </ExperienceHeading>
      <ExperienceSubHeading justify="space-between">
        <Col>{position}</Col>
        <Col>
          {start_year} - {end_year || 'Present'}
        </Col>
      </ExperienceSubHeading>
      <ExperienceSubResponsibility
        dangerouslySetInnerHTML={{ __html: responsibility }}
      />
    </Experience>
  );
};
