import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

import { Arrow, Spinner } from '../../icons';
import { Table } from './Table';
import { baseColumns } from '../talents/baseColumns';

const SpinnerAnimated = styled(Spinner)`
  animation: spin 1s infinite;
  width: 16px;
  height: 16px;
`;

const StyledExpandableTable = styled(Table)`
  .ant-table-content {
    padding: 0 16px 16px;
    table {
      box-shadow: none;
      border: 0;
      position: relative;

      .ant-table-placeholder {
        .ant-table-cell {
          border: 3px solid var(--c_light_grey);
          border-radius: 10px;
        }

        .ant-table-thead + .ant-table-tbody {
          border-top: 0;
        }
      }

      .ant-table-row-indent {
        padding: 0 !important;
      }

      .ant-table-thead {
        th {
          border-top: 3px solid var(--c_light_grey);
          &:first-child.cell-heading + th,
          &:first-child {
            border-left: 3px solid var(--c_light_grey);
          }
          &:last-child {
            border-right: 3px solid var(--c_light_grey);
          }
        }
      }

      .ant-table-row {
        & > td {
          &:first-child {
            border-left: 3px solid var(--c_light_grey);
          }
          &:last-child {
            border-right: 3px solid var(--c_light_grey);
          }
        }

        &:last-child {
          > td {
            border-bottom-width: 3px;
          }
        }
      }

      .ant-table-row-level-0 {
        td {
          background-color: var(--c_ghost_white);
          padding-top: 16px;
          padding-bottom: 16px;
        }

        &:first-child {
          td {
            border-top: 3px solid var(--c_light_grey);
            &:first-child.cell-heading + th,
            &:first-child {
              border-left: 3px solid var(--c_light_grey);
              border-top-left-radius: 10px;
            }
            &:last-child {
              border-right: 3px solid var(--c_light_grey);
              border-top-right-radius: 10px;
            }
          }
        }
      }

      th.cell-candidate {
        padding-left: 40px;
      }

      @media (max-width: 767px) {
        overflow: hidden;
      }

      @media (min-width: 768px) {
        border-top-width: 0;

        .ant-table-thead {
          position: sticky;
          top: 0;
          z-index: 20;

          &:after,
          &:before {
            position: absolute;
            content: '';
            left: 0;
            top: 0;
            right: 0;
            height: 10px;
            background-color: #fff;
          }

          &:after {
            z-index: -1;
            border: 0 solid var(--c_light_grey);
            border-width: 0 3px;
            border-radius: 10px 10px 0 0;
          }

          &:before {
            z-index: -2;
          }

          th {
            border: 0 solid var(--c_light_grey);
            border-top-width: 3px;
          }
        }

        .ant-table-row-level-0 {
          position: sticky;
          z-index: 21;
          top: 21px;
          &:first-child {
            top: 18px;
          }
        }

        .ant-table-tbody tr:last-child:not(.ant-table-row-level-0) {
          position: relative;

          > td:first-child {
            position: static;

            &:after,
            &:before {
              position: absolute;
              content: '';
              left: 0;
              height: 9px;
              right: 0;
              bottom: 0;
              background-color: #fff;
              z-index: 24;
              transition: background 0.3s;
            }

            &:after {
              border: 0 solid var(--c_light_grey);
              border-width: 0 3px 3px;
              border-radius: 0 0 10px 10px;
              z-index: 25;
            }
          }

          &:not(.ant-table-placeholder):hover {
            > td:first-child {
              &:after {
                background-color: #fafafa;
              }
            }
          }
          &.ant-table-row--selected {
            > td:first-child {
              &:after,
              &:before {
                border-color: var(--c_primary);
              }
            }
          }
        }

        .ant-table-tbody tr.ant-table-row-level-0:last-child {
          > td:first-child {
            position: static;

            &:after,
            &:before {
              position: absolute;
              content: '';
              left: 0;
              height: 9px;
              right: 0;
              top: 55px;
              background-color: #fff;
              z-index: 21;
            }

            &:after {
              border: 0 solid var(--c_light_grey);
              border-width: 0 3px 3px;
              border-radius: 0 0 10px 10px;
              background-color: var(--c_ghost_white);
              z-index: 22;
            }
          }
        }
      }
    }
  }
`;

export const ExpandableTableHeader = styled(StyledExpandableTable)`
  .ant-table-tbody {
    display: none;
  }
  .ant-table-container table > thead > tr th {
    background-color: transparent;
    border-color: #fff !important;
  }
  .ant-table-content {
    padding-bottom: 0;
    table th.cell-candidate {
      padding-left: 40px;
    }
  }
`;

export const ExpandableTable = (props) => {
  useEffect(() => {
    const ua = navigator.userAgent.toLowerCase();
    if (ua.indexOf('safari') !== -1 && !(ua.indexOf('chrome') > -1)) {
      document.body.classList.add('safari');
    }
  }, []);

  const columns = useMemo(
    () => [baseColumns.groupHeading(props.columns.length), ...props.columns],
    [props.columns],
  );

  return (
    <StyledExpandableTable
      pagination={false}
      expandable={{
        expandRowByClick: true,
        defaultExpandedRowKeys: props.defaultExpandedRowKeys,
        expandIcon: ({ expanded, onExpand, record }) => {
          if (record.loading) {
            return <SpinnerAnimated className="icon-expanded" />;
          }

          return (
            record.children && (
              <Arrow
                className={classNames('icon-expanded', {
                  active: expanded,
                  hidden: !record.groupLength,
                })}
                onClick={(e) => onExpand(e, record)}
              />
            )
          );
        },
      }}
      {...props}
      columns={columns}
    />
  );
};
