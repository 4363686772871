import { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useInfiniteQuery } from 'react-query';
import { Spin, Typography } from 'antd';
import InfiniteScroll from 'react-infinite-scroller';

import { useApi } from '@yougig/shared/api/Api';
import { InputSearch } from '@yougig/ui/components/elements/InputSearch';
import { useAuth } from '@yougig/shared/auth/AuthProvider';
import { useProfileJobs } from '@yougig/shared/jobs/useProfileJobs';
import { Job } from '@yougig/shared/jobs/Job';

import { JobCard } from '../../components/jobs/JobCard';

export const AssignmentsList = () => {
  const { isCandidate, profile: account } = useAuth();
  const appliedJobs = useProfileJobs(account?.profile);
  const { paginatedQueryFn } = useApi();
  const [keywords, setKeywords] = useState('');

  const queryParams = useMemo(
    () => ({
      keywords: keywords,
      job_type: 'position',
      expired: false,
      public: true,
      remote: true,
    }),
    [keywords],
  );

  const { data, fetchNextPage, hasNextPage, isLoading } = useInfiniteQuery(
    ['assignments', queryParams],
    paginatedQueryFn,
    {
      getNextPageParam: (lastPage, allPages) =>
        lastPage.next ? allPages.length : undefined,
    },
  );

  const items = useMemo(
    () =>
      data?.pages
        .flatMap((i) => i.result)
        .map((o) => Job.fromApi(o))
        .filter((o) => !appliedJobs?.find(({ job }) => job.key === o.key)) ||
      [],
    [data, appliedJobs],
  );

  return (
    <>
      <Helmet>
        <title>Jobs</title>
      </Helmet>
      <div className="jobs">
        <div className="heading">
          The most interesting projects from all over the world{' '}
          <span>are waiting for you</span>
        </div>
        {isLoading && <Spin />}
        {!!appliedJobs?.length && isCandidate && (
          <>
            <Typography.Title level={3}>Applied</Typography.Title>
            <div className="row mb-32">
              {appliedJobs.map(({ job }) => (
                <div className="col-4" key={job.key}>
                  <JobCard job={job} />
                </div>
              ))}
            </div>
          </>
        )}
        <div className="heading-search">
          <Typography.Title level={3}>Jobs</Typography.Title>
          <InputSearch onSearch={setKeywords} />
        </div>
        <InfiniteScroll
          initialLoad={false}
          threshold={1000}
          loadMore={() => fetchNextPage()}
          hasMore={hasNextPage}>
          <div className="row">
            {items.map((item) => (
              <div className="col-4" key={item.id}>
                <JobCard job={item} />
              </div>
            ))}
          </div>
        </InfiniteScroll>
      </div>
    </>
  );
};
