import { COLORS } from '../constants';
import { Options } from '../utils/Options';

/**
 * @enum
 */
export const REPORT_TYPE = {
  CLIENT: 'client',
  TALENT: 'talent',
};

/**
 * @enum
 */
export const REPORT_STATUS = {
  // ALL
  PENDING: 'pending',
  APPROVED: 'approved',
  // CLIENT
  DRAFT: 'draft',
  AUTO_APPROVED: 'auto-approved',
  // TALENT
  REQUESTED: 'requested',
};

export const REPORT_STATUS_OPTIONS = new Options(
  {
    value: REPORT_STATUS.REQUESTED,
    label: 'Requested',
    color: COLORS.PRIMARY,
    background: COLORS.LIGHT_BLUE,
    active: true,
    group: [REPORT_TYPE.TALENT],
  },
  {
    value: REPORT_STATUS.DRAFT,
    label: 'Draft',
    color: COLORS.DESCRIPTION,
    background: COLORS.LIGHT_GREY,
    active: true,
    group: [REPORT_TYPE.CLIENT],
  },
  {
    value: REPORT_STATUS.PENDING,
    label: 'Pending Approval',
    color: COLORS.ORANGE,
    background: COLORS.LIGHT_ORANGE,
    active: true,
    group: [REPORT_TYPE.CLIENT, REPORT_TYPE.TALENT],
  },
  {
    value: REPORT_STATUS.APPROVED,
    label: 'Approved',
    color: COLORS.GREEN,
    background: COLORS.LIGHT_GREEN,
    active: false,
    group: [REPORT_TYPE.CLIENT, REPORT_TYPE.TALENT],
  },
  {
    value: REPORT_STATUS.AUTO_APPROVED,
    label: 'Auto-approved',
    color: COLORS.GREEN,
    background: COLORS.LIGHT_GREEN,
    active: false,
    group: [REPORT_TYPE.CLIENT],
  },
);

export const REPORT_FEEDBACK_VALUE = {
  OK: 'ok',
  CONSERN: 'consern',
  ALERT: 'alert',
};

export const REPORT_FEEDBACK_VALUE_OPTIONS = new Options(
  {
    value: REPORT_FEEDBACK_VALUE.OK,
    color: COLORS.GREEN,
  },
  {
    value: REPORT_FEEDBACK_VALUE.CONSERN,
    color: COLORS.ORANGE,
  },
  {
    value: REPORT_FEEDBACK_VALUE.ALERT,
    color: COLORS.RED,
  },
);

export const REPORT_OUTCOMING_FEEDBACK_ITEMS = [
  { name: 'scope', label: 'Scope & requirements' },
  { name: 'payments', label: 'Payments' },
  { name: 'communication', label: 'Communication & support' },
  { name: 'health', label: 'Health' },
  { name: 'pressure', label: 'Work pressure' },
  { name: 'status', label: 'OVERALL STATUS', icons: true },
];

export const REPORT_INCOMING_FEEDBACK_ITEMS = [
  { name: 'speed', label: 'Speed' },
  { name: 'quality', label: 'Quality' },
  { name: 'communication', label: 'Communication & behavior' },
  { name: 'status', label: 'OVERALL STATUS', icons: true },
];
