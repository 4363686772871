import { Options } from './utils/Options';

export const COLORS = {
  TEXT: '#1C1033',
  DESCRIPTION: '#6A738A',
  HINT: '#96A0B8',
  LIGHT_GREY: '#EEEFFF',
  GHOST_WHITE: '#F6F7FB',
  DARK_VIOLET: '#573D8B',
  PRIMARY: '#5153DD',
  PERSIAN_BLUE: '#8385EC',
  VIOLET: '#977DE0',
  LIGHT_VIOLET: '#D6C1FF',
  DEEP_PINK: '#F90077',
  RED: '#FF5170',
  LIGHT_RED: '#FCD8E6',
  ORANGE: '#FAA60C',
  LIGHT_ORANGE: '#FAF2D8',
  LIGHT_BLUE: '#D8E6FC',
  GREEN: '#19BFA1',
  LIGHT_GREEN: '#DDFFF9',
  JET_STREAM: '#E5FAFF',
  SALMON: '#FF8DA2',
  PINK: '#FF409B',
  MAGNOLIA: '#F8F4FF',

  // added
  WHITE: '#FFFFFF',
  LINKEDIN: '#0075BC',
  LINKEDIN_ACTIVE: '#005d96',
};

export const DISCOUNT = 0.1;

export const SENIORITY_LEVEL = [
  'Any',
  'Basic',
  'Junior',
  'Middle',
  'Senior',
  'Expert',
];

export const REGIONS_OPTIONS = new Options(
  {
    label: 'North America',
    value: 'North America',
  },
  {
    label: 'West Europe',
    value: 'West Europe',
  },
  {
    label: 'East Europe',
    value: 'East Europe',
  },
  {
    label: 'Middle East',
    value: 'Middle East',
  },
  {
    label: 'Latin America',
    value: 'Latin America',
  },
  {
    label: 'Africa',
    value: 'Africa',
  },
  {
    label: 'Asia & Pacific',
    value: 'Asia & Pacific',
  },
  {
    label: 'Australia/NZ',
    value: 'Australia/NZ',
  },
);

export const WORK_FORMAT = {
  REMOTE: 'remote',
  ON_SITE: 'onsite',
  MIXED: 'mixed',
};

export const WORK_FORMAT_OPTIONS = new Options(
  {
    label: 'Remote',
    value: WORK_FORMAT.REMOTE,
  },
  {
    label: 'Onsite',
    value: WORK_FORMAT.ON_SITE,
  },
  {
    label: 'Mixed',
    value: WORK_FORMAT.MIXED,
  },
);
