import * as React from 'react';

export const File = (props) => (
  <svg width={12} height={12} viewBox="0 0 12 12" fill="none" {...props}>
    <path
      d="M6.5625 0.5H2.625C2.32663 0.5 2.04048 0.615892 1.8295 0.822182C1.61853 1.02847 1.5 1.30826 1.5 1.6V10.4C1.5 10.6917 1.61853 10.9715 1.8295 11.1778C2.04048 11.3841 2.32663 11.5 2.625 11.5H9.375C9.67337 11.5 9.95952 11.3841 10.1705 11.1778C10.3815 10.9715 10.5 10.6917 10.5 10.4V4.35L6.5625 0.5Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.5 0.5V4.5H10.5"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
