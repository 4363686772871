import { useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import { useAuth } from '@yougig/shared/auth/AuthProvider';
import { useQueryParams } from '@yougig/ui/utils/useQueryParams';

import { MainLayout } from '../components/layout/MainLayout';
import { TalentProvider } from '../components/common/TalentProvider';
import { AssignmentsList } from './jobs/JobList';
import { Registration } from './auth/Registration';
import { Login } from './auth/Login';
import { JobDetails } from './jobs/JobDetails';
import { Profiles } from './profiles/Profiles';
import { Overview } from './overview/Overview';
import { auth } from '../utils/firebase';

const Providers = ({ children }) => {
  const { isCandidate } = useAuth();

  if (isCandidate) {
    return <TalentProvider>{children}</TalentProvider>;
  }

  return children;
};

export const Routing = () => {
  const { initializing, isAuthenticated, isPartner, isCandidate } = useAuth();
  const queryParams = useQueryParams();

  useEffect(() => {
    const token = queryParams.get('token');
    if (token) {
      auth.signInWithCustomToken(token);
    }
  }, [queryParams]);

  if (initializing) {
    return null;
  }

  const homeRedirect = (
    <Redirect
      to={isCandidate ? '/overview' : isPartner ? 'profiles' : '/jobs'}
    />
  );

  return (
    <MainLayout>
      <Providers>
        <Switch>
          <Route path="/overview">
            {isCandidate ? <Overview /> : <Redirect to="/" />}
          </Route>
          <Route path="/profiles">
            {isPartner ? <Profiles /> : <Redirect to="/" />}
          </Route>
          <Route path="/jobs/:jobId">
            <JobDetails />
          </Route>
          <Route path="/jobs">
            <AssignmentsList />
          </Route>
          <Route
            path="/registration"
            render={() => (isAuthenticated ? homeRedirect : <Registration />)}
          />
          <Route
            path="/login"
            render={() => (isAuthenticated ? homeRedirect : <Login />)}
          />
          <Route path="*">{homeRedirect}</Route>
        </Switch>
      </Providers>
    </MainLayout>
  );
};
