import { Select } from 'formik-antd';

import { ArrowBottom } from '../../icons';

export const LANGUAGE_LEVEL = [
  { label: 'B1', value: 'B1' },
  { label: 'B2', value: 'B2' },
  { label: 'C1', value: 'C1' },
  { label: 'C2', value: 'C2' },
  { label: 'Native', value: 'native' },
];

export const LanguageLevelField = ({ name }) => {
  return (
    <Select
      name={name}
      options={LANGUAGE_LEVEL}
      suffixIcon={<ArrowBottom width={16} height={16} />}
      placeholder="Select"
      getPopupContainer={(node) => node.parentElement}
    />
  );
};
