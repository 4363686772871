import { Form, Input } from 'formik-antd';
import { Form as AntForm } from 'antd';

import { LinksField } from '@yougig/ui/components/form/LinksField';
import { AttachmentsField } from '@yougig/ui/components/form/AttachmentsField';

export const InternalInfoFields = () => (
  <div className="row">
    <Form.Item name="internalRate" label="Internal Rate" className="col-6">
      <Input name="internalRate" placeholder="XX" prefix="$" />
    </Form.Item>
    <AntForm.Item label="Links" className="col-12 links--field">
      <LinksField name="talentLinks" />
    </AntForm.Item>
    <AttachmentsField
      name="talentAttachments"
      className="col-12"
      label="Attachments"
    />
  </div>
);
