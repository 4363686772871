import * as React from 'react';

export const Spinner = (props) => (
  <svg width={24} height={24} viewBox="0 0 24 24" fill="none" {...props}>
    <path
      d="M20 12C20 13.1046 20.8954 14 22 14C23.1046 14 24 13.1046 24 12H20ZM12 24C13.1046 24 14 23.1046 14 22C14 20.8954 13.1046 20 12 20V24ZM12 20C7.58172 20 4 16.4183 4 12H0C0 18.6274 5.37258 24 12 24V20ZM4 12C4 7.58172 7.58172 4 12 4V0C5.37258 0 0 5.37258 0 12H4ZM12 4C16.4183 4 20 7.58172 20 12H24C24 5.37258 18.6274 0 12 0V4Z"
      fill="currentColor"
    />
  </svg>
);
