export const Owner = ({ width, height, ...props }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M5.75 15c0-2.9 2.35-5.25 5.25-5.25h2c2.9 0 5.25 2.35 5.25 5.25v3.25H5.75V15z"
        fill="#D8E6FC"
        stroke="#1C1033"
        strokeWidth={1.5}
      />
      <path
        d="M13 14.213l-.021.055c.003.01-.001.016-.006.016-.009 0-.02-.013-.006-.035-.035-.042-.147-.122-.466-.25-.963-.384-.198-.842 0-1 .197-.157.178.702.5 1.214z"
        fill="#1C1033"
      />
      <path
        d="M11.5 18l1.5-3.787m0 0c-.32-.512-.302-1.371-.5-1.213-.197.157-.962.615 0 1 .77.307.34.342.5.213z"
        stroke="#1C1033"
      />
      <path
        d="M.75 20.8A2.25 2.25 0 013 18.55h18a2.25 2.25 0 012.25 2.25V23a.25.25 0 01-.25.25H1A.25.25 0 01.75 23v-2.2z"
        fill="#fff"
        stroke="#1C1033"
        strokeWidth={1.5}
      />
      <circle cx={12} cy={5} r={4.25} stroke="#1C1033" strokeWidth={1.5} />
    </svg>
  );
};
