import { memo } from 'react';
import { Button, Typography } from 'antd';
import { useField, FieldArray } from 'formik';
import { Form, Input } from 'formik-antd';

import { COLORS } from '@yougig/shared/constants';

import { LanguageLevelField } from './LanguageLevelField';
import { Cross } from '../../icons';

export const LanguagesField = memo(({ name }) => {
  const [{ value }, { error }] = useField(name);

  return (
    <FieldArray
      name={name}
      render={(helpers) => (
        <>
          {Array.isArray(value) &&
            value.map((_, index) => (
              <div className="row" key={index}>
                <Form.Item
                  name={`${name}[${index}]language`}
                  label="Language"
                  className="col-3">
                  <Input
                    name={`${name}[${index}]language`}
                    placeholder="Language"
                  />
                </Form.Item>
                <Form.Item
                  name={`${name}[${index}]level`}
                  label={
                    <>
                      Level
                      {!!index && (
                        <Cross
                          color={COLORS.SALMON}
                          onClick={() => helpers.remove(index)}
                        />
                      )}
                    </>
                  }
                  className="deletable-input col-3">
                  <LanguageLevelField name={`${name}[${index}]level`} />
                </Form.Item>
              </div>
            ))}
          {!Array.isArray(error) && (
            <Typography.Text type="danger">{error}</Typography.Text>
          )}
          <Button
            type="link"
            onClick={() => helpers.push({ language: '', level: '' })}>
            + Add Language
          </Button>
        </>
      )}
    />
  );
});
