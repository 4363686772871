import { useState } from 'react';
import styled from 'styled-components';
import { Dropdown, Menu } from 'antd';

import { CV, Logout } from '../../icons';
import { useWindowWidth } from '../../utils/useWindowWidth';

const DropdownMenu = styled(Menu)`
  &.ant-menu-vertical {
    min-width: 280px;

    & > .ant-menu-item {
      font-size: 18px;
      line-height: 25px;
      padding: 16px 32px;
      margin: 0;
      color: var(--c_text);
      font-weight: 600;
      height: auto;
      display: flex;
      align-items: center;

      span {
        line-height: 25px;
      }

      &:hover {
        background-color: var(--c_light_blue);
      }
    }
  }
`;

const UserButton = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
  color: var(--c_primary);
  cursor: pointer;
  line-height: 36px;

  &:hover {
    color: var(--c_persian_blue);
  }

  svg {
    margin-bottom: 2px;
    margin-right: 10px;
  }

  @media (max-width: 767px) {
    margin-left: 0;
  }
`;

const LogoutButton = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  line-height: 36px;
  margin-top: 20px;

  svg {
    margin-right: 10px;
  }
`;

export const UserMenuDropdown = ({ children, user, logout }) => {
  const [visibleDropdown, setDropdownVisibility] = useState(false);

  const width = useWindowWidth();

  if (width < 768) {
    return (
      <>
        <UserButton>
          <span>{user.displayName}</span>
        </UserButton>
        <LogoutButton
          onClick={() => {
            setDropdownVisibility(false);
            logout();
          }}>
          <Logout />
          Log Out
        </LogoutButton>
        {children}
      </>
    );
  }

  return (
    <>
      <Dropdown
        overlay={
          <div className="ant-dropdown-menu">
            <DropdownMenu>
              <Menu.Item
                key="logout"
                onClick={() => {
                  setDropdownVisibility(false);
                  logout();
                }}
                icon={<Logout />}>
                Log Out
              </Menu.Item>
            </DropdownMenu>
            {children}
          </div>
        }
        trigger={['click']}
        visible={visibleDropdown}
        onVisibleChange={setDropdownVisibility}>
        <UserButton>
          <CV variant="blue" color="#536DFE" height={24} width={24} />
          <span>{user.displayName}</span>
        </UserButton>
      </Dropdown>
    </>
  );
};
