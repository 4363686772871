import { Typography } from 'antd';
import styled from 'styled-components';

import { MessageForm } from './MessageForm';
import { MessageList } from './MessageList';

const ChatContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const ChatTitle = styled.div`
  &.drawer-section {
    padding-bottom: 16px;
  }
  .ant-typography {
    margin: 0;
  }
`;

const ChatBody = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  min-height: 300px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  &.drawer-section {
    padding-top: 0;
  }
`;

const ChatFooter = styled.div`
  margin-top: auto;
  border-top: 1px solid var(--c_light_grey);
`;

export const Chat = ({ title, sendMessage, ...props }) => {
  return (
    <ChatContainer>
      {!!title && (
        <ChatTitle className="drawer-section">
          <Typography.Title level={3}>{title}</Typography.Title>
        </ChatTitle>
      )}
      <ChatBody className="drawer-section">
        <MessageList {...props} />
      </ChatBody>
      <ChatFooter className="drawer-section">
        <MessageForm onSendMessage={sendMessage} />
      </ChatFooter>
    </ChatContainer>
  );
};
