import ReactSlick from 'react-slick';
import styled from 'styled-components';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { REQUEST_STATUS_OPTIONS } from '@yougig/shared/actions/constants';

import { RequestCard } from './RequestCard';

const settings = {
  infinite: false,
  dots: false,
  arrows: true,
  slidesToShow: 2,
  slidesToScroll: 2,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

export const Slider = styled(ReactSlick).attrs(settings)`
  .slick-arrow {
    transition: all 0.2s;
    width: 48px;
    height: 100%;
    top: 0;
    bottom: 0;
    transform: none;
    z-index: 1;
    color: var(--c_primary);
    @media (max-width: 767px) {
      width: 16px;
    }
    &:before {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      border-top: 1px solid currentColor;
      opacity: 1;
      color: currentColor;
      top: 50%;
    }
    &.slick-disabled {
      opacity: 0;
      pointer-events: none;
    }
    &.slick-prev {
      left: 0;
      background: linear-gradient(270deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
      &:before {
        border-left: 1px solid currentColor;
        left: 50%;
        transform-origin: top left;
        transform: rotate(-45deg);
      }
    }
    &.slick-next {
      right: 0;
      background: linear-gradient(270deg, #fff 0%, rgba(255, 255, 255, 0) 100%);
      &:before {
        border-right: 1px solid currentColor;
        right: 50%;
        transform-origin: top right;
        transform: rotate(45deg);
      }
    }
  }
  .slick-track {
    display: flex;
    gap: 16px;
    &:before,
    &:after {
      display: none;
    }
  }
  .slick-slide {
    height: inherit;
    > div {
      height: 100%;
    }
  }
`;

export const RequestsSlider = ({ requests, canEdit, onEdit }) => {
  return (
    <Slider>
      {requests.map((request) => (
        <RequestCard
          key={request.id}
          request={request}
          canEdit={canEdit(request)}
          onEdit={onEdit}
          statuses={REQUEST_STATUS_OPTIONS}
        />
      ))}
    </Slider>
  );
};
