import { useState } from 'react';

import { useAuth } from '@yougig/shared/auth/AuthProvider';

import { Modal } from '../elements/Modal';
import { UserMenuDropdown } from './UserMenu';
import { LogoutModalBody } from './LogoutModalBody';

export const UserMenuDropdownWithModal = ({ user, onConfirm }) => {
  const { logout } = useAuth();
  const [visibleModal, setModalVisibility] = useState(false);

  return (
    <>
      <UserMenuDropdown user={user} logout={() => setModalVisibility(true)} />
      <Modal
        name="logout"
        title="Log out"
        visible={visibleModal}
        onCancel={() => setModalVisibility(false)}
        component={() => (
          <>
            <LogoutModalBody
              onConfirm={() => {
                setModalVisibility(false);
                onConfirm();
                logout();
              }}
              onCancel={() => setModalVisibility(false)}
            />
          </>
        )}
      />
    </>
  );
};
