import { Options } from '../utils/Options';
import { COLORS } from '../constants';

/**
 * @readonly
 * @enum {string}
 */
export const REQUEST_TYPES = {
  INTERVIEW: 'interview',
  INFO: 'info',
};

export const REQUEST_TYPES_OPTIONS = new Options(
  {
    value: REQUEST_TYPES.INTERVIEW,
    label: 'Interview',
  },
  {
    value: REQUEST_TYPES.INFO,
    label: 'Info request',
  },
);

export const REQUEST_STATUS = {
  DRAFT: 'draft',
  SUBMITTED: 'submitted',
  REJECTED: 'rejected',
  RETURNED: 'returned',
  CONFIRMED: 'confirmed',
  CLOSED: 'closed',
};

export const REQUEST_STATUS_OPTIONS = new Options(
  {
    value: REQUEST_STATUS.DRAFT,
    label: 'Draft',
    color: COLORS.PERSIAN_BLUE,
    background: COLORS.LIGHT_BLUE,
    active: true,
  },
  {
    value: REQUEST_STATUS.SUBMITTED,
    label: 'Submitted',
    color: COLORS.GREEN,
    background: COLORS.LIGHT_GREEN,
    active: true,
  },
  {
    value: REQUEST_STATUS.REJECTED,
    label: 'Rejected',
    color: COLORS.DESCRIPTION,
    background: COLORS.LIGHT_GREY,
    active: false,
  },
  {
    value: REQUEST_STATUS.RETURNED,
    label: 'Returned',
    color: COLORS.ORANGE,
    background: COLORS.LIGHT_ORANGE,
    active: true,
  },
  {
    value: REQUEST_STATUS.CONFIRMED,
    label: 'Confirmed',
    color: COLORS.GREEN,
    background: COLORS.LIGHT_GREEN,
    active: true,
  },
  {
    value: REQUEST_STATUS.CLOSED,
    label: 'Closed',
    color: COLORS.HINT,
    background: COLORS.LIGHT_GREY,
    active: false,
  },
);

export const REQUEST_AUTHOR = {
  MANAGER: 'manager',
  CLIENT: 'client',
};

export const REQUEST_AUTHOR_OPTIONS = new Options(
  {
    value: REQUEST_AUTHOR.CLIENT,
    label: 'Client',
  },
  {
    value: REQUEST_AUTHOR.MANAGER,
    label: 'Manager',
  },
);
