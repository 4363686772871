import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';

import { useAuth } from '@yougig/shared/auth/AuthProvider';
import { UserMenuDropdownWithModal } from '@yougig/ui/components/common/UserMenuWithModal';
import { HeaderLayout } from '@yougig/ui/components/layout/Header';
import { ChatButton } from '@yougig/ui/components/chat/ChatButton';
import { CHAT_TYPES } from '@yougig/shared/chat/constants';

import { Menu } from './Menu';

const Logo = styled.img`
  height: 44px;
`;

const anonLinks = [{ title: 'Jobs', link: '/jobs' }];

const consultantLinks = [
  { title: 'Overview', link: '/overview' },
  { title: 'Jobs', link: '/jobs' },
];

const partnerLinks = [
  { title: 'Jobs', link: '/jobs' },
  { title: 'Profiles', link: '/profiles' },
];

export const Header = () => {
  const { user, isPartner, isCandidate, profile, isAuthenticated } = useAuth();
  const history = useHistory();
  const location = useLocation();
  const headerRef = useRef();

  useEffect(() => headerRef.current.closeMobileMenu(), [location]);

  return (
    <HeaderLayout
      ref={headerRef}
      logo={
        <Link to="/">
          <Logo src="/images/logo.svg" alt="Yougig Logo" />
        </Link>
      }
      userMenu={
        <UserMenuDropdownWithModal
          user={user}
          onConfirm={() => history.push('/')}
        />
      }
      authButtons={
        <>
          <Link to="/registration" className="ant-btn ant-btn-ghost">
            Sign Up
          </Link>
          <Link to="/login" className="ant-btn ant-btn-primary">
            Login
          </Link>
        </>
      }>
      <Menu
        items={
          (isPartner && partnerLinks) ||
          (isCandidate && consultantLinks) ||
          anonLinks
        }
      />
      {!isPartner && isAuthenticated && (
        <ChatButton
          guestRef={profile.profile}
          chatType={CHAT_TYPES.CONSULTANT}
        />
      )}
    </HeaderLayout>
  );
};
