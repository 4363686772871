export const MoneyBlue = (props) => {
  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        d="M22.751.4l-9.675 12.2v2L12 16.1h10.338l1.24-1.24V1.64L22.751.4z"
        fill="#D8E6FC"
      />
      <ellipse
        cx={6.75}
        cy={14.25}
        rx={6}
        ry={3}
        stroke="#353535"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M.75 14.25v6c0 1.657 2.686 3 6 3s6-1.343 6-3v-6"
        stroke="#353535"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.75 17.25c0 1.657-2.686 3-6 3s-6-1.343-6-3M15.75 15.75h6a1.5 1.5 0 001.5-1.5v-12a1.5 1.5 0 00-1.5-1.5H2.25a1.5 1.5 0 00-1.5 1.5v7.507M9 8.25a3 3 0 114.979 2.255"
        stroke="#353535"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.5 4.125a.375.375 0 110 .75.375.375 0 010-.75M19.5 11.625a.375.375 0 110 .75.375.375 0 010-.75"
        stroke="#353535"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
