export const Availability = ({ ...props }) => {
  return (
    <svg
      width={24}
      height={25}
      viewBox="0 0 24 25"
      fill="url(#Gradient)"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g clipPath="url(#aviability__clip0)">
        <circle
          cx={12}
          cy={12.5}
          r={11.25}
          stroke="#1C1033"
          strokeWidth={1.5}
        />
        <path
          d="M8.5 12l3.5 2 3.5-6"
          stroke="#1C1033"
          strokeWidth={1.5}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <circle cx={12} cy={4.5} r={1} fill="#1C1033" />
        <circle cx={12} cy={20.5} r={1} fill="#1C1033" />
        <circle
          cx={20}
          cy={12.5}
          r={1}
          transform="rotate(90 20 12.5)"
          fill="#1C1033"
        />
        <circle
          cx={4}
          cy={12.5}
          r={1}
          transform="rotate(90 4 12.5)"
          fill="#1C1033"
        />
      </g>
      <defs>
        <clipPath id="aviability__clip0">
          <path fill="#fff" transform="translate(0 .5)" d="M0 0h24v24H0z" />
        </clipPath>
        <linearGradient id="Gradient" gradientTransform="rotate(30)">
          <stop offset="0%" stopColor="#fff" />
          <stop offset="50%" stopColor="#fff" />
          <stop offset="100%" stopColor="#DDFFF9" />
        </linearGradient>
      </defs>
    </svg>
  );
};
