import { Button, Typography, Popconfirm } from 'antd';
import { useLocation } from 'react-router-dom';
import { useCallback, useState } from 'react';

import { useApplyForJob } from '@yougig/shared/jobs/useApplyForJob';
import { useAuth } from '@yougig/shared/auth/AuthProvider';
import { useDrawerContext } from '@yougig/ui/components/common/DrawerProvider';

export const ConsultantApplyButton = ({ job, candidate, applied }) => {
  const [isPopoverVisible, setPopoverVisibility] = useState(false);
  const location = useLocation();
  const { isAuthenticated, userRef } = useAuth();
  const { setDrawerView } = useDrawerContext();
  const { apply, isLoading } = useApplyForJob(job, candidate);

  const handleApplyClick = () => {
    if (isAuthenticated) {
      job?.screeningUrl
        ? setDrawerView('screening', { candidate: candidate })
        : apply();
    } else {
      setPopoverVisibility(!isPopoverVisible);
    }
  };

  const cancelAssignment = useCallback(() => {
    return candidate.cancelAssignment({ position: job, userRef });
  }, [job, userRef, candidate]);

  return (
    <>
      {applied ? (
        <div className="apply-notification">
          <Typography.Title level={2} className="apply-notification__title">
            Applied
          </Typography.Title>
          <Typography.Text className="apply-notification__text">
            Thank you for applying for a job. Your request will be processed
            during next working day.
            <br /> If your profile matches the position our manager will contact
            you.
          </Typography.Text>
          <div className="apply-notification__cancel-button">
            <Button type="ghost" size="small" onClick={cancelAssignment}>
              Cancel Application
            </Button>
          </div>
        </div>
      ) : (
        <div className="apply-button">
          <Popconfirm
            trigger="click"
            icon={null}
            placement="top"
            title="Please authorize to apply for a job"
            okText="Login"
            cancelText="Sign Up"
            cancelButtonProps={{
              type: 'ghost',
              href: `/registration?returnUrl=${location.pathname}`,
            }}
            okButtonProps={{
              type: 'primary',
              href: `/login?returnUrl=${location.pathname}`,
            }}
            visible={isPopoverVisible}
            onVisibleChange={handleApplyClick}
            overlayClassName="confirm-popover with-arrow">
            <Button
              type="primary"
              onClick={handleApplyClick}
              loading={isLoading}>
              <span>
                Apply for job <sup>beta</sup>
              </span>
            </Button>
          </Popconfirm>
        </div>
      )}
    </>
  );
};
