import styled from 'styled-components';
import { Typography } from 'antd';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px 16px;
  margin-bottom: 24px;
`;

const Item = styled.div`
  font-weight: 600;
  white-space: nowrap;
  text-transform: capitalize;
  color: ${(props) =>
    props.highlighted ? 'var(--c_green)' : 'var(--c_description)'};
`;

export const SkillsList = ({
  label,
  skills = [],
  highlighted = [],
  className,
}) => {
  return (
    <Wrapper className={className}>
      {!!label && <Typography.Text strong>{label}:</Typography.Text>}
      {skills.map((skill) => (
        <Item
          key={skill}
          highlighted={
            highlighted.findIndex(
              (item) =>
                item.toLowerCase().trim() === skill.toLowerCase().trim(),
            ) !== -1
          }>
          {skill}
        </Item>
      ))}
    </Wrapper>
  );
};
