import styled from 'styled-components';
import formatDate from 'date-fns/format';
import { Typography } from 'antd';

import { AVAILABILITY } from '@yougig/shared/candidates/constants';

import { TalentProfileLayout } from './TalentProfileLayout';
import { TalentExperience } from './TalentExperience';
import { TalentRate } from './TalentRate';
import { Scoring } from '../elements/Scoring';

const ColInfo = styled.div`
  flex-basis: 0;
  flex-grow: 1;
  min-width: 1px;
`;

const ColBar = styled.div`
  flex: 0 0 auto;
  min-width: 1px;
  border: 0 solid var(--c_light_blue);
  @media (min-width: 768px) {
    border-right-width: 1px;
    padding-right: 20px;
  }
  @media (max-width: 767px) {
    border-bottom-width: 1px;
    padding-bottom: 20px;
  }
`;

// @TODO: refactor with #579 ticket.
const formatAvailabilityDate = (availability) => {
  if (availability.status === AVAILABILITY.AVAILABLE) {
    return (
      <Typography.Text type="success">
        {availability.fromDate
          ? `Available from ${formatDate(availability.fromDate, 'dd.MM.yyyy')}`
          : 'Available Now'}
      </Typography.Text>
    );
  } else {
    return (
      <Typography.Text type="secondary">
        {availability.notAvailableUntilDate
          ? `Not Available until ${formatDate(
              availability.notAvailableUntilDate,
              'dd.MM.yyyy',
            )}`
          : 'Not Available'}
      </Typography.Text>
    );
  }
};

export const TalentClientInfo = ({ profile, withRate = true }) => {
  return (
    <TalentProfileLayout.Row>
      {!!profile.scoring && (
        <ColBar>
          <Scoring full {...profile.scoring} />
        </ColBar>
      )}
      <ColInfo>
        <TalentProfileLayout.Title level={4}>
          {profile.title} <TalentExperience profile={profile} />
        </TalentProfileLayout.Title>
        {!!profile.availability && formatAvailabilityDate(profile.availability)}
        <div>
          {profile.relocation !== undefined && profile.relocation
            ? 'Ready to Relocate, '
            : 'Not ready to Relocate, '}
          {profile.businessTrip !== undefined && profile.businessTrip
            ? 'Ready for Business Trips'
            : 'Not ready for Business Trips'}
        </div>
      </ColInfo>

      {withRate && <TalentRate profile={profile} />}
    </TalentProfileLayout.Row>
  );
};
