import { useCallback, useState } from 'react';
import { useQueryClient } from 'react-query';

import { useAuth } from '../auth/AuthProvider';

export const useApplyForJob = (job, candidate) => {
  const queryClient = useQueryClient();
  const [isLoading, setIsLoading] = useState(false);
  const { userRef } = useAuth();

  const apply = useCallback(async () => {
    setIsLoading(true);
    await candidate.apply({ position: job, userRef });
    queryClient.invalidateQueries('assignments');
    setIsLoading(false);
    console.log('candidate-client reference', candidate.id);
  }, [candidate, job, userRef, setIsLoading, queryClient]);

  return { apply, isLoading };
};
