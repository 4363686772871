import { memo } from 'react';
import { Button, Typography, Tabs, Divider } from 'antd';

import { useDrawerContext } from '@yougig/ui/components/common/DrawerProvider';
import { TalentInternalInfoBlock } from '@yougig/ui/components/talents/TalentInternalInfoBlock';
import { TalentProfileLayout } from '@yougig/ui/components/talents/TalentProfileLayout';
import { Edit } from '@yougig/ui/icons';
import { useAuth } from '@yougig/shared/auth/AuthProvider';
import { TalentClientInfo } from '@yougig/ui/components/talents/TalentClientInfo';
import { SkillsList } from '@yougig/ui/components/common/SkillsList';
import { ExperienceInfo } from '@yougig/ui/components/talents/ExperienceInfo';
import { PrescreeningScores } from '@yougig/ui/components/common/PrescreeningScores';
import { CV_STATUS } from '@yougig/shared/talents/constants';
import { TalentProfileReports } from '@yougig/ui/components/reports/TalentProfileReports';

import { TalentProfileRequests } from './TalentProfileRequests';
import { JobsTab } from './JobsTab';

export const TalentProfile = memo(() => {
  const { profile, setDrawerView } = useDrawerContext();
  const { isPartner, profile: account, tenant } = useAuth();

  const isReadOnly = profile.status === CV_STATUS.DELETED;

  return (
    <TalentProfileLayout>
      <div className="drawer-section">
        <TalentProfileLayout.Header
          profile={profile}
          actions={
            <>
              {!isReadOnly && (
                <Button
                  type="ghost"
                  size="large"
                  icon={<Edit />}
                  onClick={() => setDrawerView('editCandidate')}
                />
              )}
            </>
          }
        />
        <TalentClientInfo talent={profile} profile={profile} withRate={false} />
      </div>
      <TalentInternalInfoBlock
        profile={profile}
        name={isPartner ? tenant.legalName : profile.fullName}
        location={isPartner && tenant.location}
        teamOwner={isPartner && account}
        onlyTalentLinksAtt
      />
      <div className="drawer-section">
        {!!profile.ref && <TalentProfileRequests />}
        {!!profile.ref && (
          <TalentProfileReports
            profile={profile}
            onReportClick={(report) => setDrawerView('report', { report })}
          />
        )}
        <Typography.Title level={4}>Skills</Typography.Title>
        {!!profile.prescreening?.length && (
          <PrescreeningScores items={profile.prescreening} />
        )}
        <SkillsList skills={profile.skills} />
        <Divider />
        <TalentProfileLayout.Tabs>
          <Tabs.TabPane tab="Jobs" key="jobs">
            <JobsTab profile={profile} />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Work Experience" key="experience">
            {profile.experience_info?.details
              ?.sort((a, b) => b.start_year - a.start_year)
              .map((info) => (
                <ExperienceInfo key={info.organization} {...info} />
              )) || null}
          </Tabs.TabPane>
          <Tabs.TabPane
            tab="Education & Training"
            key="education"></Tabs.TabPane>
          <Tabs.TabPane tab="Portfolio" key="portfolio"></Tabs.TabPane>
        </TalentProfileLayout.Tabs>
      </div>
    </TalentProfileLayout>
  );
});
