import { useEffect, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import { useDocumentData } from 'react-firebase-hooks/firestore';

import services from '../utils/services';
import { Job } from './Job';

export const useApiJob = (jobId) => {
  const { data: apiJob, isLoading: apiLoading } = useQuery([
    'assignments',
    jobId,
  ]);

  const jobRef = useMemo(
    () =>
      services
        .get('db')
        .doc(`jobs/${apiJob?.external_id || jobId}`)
        .withConverter(Job.converter),
    [jobId, apiJob?.external_id],
  );
  const [firebaseJob, firebaseLoading] = useDocumentData(jobRef);

  const [clientRef, setClient] = useState();
  useEffect(() => {
    if ((!firebaseJob || !firebaseJob.client) && apiJob?.company_name) {
      services
        .get('db')
        .collection('clients')
        .where('businessName', '==', apiJob.company_name)
        .get()
        .then((snaps) => {
          if (snaps.size) {
            setClient(snaps.docs[0].ref);
          }
        });
    }
  }, [firebaseJob, apiJob?.company_name]);

  const jobClient = useMemo(
    () =>
      firebaseJob?.client ||
      clientRef ||
      services.get('db').doc('clients/48iWCY3Va5zBQ2IMiA9q'),
    [firebaseJob?.client, clientRef],
  );

  const job = useMemo(() => {
    if (firebaseJob) {
      if (apiJob) {
        firebaseJob.apiData = apiJob;
      }
      return firebaseJob;
    } else if (apiJob) {
      const job = Job.fromApi(apiJob);
      if (jobClient) {
        job.firebaseData.client = jobClient;
      }
      return job;
    }
    return { id: jobId };
  }, [jobId, apiJob, firebaseJob, jobClient]);

  return [job, apiLoading || firebaseLoading];
};
