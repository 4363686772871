import styled from 'styled-components';

const Bar = styled.span`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  height: 9px;
  .empty {
    background: var(--c_light_grey);
  }
`;

const DesignBar = styled(Bar)`
  .bar-item:not(.empty) {
    background: linear-gradient(8.02deg, #ffd9e0 -33.98%, #f90077 85.57%);
  }
`;

const TechnicalBar = styled(Bar)`
  .bar-item:not(.empty) {
    background: linear-gradient(1.97deg, #8789ff 3.48%, #0055d5 93.69%);
  }
`;

const BusinessBar = styled(Bar)`
  .bar-item:not(.empty) {
    background: linear-gradient(0deg, #01f0fe -29.13%, #00d1dd 99.43%);
  }
`;

const BarItem = styled.span`
  display: inline-block;
  width: 5px;
  height: 5px;
  margin-right: 1px;
  transform: rotate(45deg);
`;

const BarLabel = styled.span`
  margin-left: 16px;
  white-space: nowrap;
  color: var(--c_description);
`;

const ScoringStyled = styled.div`
  ${(props) =>
    props.full &&
    `
    ${Bar} {
      height: 16px;
      margin-bottom: 4px;
    }
    ${BarItem} {
      width: 12px;
      height: 12px;
      margin-right: 8px;
    }
  `};
`;

export const Scoring = ({
  technical = 0,
  creative = 0,
  business = 0,
  full = false,
}) => {
  if (!technical && !creative && !business) {
    return null;
  }
  return (
    <ScoringStyled full={full}>
      <TechnicalBar>
        {!full && !technical && <BarItem className="bar-item empty"></BarItem>}
        {Array.from({ length: technical }).map((_, index) => (
          <BarItem className="bar-item" key={index} />
        ))}
        {full && (
          <>
            {Array.from({ length: 5 - technical }).map((_, index) => (
              <BarItem className="bar-item empty" key={index} />
            ))}
            <BarLabel>Technical</BarLabel>
          </>
        )}
      </TechnicalBar>
      <BusinessBar>
        {!full && !business && <BarItem className="bar-item empty"></BarItem>}
        {Array.from({ length: business }).map((_, index) => (
          <BarItem className="bar-item" key={index} />
        ))}
        {full && (
          <>
            {Array.from({ length: 5 - business }).map((_, index) => (
              <BarItem className="bar-item empty" key={index} />
            ))}
            <BarLabel>Business</BarLabel>
          </>
        )}
      </BusinessBar>
      <DesignBar>
        {!full && !creative && <BarItem className="bar-item empty"></BarItem>}
        {Array.from({ length: creative }).map((_, index) => (
          <BarItem className="bar-item" key={index} />
        ))}
        {full && (
          <>
            {Array.from({ length: 5 - creative }).map((_, index) => (
              <BarItem className="bar-item empty" key={index} />
            ))}
            <BarLabel>Design</BarLabel>
          </>
        )}
      </DesignBar>
    </ScoringStyled>
  );
};
