import { Typography } from 'antd';

export const TalentExperience = ({ profile }) => {
  const experience =
    !profile.experience || profile.experience === 'unknown'
      ? null
      : Number(profile.experience);

  if (!!experience) {
    return (
      <Typography.Text type="secondary">
        {experience} yr{experience > 1 && 's'}
      </Typography.Text>
    );
  }

  return null;
};
