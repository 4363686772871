export const Fire = ({ color, ...props }) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M9.5 8.49242C9.5 10.0154 7.933 11.25 6 11.25C4.067 11.25 2.5 10.0154 2.5 8.49242C2.5 5.37125 8.25 9.75 7.5 3.75C9.25 5.7197 9.5 6.96946 9.5 8.49242Z"
      stroke={color ? color : 'currentColor'}
      strokeWidth="0.75"
    />
    <path
      d="M2.50019 8.27128C2.50019 6.62617 2.73399 6.02381 5.75019 1.5C5.75019 4.96355 6.43665 6.08569 7.22217 6.5"
      stroke={color ? color : 'currentColor'}
      strokeWidth="0.75"
    />
  </svg>
);
