import { Button, Upload } from 'antd';
import { useField } from 'formik';
import { Form, Input } from 'formik-antd';
import styled from 'styled-components';

import { Paperclip, File, Close } from '../../icons';

const FieldWrapper = styled.div`
  .ant-form-item {
    margin-bottom: 0;

    &.form-item--attachments .ant-form-item-control-input {
      min-height: auto;
    }
  }
`;

const TextArea = styled(Input.TextArea).attrs({ autoSize: true })`
  &.ant-input {
    min-height: 71px !important;
    overflow: hidden;
  }
`;

const UploadField = styled(Upload)`
  position: relative;
  display: block;

  .ant-upload-select {
    position: absolute;
    top: -25px;
    right: 10px;
  }

  .ant-upload-list-text-container {
    color: var(--c_violet);
    max-width: 100%;

    .ant-upload-list-item {
      &:hover .ant-upload-list-item-info {
        background-color: transparent;
      }

      &-name {
        padding-right: 0;
      }

      &-card-actions {
        line-height: 16px;

        &-btn {
          opacity: 1;
          width: 16px;
          height: 16px;
          margin: 0 0 0 4px;
          cursor: pointer;
          vertical-align: top;
          padding: 4px;
        }
      }
    }
  }
`;

export const TextAreaWithUpload = ({
  label,
  messageFieldName = 'message',
  attachmentsFieldName = 'attachments',
  readOnly = false,
  textAreaProps = {},
}) => {
  const [attachmentsField, , attachmentsHelpers] =
    useField(attachmentsFieldName);

  return (
    <>
      <FieldWrapper>
        <Form.Item name={messageFieldName} label={label}>
          <TextArea
            name={messageFieldName}
            readOnly={readOnly}
            {...textAreaProps}
          />
        </Form.Item>
        <Form.Item
          className="form-item--attachments"
          name={attachmentsFieldName}>
          <UploadField
            fileList={attachmentsField.value}
            onChange={({ file, fileList, error }) => {
              if (file.status === 'error') {
                attachmentsHelpers.setError(error.message);
              }
              attachmentsHelpers.setValue(fileList);
            }}
            beforeUpload={() => false}
            iconRender={() => <File />}
            showUploadList={{
              removeIcon: <Close />,
            }}>
            <Button icon={<Paperclip />} type="link" disabled={readOnly} />
          </UploadField>
        </Form.Item>
      </FieldWrapper>
    </>
  );
};
