import PropTypes from 'prop-types';

import { File, Close } from '../../icons';
import { IconValueLink, IconValuesRow } from './IconValue';

export const AttachmentsList = ({
  items = [],
  removeAble,
  flexEnd,
  buttons,
}) => {
  return (
    <IconValuesRow right={flexEnd}>
      {items
        .filter((a) => !a.deleted)
        .map((item) => (
          <IconValueLink
            key={item.fileName}
            href={item.url}
            target="_blank"
            buttons={buttons}>
            <File />
            {item.fileName}
            {removeAble && <Close />}
          </IconValueLink>
        ))}
    </IconValuesRow>
  );
};

AttachmentsList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      fileName: PropTypes.string.isRequired,
    }),
  ),
};
