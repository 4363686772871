import { forwardRef } from 'react';
import dateFnsGenerateConfig from 'rc-picker/lib/generate/dateFns';
import generatePicker from 'antd/lib/date-picker/generatePicker';
import isToday from 'date-fns/isToday';
import formatDate from 'date-fns/format';

import { Calendar, Cross } from '../../icons';

const DatePickerBase = generatePicker(dateFnsGenerateConfig);

export const DatePicker = forwardRef((props, ref) => (
  <DatePickerBase
    {...props}
    suffixIcon={Calendar}
    clearIcon={<Cross width={15} height={15} />}
    ref={ref}
  />
));

export const TimePicker = forwardRef((props, ref) => (
  <DatePickerBase {...props} picker="time" mode={undefined} ref={ref} />
));

export const dateFormatter = (value) =>
  isToday(new Date(value)) ? 'Today' : formatDate(value, 'yyyy-MM-dd');
