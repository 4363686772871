export const Flash = ({ filled = true, props }) => {
  return (
    <svg
      width="8px"
      height="12px"
      viewBox="0 0 8 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.672 11.5l1.985-4.623a.367.367 0 00-.338-.511H.87a.367.367 0 01-.339-.512L2.778.72A.367.367 0 013.115.5h3.771a.367.367 0 01.287.596L5.189 3.572a.366.366 0 00.287.595h1.656a.367.367 0 01.26.627L.672 11.5z"
        fill={filled ? '#f1eddd' : '#96A0B8'}
        stroke={filled ? '#FAA60C' : undefined}
        fillOpacity={filled ? 1 : 0.4}
        strokeWidth={0.5}
        strokeLinejoin="round"
      />
    </svg>
  );
};
