import { Typography } from 'antd';
import { Helmet } from 'react-helmet';

import { DrawerProvider } from '@yougig/ui/components/common/DrawerProvider';
import { useTeamMembers } from '@yougig/shared/teams/useTeamMembers';

import { PeopleTable } from './components/PeopleTable';
import { PeopleDrawer } from './components/PeopleDrawer';
import { CreateProfileButton } from './components/CreateProfileButton';

export const Profiles = () => {
  const { groups, activeMembersCount } = useTeamMembers();

  return (
    <>
      <Helmet>
        <title>Profiles</title>
      </Helmet>
      <DrawerProvider>
        <div className="page-heading">
          <Typography.Title>
            Profiles{' '}
            <Typography.Text type="secondary">
              {activeMembersCount || ''}
            </Typography.Text>
          </Typography.Title>

          <CreateProfileButton />
        </div>
        <PeopleTable memberGroups={groups} />
        <PeopleDrawer />
      </DrawerProvider>
    </>
  );
};
