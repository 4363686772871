import pick from 'lodash/pick';
import differenceBy from 'lodash/differenceBy';

import services from '../utils/services';
import { uniqueId } from '../utils/uniqueId';

export class Attachments {
  static fromRcUpload({ originFileObj, name, size, type, ...f }) {
    return {
      ...f,
      file: originFileObj,
      fileName: name,
      size: size,
      contentType: type,
    };
  }

  static toRcUpload(f) {
    return {
      ...f,
      name: f.fileName,
      type: f.contentType,
      uid: f.filePath,
    };
  }

  constructor(files = [], bucketName) {
    this.files = files;
    this.bucketName = bucketName;
  }

  getFileRef(fileName) {
    return (
      services
        .get('storage')
        // Firebase Storage: `path` param cannot contain ".." (storage/invalid-argument)
        // Because of such error we need to cut off these two dots.
        .ref(
          `${this.bucketName}/${uniqueId()}_${fileName}`.replace(
            /\.{2,}/gi,
            '.',
          ),
        )
    );
  }

  async uploadNew() {
    this.files = await Promise.all(
      this.files
        .filter((a) => !a.updated || !a.deleted)
        .map(async ({ file, ...metadata }) => {
          if (metadata.url) {
            return metadata;
          }
          return new Promise((resolve, reject) => {
            const fileRef = this.getFileRef(metadata.fileName);
            const task =
              typeof file === 'string'
                ? fileRef.putFile(file)
                : fileRef.put(file);

            task
              .then(async () => {
                const ref = task.snapshot.ref;
                const url = await ref.getDownloadURL();
                resolve({
                  ...metadata,
                  filePath: ref.fullPath,
                  url,
                  updated: services.get('now')(),
                });
              })
              .catch(reject);
          });
        }),
    );
  }

  async removeOrphaned(originalValues = []) {
    const toDelete = differenceBy(originalValues, this.files, 'filePath');

    toDelete.forEach((a) =>
      this.files.push({
        ...a,
        deleted: true,
        updated: !a.deleted ? services.get('now')() : a.updated,
      }),
    );
  }

  /**
   * [toFirestore description]
   *
   * @return  {Array<{fileName: string, filePath: string, size: number, contentType: string, url: string}>}
   */
  toFirestore() {
    return this.files.map((f) =>
      pick(f, [
        'fileName',
        'filePath',
        'size',
        'contentType',
        'url',
        'deleted',
        'updated',
      ]),
    );
  }
}
