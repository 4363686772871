import styled, { css } from 'styled-components';

const IconValueStyled = css`
  white-space: nowrap;
  max-width: 100%;
  text-overflow: ellipsis;
  min-width: 1px;
  overflow: hidden;
  font-size: 12px;
  line-height: 16px;
  svg {
    max-width: 16px;
    max-height: 16px;
    margin-right: 6px;
    min-height: 16px;
    vertical-align: top;
  }
  ${(props) =>
    props.secondary &&
    `
    color: var(--c_description);
  `}
  ${(props) =>
    props.buttons &&
    `
    font-size: 16px;
    line-height: 22px;
    display: inline-block;
    padding: 8px 16px;
    border-radius: 21px;
    border: 1px solid #5153DD;
    svg {
      display: none;
    }
    @media (max-width: 479px) {
      font-size: 14px;
      line-height: 19px;
    }
  `}
`;

export const IconValuesRow = styled.div`
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: ${(props) => (props.sm ? '8px' : '10px 20px')};
  ${(props) =>
    props.right &&
    `
    justify-content: flex-end;
  `}
`;

export const IconValue = styled.div`
  ${IconValueStyled};
`;

export const IconValueLink = styled.a`
  ${IconValueStyled};
`;
