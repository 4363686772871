import { Table } from '../elements/Table';
import { ExpandableTable } from '../elements/ExpandableTable';

const defaultRowKey = (row) => row.key;

export const TalentTable = ({
  expandable,
  selectedRowKey,
  onTalentSelect,
  rowKey = defaultRowKey,
  ...props
}) => {
  const TableComponent = expandable ? ExpandableTable : Table;

  return (
    <TableComponent
      pagination={false}
      rowClassName={(record) =>
        !record.children &&
        selectedRowKey &&
        rowKey(record) === selectedRowKey &&
        'ant-table-row--selected'
      }
      rowKey={rowKey}
      onRow={(record) => ({
        onClick: () => {
          if (!record.children && onTalentSelect) {
            onTalentSelect(record);
          }
        },
      })}
      {...props}
    />
  );
};
