import { Form, Checkbox } from 'formik-antd';
import { Divider, Form as AntForm } from 'antd';

import { CountryField } from '@yougig/ui/components/form/CountryField';

import { LanguagesField } from '../form/LanguagesField';

export const LocationLanguagesFields = () => {
  return (
    <>
      <div className="row">
        <Form.Item
          name="originallyFrom"
          label="Originally from"
          className="col-6">
          <CountryField name="originallyFrom" />
        </Form.Item>
        <Form.Item name="country" label="Current Location" className="col-6">
          <CountryField name="country" />
        </Form.Item>
        <Form.Item
          name="allowedToWorkIn"
          label="Allowed to Work in"
          className="col-12">
          <CountryField name="allowedToWorkIn" mode="multiple" />
        </Form.Item>
        <div className="col-12">
          <Form.Item name="relocation" className="mb-0">
            <Checkbox name="relocation" valuePropName="checked">
              Ready to Relocate
            </Checkbox>
          </Form.Item>
          <Form.Item name="businessTrip">
            <Checkbox name="businessTrip" valuePropName="checked">
              Ready for Business Trips
            </Checkbox>
          </Form.Item>
        </div>
      </div>
      <Divider />
      <AntForm.Item>
        <LanguagesField name="languages" />
      </AntForm.Item>
    </>
  );
};
