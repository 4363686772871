import { useMemo, useState } from 'react';
import styled from 'styled-components';
import { Button, Card, Form as AntForm, Typography } from 'antd';
import { Checkbox, Form, Radio } from 'formik-antd';
import { Link, useHistory } from 'react-router-dom';
import { Formik, validateYupSchema, yupToFormErrors } from 'formik';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import pick from 'lodash/pick';

import { AVAILABILITY } from '@yougig/shared/candidates/constants';
import { User } from '@yougig/shared/auth/User';
import { specialistFormSchema } from '@yougig/shared/auth/shemas';
import {
  talentLocationLanguagesSchema,
  talentSkillsSchema,
  talentAvailabilitySchema,
} from '@yougig/shared/talents/schemas';
import { teamSchema, signPersonSchema } from '@yougig/shared/auth/shemas';
import { object, string, array } from '@yougig/shared/utils/validation';
import { Modal } from '@yougig/ui/components/elements/Modal';
import { useQueryParams } from '@yougig/ui/utils/useQueryParams';
import {
  ACCOUNT_TYPE_OPTIONS,
  ACCOUNT_TYPE,
} from '@yougig/shared/auth/constants';
import { CV_STATUS } from '@yougig/shared/talents/constants';

import { db, functions } from '../../utils/firebase';
import { TeamForm } from './components/TeamForm';
import { SpecialistFrom } from './components/SpecialistForm';

const specialistInitialValues = {
  account_type: ACCOUNT_TYPE.SPECIALIST,
  firstName: '',
  lastName: '',
  email: '',
  title: '',
  password: '',
  talentLinks: [],
  talentAttachments: [],
  languages: [{ language: '', level: '' }],
  country: '',
  relocation: false,
  businessTrip: false,
  skills: [],
  privacy: false,
  analyticsEnabled: true,
  phone: {
    code: '',
    number: '',
  },
  availability: {
    status: AVAILABILITY.AVAILABLE,
    fromDate: null,
    untilDate: null,
    availability: 40,
    notAvailableUntilDate: null,
  },
};

const teamInitialValues = {
  account_type: ACCOUNT_TYPE.TEAM,
  firstName: '',
  lastName: '',
  title: '',
  email: '',
  phone: {
    code: '1',
    number: '',
  },
  password: '',
  right: false,
  privacy: false,
  analyticsEnabled: true,
  team: {
    name: '',
    legalName: '',
    location: '',
    speciality: '',
    linkedinCompany: '',
    import: false,
  },
  signPerson: {
    firstName: '',
    lastName: '',
    email: '',
    title: '',
    phone: {
      code: '1',
      number: '',
    },
  },
};

const mainSpecialistSchema = specialistFormSchema.concat(
  object({
    talentLinks: array().of(object({ url: string(), notes: string() })),
    title: string().required(),
  }),
);

const additionalSpecialistSchema = talentLocationLanguagesSchema
  .concat(talentSkillsSchema)
  .concat(talentAvailabilitySchema);

const teamSchemaWithCreator = specialistFormSchema.concat(
  object({
    title: string().required(),
    team: teamSchema,
  }),
);

const FormAgreement = styled.div`
  margin: 40px 0 8px 0;
`;

const Header = styled.div`
  flex-direction: column;
  align-items: flex-start;
`;

const validateEmail = async (email) => {
  const emailCheck = functions.httpsCallable('accountExists');
  return await emailCheck({
    email: email,
  });
};

const getValidationSchema = (values, specialistFormState) => {
  if (values.account_type === ACCOUNT_TYPE.SPECIALIST) {
    return specialistFormState === 'main'
      ? mainSpecialistSchema
      : additionalSpecialistSchema;
  } else {
    return values.right
      ? teamSchemaWithCreator
      : teamSchemaWithCreator.concat(object({ signPerson: signPersonSchema }));
  }
};

export const parseLinkedinSlug = (url) => {
  return (
    url &&
    (url.match('(https?://www.linkedin.com/company/)([A-Za-z-0-9]+)')[2] || '')
  );
};

export const Registration = () => {
  const history = useHistory();
  const query = useQueryParams();
  const [specialistFormState, setSpecialistFormState] = useState('main');
  const [isVisibleModal, setModalVisibility] = useState(false);
  const invitation = query.get('invitation');
  const [profile] = useDocumentData(
    invitation && db.doc(`profiles/${invitation}`),
    { idField: 'id' },
  );
  const teamName = query.get('team');

  const initialValues = useMemo(() => {
    if (profile) {
      return {
        ...specialistInitialValues,
        ...pick(profile, Object.keys(specialistInitialValues)),
        availability: {
          ...specialistInitialValues.availability,
          ...profile.availability,
        },
      };
    }
    return specialistInitialValues;
  }, [profile]);

  const goToPreviousPage = () => {
    const returnUrl = query.get('returnUrl');
    if (!!returnUrl) {
      history.push(returnUrl);
    } else {
      history.push('/');
    }
  };

  return (
    <>
      <div className="container-form registration-form">
        <Header className="page-heading">
          <Typography.Title>Registration</Typography.Title>
          {!!(invitation && teamName) ? (
            <Typography>You have been invited in {teamName}</Typography>
          ) : (
            <Typography>
              Already have an account?
              <Link to="/login">Login</Link>
            </Typography>
          )}
        </Header>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validate={async (values) => {
            const validationSchema = getValidationSchema(
              values,
              specialistFormState,
            );
            try {
              await validateYupSchema(values, validationSchema);
            } catch (errors) {
              return yupToFormErrors(errors);
            }
          }}
          onSubmit={async (values, { setFieldError }) => {
            if (values.account_type === ACCOUNT_TYPE.SPECIALIST) {
              if (specialistFormState === 'main') {
                const accountExists = await validateEmail(values.email);
                accountExists.data
                  ? setFieldError(
                      'email',
                      'Account with this email already exists',
                    )
                  : setSpecialistFormState('additional');
              }
              if (specialistFormState === 'additional') {
                await User.createCandidateAccount(
                  values,
                  { status: CV_STATUS.DRAFT },
                  profile,
                );
                goToPreviousPage();
              }
            }
            if (values.account_type === ACCOUNT_TYPE.TEAM) {
              const { right, signPerson, ...data } = values;
              const accountExists = await validateEmail(data.email);
              if (accountExists.data) {
                setFieldError(
                  'email',
                  'Account with this email already exists',
                );
              }
              const linkedinCompany = parseLinkedinSlug(
                data.team.linkedinCompany,
              );
              const teamData = {
                ...data,
                team: {
                  ...data.team,
                  linkedinCompany: linkedinCompany,
                  ...(!right && { signPerson }),
                },
              };

              await User.createPartnerAccount(teamData);
              goToPreviousPage();
            }
          }}>
          {({ handleSubmit, isSubmitting, dirty, resetForm, values }) => (
            <Form layout="vertical">
              <Card>
                {specialistFormState !== 'additional' && (
                  <Form.Item name="account_type" label="I am" className="col-6">
                    <Radio.Group
                      name="account_type"
                      options={ACCOUNT_TYPE_OPTIONS}
                      optionType="button"
                      buttonStyle="solid"
                      defaultValue={ACCOUNT_TYPE.SPECIALIST}
                      value={values.account_type}
                      disabled={!!invitation}
                      onChange={() => {
                        const initialValues =
                          values.account_type === ACCOUNT_TYPE.SPECIALIST
                            ? teamInitialValues
                            : specialistInitialValues;
                        resetForm({
                          values: initialValues,
                        });
                      }}
                    />
                  </Form.Item>
                )}
                {values.account_type === ACCOUNT_TYPE.TEAM && <TeamForm />}
                {values.account_type === ACCOUNT_TYPE.SPECIALIST && (
                  <SpecialistFrom formState={specialistFormState} />
                )}
              </Card>
              {specialistFormState !== 'additional' && (
                <FormAgreement>
                  <Form.Item name="privacy" className="mb-0">
                    <Checkbox name="privacy" valuePropName="checked">
                      I agree to the Privacy Policy & the Terms of Use
                    </Checkbox>
                  </Form.Item>
                  <Form.Item name="mailing" className="mb-0">
                    <Checkbox name="mailing" valuePropName="checked">
                      I agree to keeping me informed with personalized news,
                      offers, products and promotions
                    </Checkbox>
                  </Form.Item>
                </FormAgreement>
              )}
              <AntForm.Item className="form-actions mt-32">
                <Button
                  type="ghost"
                  htmlType="button"
                  size="large"
                  onClick={() =>
                    !dirty ? history.goBack() : setModalVisibility(true)
                  }>
                  Cancel
                </Button>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  className="ml-auto"
                  disabled={isSubmitting}
                  onClick={handleSubmit}>
                  {specialistFormState !== 'additional' &&
                  values.account_type !== ACCOUNT_TYPE.TEAM
                    ? 'Next'
                    : 'Lets go'}
                </Button>
              </AntForm.Item>
            </Form>
          )}
        </Formik>
      </div>
      <Modal
        name="cancel_form"
        title="Are you sure?"
        visible={isVisibleModal}
        onCancel={() => setModalVisibility(false)}
        component={() => (
          <>
            <Typography.Paragraph>
              All your provided information will be deleted.
            </Typography.Paragraph>
            <div className="modal-footer">
              <Button type="ghost" onClick={() => history.goBack()}>
                Yes
              </Button>
              <Button type="primary" onClick={() => setModalVisibility(false)}>
                No
              </Button>
            </div>
          </>
        )}
      />
    </>
  );
};
