import { Select } from 'formik-antd';

import countries from '../../utils/countries.json';
import { ArrowBottom, Cross } from '../../icons';

const countryNameOptions = countries.map(({ flag, name, code }) => ({
  value: code,
  label: `${flag} ${name}`,
}));

export const CountryField = ({ name, mode, ...props }) => {
  return (
    <Select
      allowClear
      showArrow
      showSearch
      mode={mode}
      name={name}
      options={countryNameOptions}
      suffixIcon={<ArrowBottom width={16} height={16} />}
      clearIcon={<Cross width={16} height={16} />}
      placeholder="Country"
      filterOption={(input, option) =>
        option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      getPopupContainer={(node) => node.parentElement}
      {...props}
    />
  );
};
