import { useComingInterviews } from '@yougig/shared/interviews/useInterviews';
import { RequestCard } from '@yougig/ui/components/elements/RequestCard';
import { REQUEST_STATUS_OPTIONS } from '@yougig/shared/actions/constants';

import { OverviewCardList } from './OverviewCardList';

export const InterviewsSection = () => {
  const comingInterviews = useComingInterviews(4);

  return (
    <OverviewCardList title="Interviews" showAllViewMode="interviews">
      {comingInterviews.map(({ businessName, interview }) => {
        interview.companyName = businessName;

        return (
          <RequestCard
            key={interview.id}
            request={interview}
            statuses={REQUEST_STATUS_OPTIONS}
            canEdit={false}
          />
        );
      })}
    </OverviewCardList>
  );
};
