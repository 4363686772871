import { Options } from '../utils/Options';
import { COLORS } from '../constants';

export const CANDIDATE_STATUS = {
  // CLIENT DECISION
  ACCEPTED: 'accepted',
  INTERVIEW: 'interview',
  REQUESTED: 'requested',
  SUGGESTED: 'suggested',
  DISMISSED: 'dismissed',
  // PROCESSING
  READY: 'ready',
  SELECTED: 'selected',
  PRESELECTED: 'pre-selected',
  SELF_APPLIED: 'self-applied',
  ON_HOLD: 'on-hold',
  // SEARCH
  INVITED: 'invited',
  PRIORITIZED: 'proiritized',
  INCOMING: 'incoming',
  ARCHIVED: 'archived',
};

export const CANDIDATE_STATUS_GROUPS = {
  CLIENT_DECISION: 'CLIENT_DECISION',
  PROCESSING: 'PROCESSING',
  SEARCH: 'SEARCH',
};

export const CANDIDATE_STATUS_OPTIONS = new Options(
  {
    value: CANDIDATE_STATUS.ACCEPTED,
    color: COLORS.PRIMARY,
    background: COLORS.LIGHT_GREEN,
    label: 'Accepted',
    group: CANDIDATE_STATUS_GROUPS.CLIENT_DECISION,
  },
  {
    value: CANDIDATE_STATUS.INTERVIEW,
    color: COLORS.ORANGE,
    background: COLORS.LIGHT_ORANGE,
    label: 'Consideration',
    group: CANDIDATE_STATUS_GROUPS.CLIENT_DECISION,
  },
  {
    value: CANDIDATE_STATUS.REQUESTED,
    color: COLORS.PRIMARY,
    background: COLORS.LIGHT_BLUE,
    label: 'Requested',
    group: CANDIDATE_STATUS_GROUPS.CLIENT_DECISION,
  },
  {
    value: CANDIDATE_STATUS.DISMISSED,
    color: COLORS.TEXT,
    background: COLORS.LIGHT_GREY,
    label: 'Dismissed',
    group: CANDIDATE_STATUS_GROUPS.CLIENT_DECISION,
  },
  {
    value: CANDIDATE_STATUS.SUGGESTED,
    color: COLORS.PRIMARY,
    background: COLORS.LIGHT_BLUE,
    label: 'Suggested',
    group: CANDIDATE_STATUS_GROUPS.CLIENT_DECISION,
  },
  {
    value: CANDIDATE_STATUS.READY,
    color: COLORS.PRIMARY,
    background: COLORS.LIGHT_BLUE,
    label: 'Ready',
    group: CANDIDATE_STATUS_GROUPS.PROCESSING,
  },
  {
    value: CANDIDATE_STATUS.SELECTED,
    color: COLORS.PRIMARY,
    background: COLORS.LIGHT_BLUE,
    label: 'Selected',
    group: CANDIDATE_STATUS_GROUPS.PROCESSING,
  },
  {
    value: CANDIDATE_STATUS.ON_HOLD,
    color: COLORS.TEXT,
    background: COLORS.LIGHT_GREY,
    label: 'On Hold',
    group: CANDIDATE_STATUS_GROUPS.PROCESSING,
  },
  {
    value: CANDIDATE_STATUS.PRESELECTED,
    color: COLORS.PRIMARY,
    background: COLORS.LIGHT_BLUE,
    label: 'Preselected',
    group: CANDIDATE_STATUS_GROUPS.PROCESSING,
  },
  {
    value: CANDIDATE_STATUS.SELF_APPLIED,
    color: COLORS.PRIMARY,
    background: COLORS.LIGHT_BLUE,
    label: 'Just Applied',
    group: CANDIDATE_STATUS_GROUPS.PROCESSING,
  },
  {
    value: CANDIDATE_STATUS.INVITED,
    color: COLORS.PRIMARY,
    background: COLORS.LIGHT_BLUE,
    label: 'Invited',
    group: CANDIDATE_STATUS_GROUPS.SEARCH,
  },
  {
    value: CANDIDATE_STATUS.PRIORITIZED,
    color: COLORS.PRIMARY,
    background: COLORS.LIGHT_BLUE,
    label: 'Prioritized',
    group: CANDIDATE_STATUS_GROUPS.SEARCH,
  },
  {
    value: CANDIDATE_STATUS.ARCHIVED,
    color: COLORS.TEXT,
    background: COLORS.LIGHT_GREY,
    label: 'Dropped',
    group: CANDIDATE_STATUS_GROUPS.SEARCH,
  },
  {
    value: CANDIDATE_STATUS.INCOMING,
    color: COLORS.PRIMARY,
    background: COLORS.LIGHT_BLUE,
    label: 'Incoming',
    group: CANDIDATE_STATUS_GROUPS.SEARCH,
  },
);

export const CANDIDATE_TYPE = {
  ALL: 'all',
  VETTED: 'vetted',
  PROVEN: 'proven',
};

export const CANDIDATE_TYPE_OPTIONS = new Options(
  {
    value: CANDIDATE_TYPE.VETTED,
    label: 'Vetted',
    icon: 'vetted',
  },
  {
    value: CANDIDATE_TYPE.PROVEN,
    label: 'Proven',
    icon: 'diamond',
  },
);

export const AVAILABILITY = {
  AVAILABLE: 'available',
  NOT_AVAILABLE: 'not-available',
};

export const AVAILABILITY_OPTIONS = new Options(
  {
    label: 'Available',
    longLabel: 'Available Now',
    color: COLORS.GREEN,
    value: AVAILABILITY.AVAILABLE,
  },
  {
    label: 'Not available yet',
    longLabel: 'Not Available',
    color: COLORS.DESCRIPTION,
    value: AVAILABILITY.NOT_AVAILABLE,
  },
);

export const REASONS = [
  'Rejected due to quality or skills',
  'Skipped',
  'Consultant not available',
  'Other',
];

export const RELOCATION_OPTIONS = new Options(
  {
    value: true,
    label: 'Ready',
  },
  {
    value: false,
    label: 'Not Ready',
  },
);

export const BUSINESS_TRIP_OPTIONS = new Options(
  {
    value: true,
    label: 'Ready',
  },
  {
    value: false,
    label: 'Not Ready',
  },
);

export const EDUCATION = {
  EDUCATION: 'education',
  COURSE: 'course',
  TEST_SCORE: 'test_score',
  PUBLICATION: 'publication',
  CERTIFICATE: 'certificate',
  CONFERENCE: 'conference',
  AWARD: 'award',
  PATENT: 'patent',
  INTERNSHIP: 'internship',
};

export const EDUCATION_OPTIONS = new Options(
  {
    value: EDUCATION.EDUCATION,
    label: 'Education',
    icon: 'educationType',
  },
  {
    value: EDUCATION.COURSE,
    label: 'Course',
    icon: 'courseType',
  },
  {
    value: EDUCATION.TEST_SCORE,
    label: 'Test Score',
    icon: 'testScoreType',
  },
  {
    value: EDUCATION.PUBLICATION,
    label: 'Publication',
    icon: 'publicationType',
  },
  {
    value: EDUCATION.CERTIFICATE,
    label: 'Certificate',
    icon: 'certificateType',
  },
  {
    value: EDUCATION.CONFERENCE,
    label: 'Conference',
    icon: 'conferenceType',
  },
  {
    value: EDUCATION.AWARD,
    label: 'Award',
    icon: 'awardType',
  },
  {
    value: EDUCATION.PATENT,
    label: 'Patent',
    icon: 'patentType',
  },
  {
    value: EDUCATION.INTERNSHIP,
    label: 'Internship',
    icon: 'internshipType',
  },
);

export const MANAGER_CHECKLIST = new Options(
  {
    value: 'applied',
    label: 'Application form filled in?',
  },
  {
    value: 'reachable_via_messenger',
    label: 'Reachable via messenger (ideally Telegram)?',
  },
  {
    value: 'ready_to_start',
    label:
      '100% available and ready to start? Or just looking for potential/extra work?',
  },
  {
    value: 'budget_rates',
    label: 'Rates on our budget?',
  },
  {
    value: 'ready_for_interview_and_task',
    label: 'Ready to take interview and test task if necessary?',
  },
  {
    value: 'required_video',
    label: 'Video bio is required (last field in the apply form)',
  },
  {
    value: 'skills_concern',
    label: 'Any concerns regarding soft skills and overall professionalism?',
  },
);
