import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Radio, Typography } from 'antd';

import { useApplyForJob } from '@yougig/shared/jobs/useApplyForJob';
import { useApiJob } from '@yougig/shared/jobs/useApiJob';
import { useDrawerContext } from '@yougig/ui/components/common/DrawerProvider';
import { useTeamJobCandidatesContext } from '@yougig/shared/teams/TeamJobCandidatesProvider';
import { InputSearch } from '@yougig/ui/components/elements/InputSearch';

export const ApplyDrawerSelectConsultant = () => {
  const params = useParams();
  const [job] = useApiJob(params.jobId);
  const [teamMember, setTeamMember] = useState(null);
  const [keywords, setKeywords] = useState('');
  const { setDrawerView, closeDrawer } = useDrawerContext();
  const { notAppliedCandidates, refresh } = useTeamJobCandidatesContext();

  const filteredCandidates = useMemo(() => {
    return notAppliedCandidates.filter(({ profile }) =>
      profile.fullName.toLowerCase().includes(keywords.toLowerCase()),
    );
  }, [keywords, notAppliedCandidates]);

  const changeTeamMember = (e) => {
    setTeamMember(e.target.value);
  };

  const { apply, isLoading } = useApplyForJob(job, teamMember);

  return (
    <>
      <Typography.Title level={2}>Select a Consultant</Typography.Title>
      <InputSearch onSearch={setKeywords} />
      <Radio.Group
        name="teamSpecialist"
        onChange={changeTeamMember}
        className="team"
        value={teamMember}>
        {filteredCandidates.map(({ candidate, profile }) => (
          <Radio
            value={
              candidate
            }>{`${profile.firstName} ${profile.lastName}`}</Radio>
        ))}
      </Radio.Group>
      <Button
        block
        disabled={!filteredCandidates.length}
        loading={isLoading}
        onClick={async () => {
          if (teamMember) {
            if (job?.screeningUrl) {
              setDrawerView('screening', {
                candidate: teamMember,
                refresh: refresh,
              });
            } else {
              await apply();
              refresh();
              closeDrawer();
            }
          }
        }}>
        Next
      </Button>
    </>
  );
};
