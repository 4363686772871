import { Formik } from 'formik';
import { Form, Input } from 'formik-antd';
import { Collapse, Button, Typography, Form as AntForm } from 'antd';
import merge from 'lodash/merge';
import pick from 'lodash/pick';
import isObject from 'lodash/isPlainObject';
import { useMemo } from 'react';

import { useDrawerContext } from '@yougig/ui/components/common/DrawerProvider';
import {
  ArrowBottom,
  Availability,
  Location,
  Lock,
  Skills,
} from '@yougig/ui/icons';
import { LocationLanguagesFields } from '@yougig/ui/components/talents/LocationLanguagesFields';
import { SkillsToolsField } from '@yougig/ui/components/form/SkillsToolsField';
import { AvailabilityFields } from '@yougig/ui/components/talents/AvailabilityFields';
import { AVAILABILITY } from '@yougig/shared/candidates/constants';
import {
  mainSchema,
  talentLocationLanguagesSchema,
  talentSkillsSchema,
  talentAvailabilitySchema,
} from '@yougig/shared/talents/schemas';
import { Attachments } from '@yougig/shared/common/Attachments';
import { useAuth } from '@yougig/shared/auth/AuthProvider';
import { Profile } from '@yougig/shared/talents/Profile';
import { CV_STATUS } from '@yougig/shared/talents/constants';

import { InternalInfoFields } from './InternalInfoFields';

const initialValues = {
  firstName: '',
  lastName: '',
  title: '',
  internalRate: '',
  talentLinks: [],
  talentAttachments: [],
  languages: [{ language: '', level: '' }],
  country: '',
  originallyFrom: '',
  allowedToWorkIn: [],
  relocation: false,
  businessTrip: false,
  skills: [],
  availability: {
    status: AVAILABILITY.AVAILABLE,
    fromDate: null,
    untilDate: null,
    availability: 40,
    notAvailableUntilDate: null,
  },
};

const validationSchema = mainSchema
  .concat(talentLocationLanguagesSchema)
  .concat(talentSkillsSchema)
  .concat(talentAvailabilitySchema);

export const ProfileForm = () => {
  /**
   * @typedef DrawerContext
   * @property {import('@yougig/shared/talents/Profile').Profile} profile
   */
  /**
   * @type DrawerContext
   */
  const { profile, closeDrawer, setDrawerView } = useDrawerContext();
  const { profile: account } = useAuth();
  const formInitialValues = useMemo(() => {
    const values = merge(
      {},
      initialValues,
      pick(profile?.firebaseData, Object.keys(initialValues)),
    );
    if (!isObject(profile?.availability)) {
      values.availability = initialValues.availability;
    }
    if (values.talentAttachments?.length) {
      values.talentAttachments = values.talentAttachments.map(
        Attachments.toRcUpload,
      );
    }
    return values;
  }, [profile]);

  return (
    <div className="drawer-section">
      <Typography.Title level={3} className="mb-40">
        {profile?.id ? 'Edit Candidate' : 'Create a Candidate'}
      </Typography.Title>
      <Formik
        initialValues={formInitialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={async (
          { talentAttachments, ...values },
          { setSubmitting },
        ) => {
          const attachments = talentAttachments.map(Attachments.fromRcUpload);
          const payload = { ...values, talentAttachments: attachments };
          payload.team = account.tenant;
          const profileToStore = profile || new Profile();
          await profileToStore.save(payload);
          setSubmitting(false);
          setDrawerView('profile', { profile: profileToStore });
        }}>
        {({ values, isSubmitting, setFieldValue }) => (
          <Form layout="vertical">
            <div className="row">
              <Form.Item name="firstName" label="First Name" className="col-6">
                <Input name="firstName" placeholder="First Name" />
              </Form.Item>
              <Form.Item name="lastName" label="Last Name" className="col-6">
                <Input name="lastName" placeholder="Last Name" />
              </Form.Item>
              <Form.Item name="title" label="Profession" className="col-6">
                <Input name="title" placeholder="Title" />
              </Form.Item>
            </div>
            <Collapse
              expandIcon={ArrowBottom}
              expandIconPosition="right"
              defaultActiveKey={[
                'internal',
                'location',
                'skills',
                'availability',
              ]}>
              <Collapse.Panel
                key="internal"
                header={
                  <>
                    <Lock width={24} height={24} />
                    Internal Info
                  </>
                }>
                <InternalInfoFields />
              </Collapse.Panel>
              <Collapse.Panel
                key="location"
                header={
                  <>
                    <Location width={24} height={24} outlined={false} />
                    Location & Languages
                  </>
                }>
                <LocationLanguagesFields />
              </Collapse.Panel>
              <Collapse.Panel
                key="skills"
                header={
                  <>
                    <Skills />
                    Skills
                  </>
                }>
                <div className="row">
                  <SkillsToolsField name="skills" label="Skills / Tools" />
                </div>
              </Collapse.Panel>
              <Collapse.Panel
                key="availability"
                header={
                  <>
                    <Availability />
                    Availability
                  </>
                }>
                <AvailabilityFields />
              </Collapse.Panel>
            </Collapse>
            <AntForm.Item className="form-actions">
              <Button type="ghost" htmlType="button" onClick={closeDrawer}>
                Cancel
              </Button>
              <Button
                type="ghost"
                htmlType="submit"
                className="ml-auto"
                loading={isSubmitting && values.status === CV_STATUS.DRAFT}
                onClick={() => setFieldValue('status', CV_STATUS.DRAFT)}>
                Save as Draft
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                loading={isSubmitting && values.status === CV_STATUS.PUBLISH}
                onClick={() => setFieldValue('status', CV_STATUS.PUBLISH)}>
                Publish
              </Button>
            </AntForm.Item>
          </Form>
        )}
      </Formik>
    </div>
  );
};
