export const ArrowBottom = ({
  width = 24,
  height = 24,
  viewBox = '0 0 24 24',
  fill = 'none',
}) => (
  <svg width={width} height={height} viewBox={viewBox} fill={fill}>
    <path
      transform="translate(0 -2)"
      d="M11.9997 19L11.4694 19.5303C11.7623 19.8232 12.2372 19.8232 12.5301 19.5303L11.9997 19ZM2.52984 8.46967C2.23694 8.17677 1.76207 8.17678 1.46918 8.46968C1.17629 8.76257 1.17629 9.23745 1.46919 9.53034L2.52984 8.46967ZM22.5298 9.53033C22.8227 9.23743 22.8227 8.76256 22.5298 8.46967C22.2369 8.17678 21.7621 8.17678 21.4692 8.46968L22.5298 9.53033ZM12.53 18.4697L2.52984 8.46967L1.46919 9.53034L11.4694 19.5303L12.53 18.4697ZM21.4692 8.46968L11.4694 18.4697L12.5301 19.5303L22.5298 9.53033L21.4692 8.46968Z"
      fill="currentColor"
    />
  </svg>
);
