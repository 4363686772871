import { Typography, Button } from 'antd';
import { Formik } from 'formik';
import { Form, Input } from 'formik-antd';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { useAuth } from '@yougig/shared/auth/AuthProvider';
import { Card } from '@yougig/ui/components/elements/Card';
import { object, string } from '@yougig/shared/utils/validation';
import { useQueryParams } from '@yougig/ui/utils/useQueryParams';

const FormErrorContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 24px;
`;

const FormContainer = styled.div`
  margin: 40px auto;
  max-width: 400px;
`;

const FormFooter = styled.div`
  text-align: right;
`;

const LoginPageContainer = styled.div`
  width: 625px;
  margin: 0 auto;
  max-width: 100%;
`;

const Header = styled.div`
  flex-direction: column;
  align-items: flex-start;
`;

const schema = object({
  email: string().email().required(),
  password: string().required(),
});

export const Login = () => {
  const { authorize } = useAuth();
  const history = useHistory();
  const query = useQueryParams();

  return (
    <LoginPageContainer>
      <Header className="page-heading">
        <Typography.Title>Login</Typography.Title>
        <Typography.Text>
          Do not have an account?
          <Link to="/registration">Sign Up</Link>
        </Typography.Text>
      </Header>
      <Card>
        <FormContainer>
          <Formik
            initialValues={{ email: '', password: '' }}
            validationSchema={schema}
            onSubmit={async (values, { setStatus, setSubmitting }) => {
              try {
                await authorize(values);
                setSubmitting(false);
                const returnUrl = query.get('returnUrl');
                history.push(returnUrl || '');
              } catch (err) {
                setStatus(err.message);
              }
            }}>
            {({ status, handleSubmit, isSubmitting }) => (
              <>
                <Form onSubmit={handleSubmit} layout="vertical">
                  <Form.Item name="email" label="Email">
                    <Input
                      type="email"
                      name="email"
                      placeholder="email@example.com"
                    />
                  </Form.Item>
                  <Form.Item name="password" label="Password">
                    <Input
                      type="password"
                      name="password"
                      placeholder="Password"
                    />
                  </Form.Item>
                  {!!status && (
                    <FormErrorContainer>
                      <Typography.Text type="danger">{status}</Typography.Text>
                    </FormErrorContainer>
                  )}
                  <FormFooter>
                    <Button
                      type="primary"
                      htmlType="submit"
                      loading={isSubmitting}>
                      Sign In
                    </Button>
                  </FormFooter>
                </Form>
              </>
            )}
          </Formik>
        </FormContainer>
      </Card>
    </LoginPageContainer>
  );
};
