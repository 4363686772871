export const Cross = ({ color = '#FF5170', ...props }) => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" {...props}>
    <path
      d="M1 1L11 11"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 1L1 11"
      stroke={color}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
