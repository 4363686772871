import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { db } from '@yougig/talent/src/utils/firebase';
import { useApi } from '@yougig/shared/api/Api';

import { Candidate } from '../candidates/Candidate';
import { CANDIDATE_STATUS } from '../candidates/constants';
import { Job } from './Job';

const statuses = [
  CANDIDATE_STATUS.ACCEPTED,
  CANDIDATE_STATUS.INTERVIEW,
  CANDIDATE_STATUS.SELF_APPLIED,
];

export const useProfileJobs = (profileRef) => {
  const { apiClient } = useApi();
  const [state, setState] = useState([]);

  useEffect(() => {
    if (!profileRef) {
      return;
    }
    return db
      .collection('candidates')
      .where('consultant', '==', profileRef)
      .where('status', 'in', statuses)
      .withConverter(Candidate.converter)
      .onSnapshot(async (snaps) => {
        const appliedJobsIds = await Promise.all(
          snaps.docs.map(async (snap) => {
            const candidateStatus = snap.get('status');
            const jobs = await snap.ref.collection('assignments').get();
            const jobsIds = jobs.docs.flatMap((jobSnap) => jobSnap.id);
            return { candidateStatus, jobsIds };
          }),
        );
        setState(appliedJobsIds);
      });
  }, [profileRef]);

  const { data: jobs } = useQuery(
    ['assignments', state],
    async () => {
      const response = await apiClient.get('assignments/by-ids', {
        params: { id_list: state.map(({ jobsIds }) => jobsIds).join(',') },
      });
      const jobsData = response.data.result;
      return jobsData.map((jobData) => {
        const job = Job.fromApi(jobData);
        return {
          candidateStatus: state.find(({ jobsIds }) =>
            jobsIds.includes(job.key),
          )?.candidateStatus,
          job,
        };
      });
    },
    { enabled: !!state?.length },
  );

  return jobs;
};
