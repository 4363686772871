import React, { useMemo, useContext, useCallback, createContext } from 'react';
import axios from 'axios';
import { useDocumentData } from 'react-firebase-hooks/firestore';

import { useAuth } from '../auth/AuthProvider';
import services from '../utils/services';

export const ApiContext = createContext({});

export const ApiProvider = ({ children, baseUrl }) => {
  const { isAdmin, isRecruiter, profile } = useAuth();
  const [tokens = {}] = useDocumentData(
    !!profile && services.get('db').doc('config/tokens'),
  );

  const token = useMemo(
    () => tokens?.[isAdmin || isRecruiter ? 'admin' : 'client'],
    [isAdmin, isRecruiter, tokens],
  );

  const apiClient = useMemo(() => {
    const config = {
      baseURL: baseUrl,
      headers: {
        'Content-Type': 'application/json',
      },
    };
    if (token) {
      config.headers['X-Api-Key'] = `${token}`;
    }
    return axios.create(config);
  }, [baseUrl, token]);

  const entityQueryFn = useCallback(
    ({ queryKey: [endpoint, id] }) =>
      apiClient.get(`${endpoint}/${id}`).then((r) => r.data),
    [apiClient],
  );
  const paginatedQueryFn = useCallback(
    ({ queryKey: [endpoint, params], pageParam = 0 }) =>
      apiClient
        .get(endpoint, {
          params: { offset: pageParam * 50, limit: 50, ...params },
        })
        .then((r) => r.data),
    [apiClient],
  );

  const contextValue = useMemo(() => {
    return {
      token,
      apiClient,
      entityQueryFn,
      paginatedQueryFn,
    };
  }, [token, entityQueryFn, paginatedQueryFn, apiClient]);

  return (
    <ApiContext.Provider value={contextValue}>{children}</ApiContext.Provider>
  );
};

export const useApi = () => useContext(ApiContext);

export const waitForToken = (WrappedComponent) => (props) => {
  const { token } = useApi();
  if (!token) {
    return null;
  }
  return <WrappedComponent {...props} />;
};
