import * as React from 'react';

export const Link = (props) => (
  <svg width={12} height={12} viewBox="0 0 12 12" fill="none" {...props}>
    <path
      d="M5 6.41521C5.23372 6.72582 5.5319 6.98283 5.87433 7.16881C6.21675 7.35479 6.5954 7.46538 6.9846 7.49309C7.3738 7.5208 7.76445 7.46497 8.13003 7.3294C8.49562 7.19383 8.82761 6.98169 9.10347 6.70736L10.7361 5.08431C11.2318 4.57413 11.5061 3.89083 11.4999 3.18156C11.4937 2.4723 11.2075 1.79384 10.703 1.2923C10.1985 0.790755 9.51598 0.506266 8.80251 0.500102C8.08904 0.493939 7.40168 0.766595 6.88847 1.25935L5.9524 2.18448"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7 5.58479C6.76628 5.27418 6.4681 5.01717 6.12567 4.83119C5.78325 4.64521 5.4046 4.53462 5.0154 4.50691C4.6262 4.4792 4.23555 4.53503 3.86997 4.6706C3.50438 4.80617 3.17239 5.01831 2.89653 5.29264L1.26385 6.91569C0.768178 7.42587 0.493903 8.10918 0.500103 8.81844C0.506303 9.5277 0.792481 10.2062 1.297 10.7077C1.80152 11.2092 2.48401 11.4937 3.19749 11.4999C3.91096 11.5061 4.59832 11.2334 5.11153 10.7407L6.04216 9.81552"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
