import { useState, useMemo, useCallback } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';

const defaultSnaps = { docs: [] };

export const usePaginationData = (query, options = { limit: 25 }) => {
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [nextSnaps, setNextSnaps] = useState([]);
  const [querySnaps = defaultSnaps, isLoading, error] = useCollection(
    query.limit(options.limit),
  );

  const snaps = useMemo(
    () => [...querySnaps.docs, ...nextSnaps],
    [querySnaps, nextSnaps],
  );

  const data = useMemo(
    () =>
      snaps.length
        ? snaps.map((snap) =>
            options.idField
              ? { ...snap.data(), [options.idField]: snap.id }
              : snap.data(),
          )
        : [],
    [options, snaps],
  );

  const loadMore = useCallback(async () => {
    setLoading(true);

    const newQuerySnaps = await query
      .startAfter(snaps[snaps.length - 1])
      .limit(options.limit)
      .get();

    if (newQuerySnaps.size) {
      setNextSnaps([...nextSnaps, ...newQuerySnaps.docs]);
      setHasMore(true);
    } else {
      setHasMore(false);
    }

    setLoading(false);
  }, [query, options, snaps, nextSnaps]);

  return [data, { loading: isLoading || loading, loadMore, hasMore }, error];
};
