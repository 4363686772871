import { Radio } from 'formik-antd';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useField } from 'formik';

import {
  REPORT_FEEDBACK_VALUE,
  REPORT_FEEDBACK_VALUE_OPTIONS,
} from '@yougig/shared/reports/constants';

import { Checkbox, Vetted, Warning, Fire } from '../../icons';

const Feedback = styled(Radio.Group)`
  padding: 0;
  border: 0;
  min-height: auto !important;
  align-items: center;
  display: inline-flex;
  &:not(:last-child) {
    margin-bottom: 16px;
  }
  .ant-radio-button-wrapper {
    background-color: transparent;
    flex: 0 0 auto;
    padding: 0;
    margin: 4px;
    border: 0;
    line-height: 1 !important;
    &.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
      box-shadow: none;
    }
    .ant-radio-button + span {
      display: flex;
    }
    svg {
      vertical-align: top;
    }
  }
`;

const FeedbackLabel = styled.div`
  font-size: 14px;
  line-height: 19px;
  margin-right: auto;
`;

const FeedbackDot = styled.div`
  width: 4px;
  height: 4px;
  background-color: ${(props) => props.color};
  margin-right: 6px;
  border-radius: 50%;
`;

const feedbackIcons = {
  [REPORT_FEEDBACK_VALUE.OK]: (props) => (
    <Vetted width={12} height={12} {...props} />
  ),
  [REPORT_FEEDBACK_VALUE.CONSERN]: (props) => <Warning {...props} />,
  [REPORT_FEEDBACK_VALUE.ALERT]: (props) => <Fire {...props} />,
};

export const FeedbackItem = ({ icons = false, readOnly = false, ...props }) => {
  const [{ value: fieldValue }] = useField(props.name);
  return (
    <Feedback optionType="default" {...props}>
      <FeedbackDot
        color={
          REPORT_FEEDBACK_VALUE_OPTIONS.get(fieldValue)?.color ||
          'var(--c_hint)'
        }
      />
      <FeedbackLabel>{props.label}</FeedbackLabel>
      {REPORT_FEEDBACK_VALUE_OPTIONS.filter((option) =>
        readOnly ? option.value === fieldValue : true,
      ).map(({ value, color }) => {
        const Icon = feedbackIcons[value];
        return (
          <Radio.Button value={value} key={value}>
            {icons ? (
              <Icon color={fieldValue === value ? color : 'var(--c_hint)'} />
            ) : (
              <Checkbox checked={fieldValue === value} color={color} />
            )}
          </Radio.Button>
        );
      })}
    </Feedback>
  );
};

FeedbackItem.propTypes = {
  name: PropTypes.string.isRequired,
  status: PropTypes.bool,
  readOnly: PropTypes.bool,
};
