import { useState } from 'react';
import { Button, Checkbox, Form, Typography } from 'antd';
import { useParams } from 'react-router-dom';

import { ArrowRight } from '@yougig/ui/icons';
import { COLORS } from '@yougig/shared/constants';
import { useApiJob } from '@yougig/shared/jobs/useApiJob';
import { useApplyForJob } from '@yougig/shared/jobs/useApplyForJob';
import { useDrawerContext } from '@yougig/ui/components/common/DrawerProvider';

export const ApplyDrawerScreeningQuestions = () => {
  const params = useParams();
  const [job] = useApiJob(params.jobId);
  const { closeDrawer, candidate, refresh } = useDrawerContext();
  const [isChecked, setIsChecked] = useState(false);

  const { apply } = useApplyForJob(job, candidate);

  return (
    <>
      <Typography.Title level={2}>Screening Questions</Typography.Title>
      <Typography.Paragraph>
        To make sure you get the job done, we've prepared a survey for you.
        Answering questions is a must. Without it, your CV won't be reviewed
      </Typography.Paragraph>
      <a href={job?.screeningUrl} target="_blank" rel="noreferrer">
        Go to questions <ArrowRight color={COLORS.PRIMARY} />
      </a>
      <Form>
        <Checkbox checked={isChecked} onChange={() => setIsChecked(!isChecked)}>
          I confirm that I passed the survey
        </Checkbox>
        <div className="modal-footer">
          <Button
            block
            type="primary"
            disabled={!isChecked}
            onClick={() => {
              apply();
              setIsChecked(false);
              closeDrawer();
              if (refresh) {
                refresh();
              }
            }}>
            Apply
          </Button>
        </div>
      </Form>
    </>
  );
};
