import { Button } from 'antd';

import { useDrawerContext } from '@yougig/ui/components/common/DrawerProvider';
import { ReportForm } from '@yougig/ui/components/reports/ReportForm';
import { REPORT_STATUS } from '@yougig/shared/reports/constants';
import { useAuth } from '@yougig/shared/auth/AuthProvider';

import { ReportTimesheet } from '../../../components/reports/ReportTimesheet';

/**
 * @typedef {Object} DrawerContext
 * @property {import('@yougig/shared/reports/Report').Report} report
 */

export const ReportDetails = () => {
  const { profile } = useAuth();
  const { report, setDrawerView } = useDrawerContext();

  return (
    <>
      <div className="drawer-section">
        <ReportForm
          report={report}
          title="Consultant report"
          subHeading={`${profile.firstName} ${profile.lastName} for ${report.businessName}`}
          statusType="label"
          onClose={(updatedReport) => {
            if (updatedReport.status === REPORT_STATUS.PENDING) {
              setDrawerView('reports', { report: null });
            } else {
              setDrawerView('report', { report: updatedReport });
            }
          }}
          renderButtons={(formik) => (
            <>
              {report.status === REPORT_STATUS.REQUESTED && (
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={formik.isSubmitting}
                  onClick={() =>
                    formik.setFieldValue('status', REPORT_STATUS.PENDING)
                  }>
                  Send Report
                </Button>
              )}
              {report.status === REPORT_STATUS.PENDING && (
                <Button
                  type="primary"
                  htmlType="submit"
                  disabled={formik.isSubmitting}
                  onClick={() =>
                    formik.setFieldValue('status', REPORT_STATUS.REQUESTED)
                  }>
                  Withdraw
                </Button>
              )}
            </>
          )}
        />
      </div>
      <ReportTimesheet report={report} />
    </>
  );
};
