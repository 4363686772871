export const Paperclip = (props) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M14.666 7.54223L8.04714 14.0819C7.23627 14.8831 6.13651 15.3332 4.98978 15.3332C3.84304 15.3332 2.74328 14.8831 1.93242 14.0819C1.12155 13.2808 0.666016 12.1942 0.666016 11.0611C0.666016 9.92813 1.12155 8.84152 1.93242 8.04036L8.55129 1.50067C9.09187 0.966562 9.82505 0.666504 10.5895 0.666504C11.354 0.666504 12.0872 0.966562 12.6278 1.50067C13.1683 2.03478 13.472 2.75918 13.472 3.51452C13.472 4.26987 13.1683 4.99427 12.6278 5.52838L6.00169 12.0681C5.73141 12.3351 5.36482 12.4852 4.98257 12.4852C4.60033 12.4852 4.23374 12.3351 3.96345 12.0681C3.69317 11.801 3.54132 11.4388 3.54132 11.0611C3.54132 10.6835 3.69317 10.3213 3.96345 10.0542L10.0782 4.01977"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
