import formatDate from 'date-fns/format';
import pick from 'lodash/pick';

import { Attachments } from '../common/Attachments';
import services from '../utils/services';
import { REPORT_STATUS, REPORT_TYPE } from './constants';

/**
 * @typedef ReportModel
 * @type {object}
 * @property {REPORT_TYPE} type Type of the report
 * @property {string} startDate Start date formated in YYYY-MM-DD
 * @property {string} endDate End date formated in YYYY-MM-DD
 * @property {string} status status of the report
 */

/**
 * Describes Report model, access to it's properties and operations on it.
 * @augments ReportModel
 */
export class Report {
  /**
   * Report Document data.
   * @property {ReportModel} _data
   * @private
   */
  _data;

  _id;
  _ref;

  isDraft = true;

  /**
   *
   * @param {Partial<ReportModel>} data
   * @returns
   */
  constructor(data = {}) {
    this._data = data;

    return new Proxy(this, {
      get(report, fieldName) {
        return fieldName in report
          ? report[fieldName]
          : report._data?.[fieldName];
      },
    });
  }

  static converter = {
    toFirestore(data) {
      return data instanceof Report ? data._data : data;
    },
    fromFirestore(snapshot) {
      return Report.fromSnapshot(snapshot);
    },
  };

  /**
   * Creates new Report instance from Firestore DocumentSnapshot.
   * @param {DocumentSnapshot} snap Firestore DocumentSnapshot.
   * @return {Report}
   */
  static fromSnapshot(snap) {
    const { startDate, endDate, ...data } = snap.data();
    const report = new Report({
      startDate:
        startDate instanceof services.get('firestore').Timestamp
          ? startDate.toDate()
          : new Date(startDate),
      endDate:
        endDate instanceof services.get('firestore').Timestamp
          ? endDate.toDate()
          : new Date(endDate),
      ...data,
    });
    report.id = snap.id;
    report.ref = snap.ref;
    report.isDraft = false;

    return report;
  }

  get id() {
    return this._id;
  }

  set id(value) {
    this._id = value;
  }

  get ref() {
    return this._ref;
  }

  set ref(value) {
    this._ref = value.withConverter(Report.converter);
  }

  /**
   * Returns collection query for time entries, imported from clockify.
   * @returns {CollectionQuery|null}
   */
  getTimeEntriesRawQuery() {
    if (this.type === REPORT_TYPE.TALENT && this._ref) {
      return this._ref.collection('time_entries_raw');
    }
    return null;
  }

  /**
   * Returns collection query for time entries extensions, made by manager.
   * @returns {CollectionQuery|null}
   */
  getTimeEntriesExtQuery() {
    if (this.type === REPORT_TYPE.TALENT && this._ref) {
      return this._ref.collection('time_entries_ext');
    }
    return null;
  }

  getFormValues() {
    const emptyValues = {
      outcomingFeedback: {},
      outcomingMessage: '',
      outcomingAttachments: [],
      incomingFeedback: {},
      incomingMessage: '',
      incomingAttachments: [],
    };
    const editableFields = Object.keys(emptyValues).concat([
      'status',
      'startDate',
      'endDate',
    ]);
    const initialValues = Object.assign(
      emptyValues,
      pick(this._data, editableFields),
    );
    if (typeof initialValues.startDate === 'string') {
      initialValues.startDate = new Date(initialValues.startDate);
    }
    if (typeof initialValues.endDate === 'string') {
      initialValues.endDate = new Date(initialValues.endDate);
    }
    return initialValues;
  }

  /**
   * Transforms and validates payload data before saving into DB.
   * @param {Partial<ReportModel>} payload Payload to write into DB.
   * @returns {Promise<Partial<ReportModel>>}
   */
  async transformPayload({ startDate, endDate, ...payload }) {
    if (startDate) {
      payload.startDate =
        startDate instanceof Date
          ? formatDate(startDate, 'yyyy-MM-dd')
          : startDate;
    }
    if (endDate) {
      payload.endDate =
        endDate instanceof Date ? formatDate(endDate, 'yyyy-MM-dd') : endDate;
    }
    payload.type = payload.type || this._data.type || REPORT_TYPE.CLIENT;
    payload.status = payload.status || this._data.status || REPORT_STATUS.DRAFT;

    const handleAttachments = async (fieldName) => {
      const files = payload[fieldName];
      if (typeof files === 'undefined') {
        return;
      }
      const att = new Attachments(files, `/reports/${this._id}/${fieldName}`);
      await att.uploadNew();
      if (!this.isDraft) {
        await att.removeOrphaned(this._data[fieldName]);
      }
      const dbValue = att.toFirestore();
      if (!dbValue.length && (this.isDraft || !this._data[fieldName]?.length)) {
        delete payload[fieldName];
        return;
      }
      payload[fieldName] = dbValue;
    };

    await handleAttachments('outcomingAttachments');
    await handleAttachments('incomingAttachments');

    return payload;
  }

  /**
   * Creates or updates a report with provided payload.
   * @param {Partial<ReportModel>} values Payload to write into DB
   * @returns {Promise<Report>} Self-return
   */
  async save(values) {
    if (this.isDraft) {
      if (!values.client) {
        throw new Error('Missing client reference for new report');
      }
      if (values.type === REPORT_TYPE.TALENT && !values.consultant) {
        throw new Error('Missing consultant reference for new report');
      }

      this.ref = services.get('db').collection('reports').doc();
      this.id = this.ref.id;
    }

    const payload = await this.transformPayload(values);

    if (this.isDraft) {
      await this._ref.withConverter(null).set(payload);
      this._data = payload;
      this.isDraft = false;
    } else {
      await this._ref.withConverter(null).update(payload);
      this._data = Object.assign(this._data, payload);
    }
    return this;
  }

  /**
   * Creates a sub-report for the current one
   * @param {DocumentReference} consultant DocumentReference for consultant's profile
   * @returns {Promise<Report>}
   */
  generateConsultantReport(consultant) {
    if (this.type !== REPORT_TYPE.CLIENT) {
      throw new Error(
        'Consultant report can be generated only for client report. Please, check your implementation.',
      );
    }
    if (this.isDraft) {
      throw new Error(
        'Child report can be created only for already stored report. Please, save the report first.',
      );
    }

    const report = new Report();
    const payload = {
      type: REPORT_TYPE.TALENT,
      consultant,
      client: this.client,
      startDate: this.startDate,
      endDate: this.endDate,
      parent: this._ref,
      status: REPORT_STATUS.REQUESTED,
    };

    return report.save(payload);
  }
}
