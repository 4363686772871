import isUndefined from 'lodash/isUndefined';

import services from '../utils/services';
import { CANDIDATE_STATUS } from './constants';
import { Client } from '../clients/Client';
import { Profile } from '../talents/Profile';

export class Candidate {
  static requiredFields = ['client', 'approvedBy', 'consultant', 'timestamp'];
  static optionalFields = ['name', 'saved', 'status', 'reason', 'notes'];
  static fields = [...Candidate.requiredFields, ...Candidate.optionalFields];

  static converter = {
    toFirestore(candidate) {
      const emptyField = Candidate.requiredFields.find((f) =>
        isUndefined(candidate[f]),
      );
      if (emptyField) {
        throw new Error(`Missing value for required '${emptyField}'`);
      }
      return Candidate.fields.reduce((acc, fieldName) => {
        if (!isUndefined(candidate[fieldName])) {
          acc[fieldName] = candidate[fieldName];
        }
        return acc;
      }, {});
    },
    fromFirestore(snapshot) {
      return Candidate.fromSnapshot(snapshot);
    },
  };

  constructor(data) {
    Object.entries(data).forEach(([key, value]) => {
      this[key] =
        value instanceof services.get('firestore').Timestamp
          ? value.toDate()
          : value;
    });
  }

  /**
   * Returns the candidates query.
   */
  static getCandidatesQuery() {
    return services
      .get('db')
      .collection('candidates')
      .withConverter(Candidate.converter);
  }

  /**
   * Creates new Candidate instance from the firestore's DocumentSnapshot.
   * @param {DocumentSnapshot} snapshot Firestore DocumentSnapshot of the candidate.
   */
  static fromSnapshot(snapshot) {
    const candidate = new Candidate(snapshot.data());
    candidate.id = snapshot.id;
    candidate.__snapshot = snapshot;
    candidate.__ref = snapshot.ref;
    candidate.consultant = candidate.consultant?.withConverter(
      Profile.converter,
    );
    return candidate;
  }

  /**
   * Creates Candidate instance from data received from API.
   * @param {object} payload Candidate object received from API.
   */
  static fromApi(payload) {
    const candidate = new Candidate({});
    candidate.extendWithApi(payload);
    return candidate;
  }

  get isDraft() {
    return !!this.__draft || !this.__ref;
  }

  /**
   * Extends Candidate instance with data from API.
   * @param {object} payload Candidate object received from API.
   */
  extendWithApi(payload) {
    this.name = this.name || payload.name;
    if (!this.consultant && payload.external_id) {
      if (this.id) {
        console.warn(`Candidate ${this.name} does't have profile?!`, this);
      }
      this.consultant = services
        .get('db')
        .doc(`profiles/${payload.external_id}`)
        .withConverter(Profile.converter);
    }
  }

  /**
   * Returns the assignments query for the candidate.
   */
  getAssignmentsQuery() {
    if (!this.__ref) {
      return null;
    }

    return this.__ref.collection('assignments');
  }

  /**
   * Updates candidate values based on changes and/or context values;
   * @param {Object} context Context data to update interview references and cache data.
   */
  async applyContext(context) {
    if (!this.client) {
      if (context.position) {
        const request = context.position;
        if (request.client) {
          this.client = request.client;
        } else if (request.external_id) {
          // assignment from blackbox might have reference to yougig's request
          // (stored in firebase) — try to find it.
          const requestSnap = await services
            .get('db')
            .doc(`jobs/${request.external_id}`)
            .get();
          if (requestSnap.exists) {
            this.client = requestSnap.get('client');
          }
        }
        // in case there is still no client, try to find by company_name or create a new
        // (this is only for blackbox's assignments)
        if (!this.client) {
          this.client = await Client.fromExternalAssignment(request);
        }
      } else if (context.client) {
        this.client = context.client;
      } else if (context.user?.tenant) {
        this.client = context.user.tenant;
      }
    }
    if (context.profile && !this.consultant) {
      if (context.profile.isDraft) {
        await context.profile.save();
      }
      this.consultant = context.profile.ref;
    }
    this.approvedBy = context.userRef;
  }

  /**
   * Ensures that there is an assignment for the position.
   * @param {Object} position Position object
   */
  async applyPosition(position, assigmentData = {}) {
    assigmentData.client = this.client || null;
    assigmentData.timestamp = services.get('now')();

    if (!position.client) {
      position.firebaseData.client = this.client;
    }

    position.isDraft && (await position.save());

    assigmentData.request = position.ref;

    await this.__ref
      .collection('assignments')
      .doc(position.id)
      .set(assigmentData, { merge: true });
  }

  /**
   * Saves candidate into Firestore.
   */
  async save() {
    this.timestamp = services.get('now')();

    if (this.id) {
      await this.__ref.withConverter(Candidate.converter).set(this);
    } else {
      this.__ref = await services
        .get('db')
        .collection('candidates')
        .withConverter(Candidate.converter)
        .add(this);
      this.id = this.__ref.id;
    }
    delete this.__draft;
  }

  /**
   * Remove candidate and it's assignments from Firestore.
   */
  async remove() {
    const assignments = await this.getAssignmentsQuery().get();
    assignments.forEach((assignment) => {
      assignment.ref.delete();
    });
    await this.__ref.delete();
  }

  /**
   * Applies context, saves candidates and adds assignment if provided
   * @param {Object} context Context object
   */
  async applyContextAndSave(context) {
    await this.applyContext(context);
    if (context.position) {
      if (!this.id) {
        // Create candidate doc reference to save assignment before candidate creation.
        // This is needed for the cases when we are listening for candidates connections first
        // and then loading assignments for them to not loose data and correctly update it.
        this.__ref = services.get('db').collection('candidates').doc();
        this.id = this.__ref.id;
        this.__draft = true;
      }
      try {
        await this.applyPosition(context.position, context.assignmentData);
      } catch (err) {
        console.error('Cannot save assignment for candidate', this.id);
        if (this.__draft) {
          delete this.__ref;
          delete this.id;
          delete this.__draft;
        }
      }
    }

    await this.save();
  }

  /**
   * Change candidate's status, apply context and save.
   * @param {string} status New status value
   * @param {Object} context Context object
   * @returns {Promise}
   */
  setStatusAndSave(status, context) {
    this.status = status;
    return this.applyContextAndSave(context);
  }

  /**
   * Just change the status to Interview, without assigning actual interview.
   * @param {Object} context Context data (`client`, `position`)
   */
  async interview(context) {
    this.status = CANDIDATE_STATUS.INTERVIEW;
    await this.applyContextAndSave(context);
  }

  /**
   * Mark candidate as accepted.
   * @context {Object} context Context data to update cache
   */
  async accept(context) {
    this.status = CANDIDATE_STATUS.ACCEPTED;
    await this.applyContextAndSave(context);
  }

  /**
   * Mark candidate as archived.
   * @param {object} data Data submitted with archive request
   * @param {object} context Context data
   */
  async archive({ reason, notes }, context) {
    this.status = CANDIDATE_STATUS.DISMISSED;
    this.reason = reason;
    this.notes = notes;

    await this.applyContextAndSave(context);
  }

  /**
   * Remove candidate's assignment by id.
   * @param {string} id
   */
  async removeAssignment(id) {
    await this.getAssignmentsQuery().doc(id).delete();
  }

  /**
   * Remove candidate's assignments which are not in the given list.
   * @param {Array} assignments Assignments to filter by.
   */
  async removeMissingAssignments(assignments) {
    const ids = assignments.map(({ key }) => key);
    const snaps = await this.getAssignmentsQuery().get();
    for await (const snap of snaps.docs) {
      if (!ids.includes(snap.id)) {
        await snap.ref.delete();
      }
    }
  }

  /**
   * Add assignments to candidate.
   * @param {Array} assignments Assignments to add
   */
  async addAssignments(assignments) {
    for await (const job of assignments) {
      await this.applyPosition(job);
    }
  }

  /**
   * Mark candidate as suggested.
   * @context {Object} context Context data to update cache
   */
  async suggest(suggestData, context) {
    this.status = CANDIDATE_STATUS.SUGGESTED;
    await this.applyContext(context);
    await this.save();

    if (context.position) {
      await (suggestData.label
        ? this.applyPosition(context.position, suggestData)
        : this.applyPosition(context.position));
    }
  }

  /**
   * Mark candidate as requested.
   * @param {Object} context Context data to update cache
   */
  async request(context) {
    this.status = CANDIDATE_STATUS.REQUESTED;

    await this.applyContextAndSave(context);
  }

  /**
   * Preselect candidate for the blackbox's position.
   * @param {Object} context Context with following data:
   *   — userRef {Object} Logged in user DocumentReference.
   *   - position {Object} Blackbox assignment object.
   */
  async preSelect(context) {
    this.status = CANDIDATE_STATUS.PRESELECTED;

    await this.applyContextAndSave(context);
  }

  /**
   * Method to allow a candidate to apply to a position himself (using Yougig Talant app).
   * @param {Object} context
   */
  async apply(context) {
    if (!this.status || this.status === CANDIDATE_STATUS.ARCHIVED) {
      this.status = CANDIDATE_STATUS.SELF_APPLIED;
    }
    await this.applyContextAndSave({
      ...context,
      assignmentData: { applied: true },
    });
  }

  /**
   * Cancel assignment (apply) for a job by talent.
   * @param {Object} context Context object
   */
  async cancelAssignment(context) {
    this.status = CANDIDATE_STATUS.ARCHIVED;
    await this.applyContextAndSave({
      ...context,
      assignmentData: { applied: false },
    });
  }

  async updateAssignment(id, values) {
    const assignmentRef = await this.__ref.collection('assignments').doc(id);
    await assignmentRef.update(values);
  }

  /**
   * Select candidate for the blackbox's position.
   * @param {Object} context Context with following data:
   *   — userRef {Object} Logged in user DocumentReference.
   *   - position {Object} Blackbox assignment object.
   */
  async select(context) {
    this.status = CANDIDATE_STATUS.SELECTED;

    await this.applyContextAndSave(context);
  }

  /**
   *
   * @param {Object} context Context data to update cache
   */
  async toggleSaved(context) {
    this.saved = !this.saved;
    await this.applyContext(context);
    return this.save();
  }
}
