import { useEffect, useState } from 'react';

import { Profile } from '@yougig/shared/talents/Profile';

import { useApi } from '../api/Api';
import { Candidate } from './Candidate';

async function* getCandidatesFromApi(apiClient, candidateIds) {
  let offset = 0;
  while (offset < candidateIds.length) {
    try {
      const response = await apiClient.get('/candidates/by-ids', {
        params: {
          id_list: candidateIds.slice(offset, offset + 100).join(','),
          limit: 100,
        },
      });
      yield response.data.result;
    } catch (err) {
      yield [];
    } finally {
      offset += 100;
    }
  }
  return;
}

/**
 * @typedef {Object} CandidateDataItem
 * @prop {import('@yougig/shared/candidates/Candidate').Candidate} candidate Candidate instance
 * @prop {import('@yougig/shared/talents/Profile').Profile} profile Profile instance
 * @prop {string} key Key for the item — either Candidate ID or Profile ID.
 */

/**
 *
 * @param {Firebase.Query} query Query for candidates collection.
 * @returns {[Array<CandidateDataItem>, boolean, undefined | string]}
 */

export const useCandidatesData = (query) => {
  const { apiClient } = useApi();
  const [data, setData] = useState([]);

  const [isLoading, setIsLoading] = useState(!!query);
  const [error, setError] = useState();

  useEffect(() => {
    if (!query) {
      return;
    }
    return query
      .withConverter(Candidate.converter)
      .onSnapshot(async (snaps) => {
        if (snaps && snaps.size) {
          try {
            const profileIds = snaps.docs
              .map((snap) => snap.get('consultant')?.id)
              .filter(Boolean);

            let candidatesData = [];
            const gen = getCandidatesFromApi(apiClient, profileIds);
            while (candidatesData.length < Infinity) {
              const { value, done } = await gen.next();
              if (done) {
                break;
              }

              candidatesData = candidatesData.concat(value);
            }

            const candidates = await Promise.all(
              snaps.docs.map(async (docSnap) => {
                /**
                 * @type {Candidate}
                 */
                const candidate = docSnap.data();
                if (!candidate.consultant) {
                  console.warn(
                    `Missing consultant reference for ${candidate.id}`,
                  );
                  return null;
                }

                const details = candidatesData.find(
                  (c) => c.external_id === candidate.consultant?.id,
                );
                let profile = new Profile();
                if (details) {
                  candidate.extendWithApi(details);
                  profile = Profile.fromApi(details);
                }
                if (!profile.ref) {
                  profile._firebaseData = profile._firebaseData || {};
                  profile._firebaseData.firstName = candidate.name || '';
                  profile._id = candidate.consultant.id;
                  profile._ref = candidate.consultant;
                }
                return { candidate, profile, key: candidate.id || profile.key };
              }),
            );

            setData(candidates.filter(Boolean));
          } catch (err) {
            console.log(err);
            setError(err);
          }
        }
        setIsLoading(false);
      });
  }, [apiClient, query]);

  return [data, isLoading, error, setData];
};
