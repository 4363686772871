import { useMemo } from 'react';

import {
  baseColumns,
  noRenderCell,
} from '@yougig/ui/components/talents/baseColumns';
import { TEAM_PEOPLE_GROUPS } from '@yougig/shared/teams/constants';
import { useDrawerContext } from '@yougig/ui/components/common/DrawerProvider';
import { TalentTable } from '@yougig/ui/components/talents/TalentTable';
import countries from '@yougig/ui/utils/countries.json';

import { PeopleTableActions } from './PeopleTableActions';

export const columns = [
  baseColumns.candidate,
  {
    title: 'Location',
    className: 'cell-location',
    key: 'countryCode',
    render: (_, { profile, groupTitle }) => {
      if (groupTitle) {
        return noRenderCell;
      }

      return (
        <span
          title={
            countries.find((c) => c.cca2 === profile.countryCode)?.name ||
            profile.countryCode
          }>
          {profile.countryCode}
        </span>
      );
    },
  },
  baseColumns.occupation,
  baseColumns.scoring,
  {
    title: 'Rate',
    className: 'cell-rate',
    key: 'internalRate',
    render: (_, { profile, groupTitle }) => {
      if (groupTitle) {
        return noRenderCell;
      }

      return profile.internalRate ? `$${profile.internalRate}/h` : null;
    },
  },
  baseColumns.availability,
  baseColumns.email,
  {
    title: 'Actions',
    className: 'cell-actions-stat',
    key: 'actions',
    render: (_, { profile, groupTitle }) => {
      if (groupTitle) {
        return noRenderCell;
      }
      const applied = profile.manager_weight?.[2];
      return applied ? `${applied} applied job${applied > 1 ? 's' : ''}` : null;
    },
  },
  {
    title: '',
    className: 'actions',
    key: 'actions',
    render: (_, rowData) => {
      if (rowData.groupTitle) {
        return noRenderCell;
      }
      return <PeopleTableActions profile={rowData.profile} />;
    },
  },
];

const DEFAULT_EXPANDED_ROWS = Object.values(TEAM_PEOPLE_GROUPS).filter(
  (v) => v !== TEAM_PEOPLE_GROUPS.REMOVED,
);

export const PeopleTable = ({ memberGroups }) => {
  const { entryKey, setDrawerView } = useDrawerContext();

  const tranformedGroups = useMemo(
    () => memberGroups.map(({ data, ...g }) => ({ ...g, children: data })),
    [memberGroups],
  );

  return (
    <TalentTable
      expandable
      dataSource={tranformedGroups}
      selectedRowKey={entryKey}
      columns={columns}
      onTalentSelect={(row) =>
        setDrawerView('profile', { profile: row.profile, entryKey: row.key })
      }
      rowKey={(r) => r.key}
      defaultExpandedRowKeys={DEFAULT_EXPANDED_ROWS}
    />
  );
};
