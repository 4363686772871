import { Button, Typography, Row } from 'antd';
import styled from 'styled-components';

import { useDrawerContext } from '@yougig/ui/components/common/DrawerProvider';

import { OverviewDrawer } from './OverviewDrawer';

const SectionTitle = styled(Typography.Title)`
  &.ant-typography {
    font-size: 24px;
    font-weight: normal;
    margin-bottom: 16px;
  }
`;

const List = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
  grid-gap: 20px;
  font-size: 14px;
  &:not(:last-child) {
    margin-bottom: 32px;
  }
`;

export const OverviewCardList = ({ title, showAllViewMode, children }) => {
  const { setDrawerView } = useDrawerContext();

  return (
    <>
      <Row>
        <SectionTitle level={2}>{title}</SectionTitle>
        <Button
          type="link"
          className="ml-auto"
          onClick={() => setDrawerView(showAllViewMode)}>
          show all
        </Button>
      </Row>
      <List>{children}</List>
      <OverviewDrawer />
    </>
  );
};
