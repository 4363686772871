import { Button } from 'antd';
import { Form } from 'formik-antd';
import { Formik } from 'formik';
import styled from 'styled-components';

import { array, object, string } from '@yougig/shared/utils/validation';
import { Attachments } from '@yougig/shared/common/Attachments';

import { TextAreaWithUpload } from '../form/TextAreaWithUpload';

const FormLayout = styled(Form)`
  position: relative;
  .ant-upload-list-text {
    padding-right: 85px;
    min-height: 30px;
  }
`;
const SendButton = styled(Button)`
  &.ant-btn,
  &.ant-btn-loading {
    position: absolute;
    right: 0;
    bottom: 3px;
  }
`;

const messageSchema = object({
  message: string(),
  attachments: array().nullable(),
});

export const MessageForm = ({ onSendMessage }) => {
  return (
    <Formik
      initialValues={{ message: '', attachments: [] }}
      validationSchema={messageSchema}
      onSubmit={async (
        { message, attachments },
        { setSubmitting, setFieldError, resetForm },
      ) => {
        setSubmitting(true);
        try {
          await onSendMessage({
            message,
            attachments: attachments.map(Attachments.fromRcUpload),
          });
          setSubmitting(false);
          resetForm();
        } catch (err) {
          console.error(err);
          setFieldError('message', err.message);
        }
      }}>
      {({ isSubmitting, submitForm }) => (
        <FormLayout layout="vertical">
          <TextAreaWithUpload
            label="Message"
            textAreaProps={{
              onKeyDown: (e) => {
                if (
                  e.key === 'Enter' &&
                  (e.metaKey || e.ctrlKey) &&
                  !isSubmitting
                ) {
                  submitForm();
                }
              },
              placeholder: 'Write your message',
            }}
          />
          <SendButton loading={isSubmitting} type="link" htmlType="submit">
            Send
          </SendButton>
        </FormLayout>
      )}
    </Formik>
  );
};
