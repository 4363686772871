import { useEffect, useMemo, useState } from 'react';
import {
  useCollectionData,
  useDocumentData,
} from 'react-firebase-hooks/firestore';

import { useApi } from '../api/Api';
import services from '../utils/services';
import { Candidate } from './Candidate';
import { Profile } from '../talents/Profile';

export const useTalentProfile = (user) => {
  return useDocumentData(
    user?.profile && user?.profile.withConverter(Profile.converter),
  );
};

export const useTalentAssignments = (profile) => {
  const { apiClient } = useApi();
  const candidatesQuery = useMemo(
    () =>
      profile?.ref &&
      services
        .get('db')
        .collection('candidates')
        .where('consultant', '==', profile.ref)
        .withConverter(Candidate.converter),
    [profile],
  );

  const [candidates] = useCollectionData(candidatesQuery);

  const [assignments, setAssignments] = useState([]);
  useEffect(() => {
    if (!candidates?.length) {
      return;
    }
    Promise.all(
      candidates.map(async (candidate) => {
        const jobsSnaps = await candidate.getAssignmentsQuery().get();
        return await Promise.all(
          jobsSnaps.docs.map(async (jobSnap) => {
            const {
              client: clientRef,
              request: requestRef,
              ...data
            } = jobSnap.data();
            if (!clientRef) {
              return data;
            }
            const clientDoc = await clientRef.get();
            let request = {};
            if (typeof requestRef === 'string') {
              const response = await apiClient.get(
                `/assignments/${requestRef}`,
              );
              request.jobId = response.data.id;
              request.jobName = response.data.title;
            } else {
              const requestSnap = await requestRef.get();
              request.jobId = requestSnap.id;
              request.jobName = requestSnap.get('name');
            }
            return {
              businessName: clientDoc.get('businessName'),
              ...request,
              ...data,
              candidate,
            };
          }),
        );
      }),
    ).then((data) => setAssignments(data.flat()));
  }, [apiClient, candidates, setAssignments]);

  return { candidates, assignments };
};
