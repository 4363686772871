import styled from 'styled-components';
import { Layout } from 'antd';
import { forwardRef, useCallback, useImperativeHandle, useState } from 'react';

import { useAuth } from '@yougig/shared/auth/AuthProvider';

import { Close, Hamburger } from '../../icons';

const HeaderBar = styled(Layout.Header)`
  width: 100%;
  height: 82px;
  font-size: 18px;
  font-weight: 600;
  background-color: #fff;
  box-shadow: 0px 5px 10px rgba(108, 109, 190, 0.1);
  padding: 0 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: inherit;
  position: relative;
  z-index: 10;
  transition: height 0.4s;

  body.hub71-theme & {
    font-weight: normal;
  }

  // container width (1270) - container paddings (32) + header paddings (100) = 1338
  @media (max-width: 1338px) {
    padding: 0 16px;
  }

  @media (max-width: 767px) {
    max-height: -webkit-fill-available;
    height: 64px;
    min-height: 64px;
    width: 100vw;
    overflow: hidden;
    position: fixed;
    flex-direction: column;
    align-items: flex-start;
    z-index: 50;

    ${(props) => {
      if (props.open) {
        return `
          height: 100vh;
          overflow: auto;
          padding-bottom: 40px;
          border-radius: 0px 0px 20px 20px;
        `;
      }
    }}
  }
`;

const HeaderMobile = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 767px) {
    width: 100%;
    flex: 0 0 64px;
    min-height: 64px;
  }
`;

const HeaderMobileMenu = styled.div`
  margin-left: auto;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
  display: flex;
  align-items: center;

  @media (max-width: 767px) {
    flex-direction: column;
    align-items: stretch;
    width: 100%;
  }
`;

const HeaderMenu = styled.div`
  flex: 1;
  display: flex;

  .menu {
    display: flex;
    flex: 1;
    list-style: none;
    margin-bottom: 0;
    margin-right: 24px;
    margin-left: 24px;
    padding: 0;
    align-items: center;
    justify-content: flex-end;

    @media (max-width: 767px) {
      margin-top: 5px;
      margin-right: 0;
      margin-left: 0;
      flex: 0 0 auto;
      flex-direction: column;
    }
  }

  .menu-item {
    margin: 0 24px;
    line-height: 36px;
    text-align: center;
    position: relative;

    & > a,
    & > span {
      font-size: 18px;
    }

    > .menu {
      display: none;

      & .menu-item {
        padding: 15px 33px;
        text-align: left;
        margin: 0;
        font-weight: normal;
      }
    }

    &__dropdown {
      span {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
      svg {
        margin-left: 10px;
        transition: all 0.3s;
      }

      @media (min-width: 768px) {
        &:hover {
          svg {
            transform: rotate(-180deg);
          }
        }
      }
    }

    @media (min-width: 768px) {
      .menu {
        display: block;
        position: absolute;
        left: -33px;
        top: 100%;
        z-index: 2;
        background-color: #fff;
        box-shadow: 0px 10px 20px rgba(108, 109, 190, 0.3);
        border-radius: 14px;
        margin: 0;
        min-width: 285px;
        opacity: 0;
        pointer-events: none;
        transition: all 0.3s;
      }

      &:hover > .menu {
        opacity: 1;
        pointer-events: auto;
      }
    }

    @media (max-width: 767px) {
      width: 100%;
      line-height: 25px;
      text-align: left;

      &__expanded {
        & > span svg {
          transform: rotate(-180deg);
        }
        & > .menu {
          display: block;

          .menu-item {
            margin: 24px 0;
            padding: 0 0 0 32px;

            &:last-child {
              margin-bottom: 0;
            }

            a,
            span {
              font-size: 18px;
            }
          }
        }
      }
    }

    @media (max-width: 1024px) {
      margin: 0 10px;
    }

    @media (max-width: 767px) {
      margin: 16px 0;
    }

    &:first-child {
      margin-left: 0;

      @media (max-width: 767px) {
        margin-right: 0;
      }
    }
    &:last-child {
      margin-right: 0;

      @media (max-width: 767px) {
        margin-left: 0;
      }
    }

    a {
      transition: all 0.3s;
      color: var(--c_text);
      text-decoration: none;

      &:hover {
        color: var(--c_dark_violet);
      }
    }

    a.active,
    &.active a {
      cursor: default;
      color: var(--c_deep_pink);

      @media (min-width: 768px) {
        text-decoration: underline;

        body.hub71-theme & {
          text-decoration-color: var(--c_deep_pink);
        }
      }
    }

    body.hub71-theme & {
      font-family: var(--ff_heading);

      a {
        text-decoration: underline transparent;

        &:hover {
          color: var(--c_deep_pink);
          text-decoration-color: var(--c_deep_pink);
        }
      }
    }
  }

  @media (max-width: 767px) {
    margin: 0 0 40px;
    padding-bottom: 24px;
    border-bottom: 1px solid var(--c_light_grey);
  }
`;

const HeaderMenuToggle = styled.button`
  padding: 0;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 768px) {
    display: none;
  }
`;

const Logo = styled.div`
  height: 44px;
  padding: 0;
  display: flex;
  align-items: center;

  img {
    max-height: 100%;
  }

  @media (max-width: 767px) {
    padding-left: 28px;
    flex-basis: 0;
    flex-grow: 1;
    justify-content: center;
    height: 31px;

    body.hub71-theme & {
      padding-left: 0;
      justify-content: flex-start;
    }
  }
`;

const AuthButtons = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 768px) {
    margin-left: 28px;
  }

  .ant-btn {
    line-height: 1.2;
    border-radius: 100px;
    min-width: 140px;
    padding: 11px 32px !important;

    &.ant-btn-ghost {
      margin-right: 20px;
    }
  }
`;

export const HeaderLayout = forwardRef(
  ({ children, logo, userMenu, authButtons }, ref) => {
    const [isOpen, setOpen] = useState(false);

    const openMobileMenu = useCallback(() => {
      document.body.style.overflow = 'hidden';
      setOpen(true);
    }, [setOpen]);

    const closeMobileMenu = useCallback(() => {
      document.body.style.overflow = 'unset';
      setOpen(false);
    }, [setOpen]);

    useImperativeHandle(ref, () => ({ openMobileMenu, closeMobileMenu }), [
      openMobileMenu,
      closeMobileMenu,
    ]);

    const { isAuthenticated } = useAuth();

    return (
      <>
        <HeaderBar open={isOpen}>
          <HeaderMobile>
            <Logo>{logo}</Logo>
            <HeaderMenuToggle
              onClick={() => (isOpen ? closeMobileMenu() : openMobileMenu())}>
              {isOpen ? <Close /> : <Hamburger />}
            </HeaderMenuToggle>
          </HeaderMobile>
          <HeaderMobileMenu>
            <HeaderMenu>{children}</HeaderMenu>

            {isAuthenticated
              ? userMenu
              : authButtons && <AuthButtons>{authButtons}</AuthButtons>}
          </HeaderMobileMenu>
        </HeaderBar>
      </>
    );
  },
);
