import styled from 'styled-components';
import formatDate from 'date-fns/format';

import { AttachmentsList } from '../elements/AttachmentsList';

const MessageWrapper = styled.div`
  padding: 16px;
  font-size: 14px;
  line-height: 19px;
  border-radius: 10px;
  background-color: var(
    ${({ outcoming }) => (outcoming ? '--c_light_green' : '--c_light_grey')}
  );
  min-width: calc(50% + 1px);
  position: relative;
  &:after {
    position: absolute;
    content: '';
    bottom: 0;
    border: 8px solid
      var(
        ${({ outcoming }) => (outcoming ? '--c_light_green' : '--c_light_grey')}
      );
    border-top-color: transparent;
  }
  ${({ outcoming }) =>
    outcoming
      ? `
        margin-right: 16px;
        margin-left: auto;
        border-bottom-right-radius: 0;
        &:after {
          right: -16px;
          border-top-color: transparent;
          border-right-color: transparent;
        }
      `
      : `
        margin-left: 16px;
        margin-right: auto;
        border-bottom-left-radius: 0;
        &:after {
          left: -16px;
          border-left-color: transparent;
        }
      `}
`;

const MessageName = styled.div`
  margin-bottom: 16px;
  color: var(--c_description);
`;

const MessageText = styled.div`
  white-space: pre-wrap;
`;

const MessageTime = styled.div`
  text-align: right;
  font-size: 12px;
  line-height: 16px;
  margin-top: 8px;
  color: var(--c_description);
`;

const MessageAttachments = styled.div`
  margin-top: 8px;
  a {
    color: var(--c_violet);
  }
`;

export const Message = ({
  authorName,
  message,
  attachments = [],
  dateTime,
  outcoming,
  className,
}) => {
  return (
    <MessageWrapper outcoming={outcoming} className={className}>
      <MessageName>{authorName}</MessageName>
      <MessageText>{message}</MessageText>
      {!!attachments.length && (
        <MessageAttachments>
          <AttachmentsList items={attachments} />
        </MessageAttachments>
      )}
      <MessageTime>{formatDate(dateTime, 'HH:mm')}</MessageTime>
    </MessageWrapper>
  );
};

Message.Date = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: var(--c_description);
  min-width: 100%;
  text-align: center;
  position: sticky;
  top: 0;
  background-color: #fff;
  padding: 8px 0;
  margin-top: -8px;
  margin-bottom: -8px;
  z-index: 1;

  svg {
    margin-left: 4px;
  }
`;
