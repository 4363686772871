import { memo } from 'react';
import { Button } from 'antd';
import { useField, FieldArray } from 'formik';
import { Form, Input } from 'formik-antd';

export const LinksField = memo(({ name }) => {
  const [{ value }] = useField(name);

  return (
    <FieldArray
      name={name}
      render={(helpers) => (
        <>
          {Array.isArray(value) &&
            value.map((_, index) => (
              <div className="row" key={index}>
                <Form.Item
                  name={`${name}[${index}].url`}
                  label="URL"
                  className="col-6">
                  <Input
                    name={`${name}[${index}].url`}
                    placeholder="LinkedIn, Upwork, Dribble, etc"
                  />
                </Form.Item>
                <Form.Item
                  name={`${name}[${index}].notes`}
                  label="Notes"
                  className="col-6">
                  <Input
                    name={`${name}[${index}].notes`}
                    placeholder="Link description"
                  />
                </Form.Item>
              </div>
            ))}
          <Button type="link" onClick={() => helpers.push({ url: '' })}>
            + Add Link
          </Button>
        </>
      )}
    />
  );
});
