import { Typography } from 'antd';
import styled from 'styled-components';
import formatDate from 'date-fns/format';

import { Table } from '../elements/Table';
import { ShowMoreText } from '../elements/ShowMoreText';

export const columns = [
  {
    dataIndex: 'name',
    title: 'Date',
    render: (date) =>
      date ? formatDate(new Date(date), 'EEE, dd MMM').toUpperCase() : date,
    width: 120,
  },
  {
    dataIndex: 'description',
    title: 'Description',
    render: (text) => (text ? <ShowMoreText text={text} /> : '—'),
  },
  {
    dataIndex: 'duration',
    title: 'Hours',
    width: 70,
    render: (duration) =>
      duration && (
        <Typography.Text type="secondary">{duration}</Typography.Text>
      ),
  },
];

export const TimesheetTable = styled(Table)`
  .ant-table-tbody {
    & > tr > td {
      font-size: 14px;

      &.ant-table-cell-editable {
        padding: 14px 16px;
      }

      .ant-form-item {
        margin: 0;
      }
    }
  }
`;
