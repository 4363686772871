import { useDebugValue } from 'react';
import { useMutation, useQuery } from 'react-query';

import { useApi } from '../api/Api';

/**
 * Loads list of available assessments from API.
 * @returns {import('react-query').UseQueryResult<Array<{id: string; name: string;}>>}
 */
function useAssessmentsList() {
  const { apiClient } = useApi();
  const result = useQuery(['assessments'], () =>
    Assessment.getAssessments(apiClient),
  );

  useDebugValue(result);

  return result;
}

/**
 * Hook to send invitation to assessment for given profile.
 *
 * @param {Profile} profile Profile instance.
 * @returns {Omit<import('react-query').UseMutationResult, 'mutate'> & {sendInvite: (assessment: object) => Promise<void>}}
 */
function useSendInvite(profile) {
  const { apiClient } = useApi();
  const { mutate, ...mutation } = useMutation((assessment) =>
    Assessment.sendInvite(assessment, profile, apiClient),
  );

  useDebugValue(mutation);

  return { sendInvite: mutate, ...mutation };
}

export class Assessment {
  /**
   * @param {import('axios').AxiosInstance} apiClient Axios instance
   * @returns {import('axios').AxiosResponse}
   */
  static getAssessments(apiClient) {
    return apiClient
      .get('/prescreening/assessments')
      .then((response) => response.data.result);
  }

  /**
   * Sends invite
   * @param {{ id: string }} assessment Assessment object, provided by API
   * @param {{ email: string; id: string }} profile Profile document object.
   * @param {import('axios').AxiosInstance} apiClient Axios instance
   * @returns {import('axios').AxiosResponse}
   */
  static async sendInvite(assessment, profile, apiClient) {
    const payload = {
      profile_id: profile.id,
      email: profile.email,
      assessment_id: assessment.id,
    };
    if (!payload.email) {
      const accountSnap = await profile.account.get();
      payload.email = accountSnap.get('email');
    }
    try {
      return await apiClient.post('/prescreening/assessments/invite', payload);
    } catch (err) {
      if (err.isAxiosError) {
        const errorMessage =
          err.response.data?.message || err.response.data?.detail?.email?.[0];
        if (errorMessage) {
          throw new Error(errorMessage);
        }
      }
      throw new Error('Sorry, some error occured.');
    }
  }

  //#region Hooks
  static useAssessmentsList = useAssessmentsList;
  static useSendInvite = useSendInvite;
  //#endregion
}
