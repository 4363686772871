import isUndefined from 'lodash/isUndefined';

import { Attachments } from '../common/Attachments';
import services from '../utils/services';
import { REQUEST_TYPES, REQUEST_AUTHOR, REQUEST_STATUS } from './constants';

export class Request {
  static requiredFields = [
    'authorType',
    'dateTime',
    'status',
    'client',
    'sentBy',
    'timestamp',
    'type',
    'consultant',
  ];
  static fields = [...Request.requiredFields, 'notes', 'attachments'];

  /**
   * FirestoreDocumentConverter implementation
   */
  static converter = {
    /**
     * Converts Request instance into firestore document data.
     * @param {Request} request Request instance
     */
    toFirestore(request) {
      const emptyField = Request.requiredFields.find((f) =>
        isUndefined(request[f]),
      );
      if (emptyField) {
        throw new Error(`Missing value for required '${emptyField}'`);
      }
      return Request.fields.reduce((acc, fieldName) => {
        if (typeof request[fieldName] !== 'undefined') {
          acc[fieldName] = request[fieldName];
        }
        return acc;
      }, {});
    },
    /**
     * Creates Request instance from DocumentSnapshot.
     * @param {DocumentSnapshot} snapshot Firestore snapshot of the request document.
     */
    fromFirestore(snapshot) {
      return Request.fromSnapshot(snapshot);
    },
  };

  constructor({ id, ...data }) {
    Object.entries(data).forEach(([key, value]) => {
      this[key] =
        value instanceof services.get('firestore').Timestamp
          ? value.toDate()
          : value;
    });

    if (!this.authorType) {
      this.authorType = REQUEST_AUTHOR.CLIENT;
    }

    if (id) {
      this.__ref = services.get('db').doc(`actions/${id}`);
    }
  }

  /**
   * Creates Request instance from DocumentSnapshot.
   */
  static fromSnapshot(snapshot) {
    const request = new Request(snapshot.data());
    request.id = snapshot.id;
    request.__snapshot = snapshot;
    request.__ref = snapshot.ref;
    return request;
  }

  /**
   * Upload attachments, and remove previous attachments from the storage if we update request.
   */
  async uploadAttachments(attachments) {
    const att = new Attachments(attachments, `actions/${this.id}`);
    await att.uploadNew();
    if (!this.isDraft) {
      await att.removeOrphaned(this.attachments);
    }
    this.attachments = att.toFirestore();
  }

  async transformValues(values) {
    const { attachments, ...props } = values;
    this.timestamp = services.get('now')();
    if (this.isDraft) {
      this.__ref = services.get('db').collection('actions').doc();
      this.id = this.__ref.id;
      this.type = this.type || REQUEST_TYPES.INFO;
      this.status = this.status || REQUEST_STATUS.SUBMITTED;
    }
    await this.uploadAttachments(attachments);
    return { ...this, ...props };
  }

  /**
   * Writes request into Firestore.
   */
  async save(values) {
    this.isDraft = !this.id;
    const payload = await this.transformValues(values);
    return this.__ref.withConverter(Request.converter).set(payload);
  }

  get ref() {
    return this.__ref && this.__ref.withConverter(Request.converter);
  }
}
