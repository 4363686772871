export class Options extends Array {
  static get [Symbol.species]() {
    return Array;
  }

  get(value, variant = 'default') {
    const option = this.find((item) => item.value === value);
    if (option && option.variants?.[variant]) {
      return { ...option, ...option.variants[variant] };
    }
    return option;
  }

  getLabel(value, variant) {
    return this.get(value, variant)?.label || '';
  }
}
