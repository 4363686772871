export const Location = ({ outlined = true, ...props }) => (
  <svg
    width={16}
    height={16}
    viewBox="0 0 18 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <mask id="prefix__a" fill="#fff">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 24.5s9-7.732 9-14.809C18 4.615 13.97.5 9 .5S0 4.615 0 9.691C0 16.768 9 24.5 9 24.5zm0-12a3 3 0 100-6 3 3 0 000 6z"
      />
    </mask>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 24.5s9-7.732 9-14.809C18 4.615 13.97.5 9 .5S0 4.615 0 9.691C0 16.768 9 24.5 9 24.5zm0-12a3 3 0 100-6 3 3 0 000 6z"
      fill={!outlined ? '#D6C1FF' : undefined}
    />
    <path
      d="M9 24.5l-.977 1.138.977.84.977-.84L9 24.5zm7.5-14.809c0 2.99-1.95 6.34-4.172 9.122a38.818 38.818 0 01-3.954 4.234 20.891 20.891 0 01-.349.313l-.003.003L9 24.5c.977 1.138.978 1.137.978 1.137l.003-.002a.562.562 0 00.007-.006l.024-.02.083-.075a37.718 37.718 0 001.366-1.287 41.81 41.81 0 003.211-3.562C16.951 17.832 19.5 13.78 19.5 9.691h-3zM9 2c4.113 0 7.5 3.414 7.5 7.691h3C19.5 3.817 14.829-1 9-1v3zM1.5 9.691C1.5 5.414 4.887 2 9 2v-3C3.171-1-1.5 3.817-1.5 9.691h3zM9 24.5c.977-1.138.978-1.138.978-1.137l-.003-.003a1.721 1.721 0 01-.082-.072l-.267-.24a38.804 38.804 0 01-3.954-4.234C3.45 16.03 1.5 12.68 1.5 9.69h-3c0 4.088 2.55 8.141 4.828 10.994A41.817 41.817 0 007.6 25.26a24.615 24.615 0 00.418.375l.002.002c.001 0 .002 0 .979-1.137zm1.5-15A1.5 1.5 0 019 11v3a4.5 4.5 0 004.5-4.5h-3zM9 8a1.5 1.5 0 011.5 1.5h3A4.5 4.5 0 009 5v3zM7.5 9.5A1.5 1.5 0 019 8V5a4.5 4.5 0 00-4.5 4.5h3zM9 11a1.5 1.5 0 01-1.5-1.5h-3A4.5 4.5 0 009 14v-3z"
      fill="currentColor"
      mask="url(#prefix__a)"
    />
  </svg>
);
