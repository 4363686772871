import { useMemo } from 'react';
import { Typography, Button } from 'antd';

import { ReportsService } from '@yougig/shared/reports/ReportsService';
import { Report } from '@yougig/ui/components/reports/Reports';
import { REPORT_STATUS } from '@yougig/shared/reports/constants';
import {
  TimesheetTable,
  columns,
} from '@yougig/ui/components/reports/TimesheetTable';

/**
 *
 * @param {Object} props
 * @param {import('@yougig/shared/reports/Report').Report} props.report
 */
export const ReportTimesheet = ({ report }) => {
  const timesheetEnabled = ReportsService.useTimesheetEnabled(report.client);
  const { timeentries, syncing, refresh } =
    ReportsService.useReportTimesheet(report);

  const duration = useMemo(
    () => timeentries.reduce((sum, i) => sum + (i.duration || 0), 0).toFixed(1),
    [timeentries],
  );

  return (
    <div className="drawer-section drawer-section-greyed">
      <div className="section--heading">
        <Typography.Title level={4}>Timesheet</Typography.Title>
        {!!duration && (
          <Typography.Text type="secondary">{duration}h</Typography.Text>
        )}
        {timesheetEnabled && report.status === REPORT_STATUS.REQUESTED && (
          <Button
            type="link"
            htmlType="button"
            size="small"
            onClick={() => refresh()}
            icon={<Report.RefreshIcon $loading={syncing} />}
          />
        )}
      </div>

      <TimesheetTable
        dataSource={timeentries}
        pagination={false}
        columns={columns}
        rowKey={(row) => row.name}
      />
    </div>
  );
};
