import styled from 'styled-components';

import { Flash } from '../../icons';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 6px 16px;
  margin-bottom: 16px;
`;

const Item = styled.div`
  font-weight: 600;
  white-space: nowrap;
  display: inline-flex;
  align-items: center;
  color: var(--c_description);
  svg {
    &:first-child {
      margin-left: 6px;
    }
  }
`;

export const PrescreeningScores = ({ items = [], className }) => {
  return (
    <Wrapper className={className}>
      {items.map((item) => (
        <Item key={item.id}>
          {item.name}
          {item.score >= 20 && <Flash />}
          {item.score >= 40 && <Flash />}
          {item.score >= 70 && <Flash />}
        </Item>
      ))}
    </Wrapper>
  );
};
