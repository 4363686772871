import { useMemo } from 'react';
import { Typography, Space, Button } from 'antd';
import uniq from 'lodash/uniq';

import { Lock } from '../../icons';
import { IconValue } from '../elements/IconValue';
import { AttachmentsList } from '../elements/AttachmentsList';
import { LinksList } from '../elements/LinksList';
import { TalentProfileLayout } from './TalentProfileLayout';

const useContacts = (profile) => {
  return useMemo(
    () =>
      uniq(
        (profile?.contacts || [])
          .concat([profile?.email, profile?.phone])
          .filter(Boolean),
      ),
    [profile],
  );
};

const useInternalLinks = (profile, contacts = [], onlyTalentLinks) => {
  return useMemo(() => {
    return []
      .concat(
        ((!onlyTalentLinks && profile?.internalLinks) || [])
          .concat(profile?.talentLinks || [])
          .map(({ url, notes }) => ({
            url,
            label: notes,
          })),
      )
      .concat(contacts.map((contact) => ({ url: contact })))
      .filter((i) => !!i.url);
  }, [profile?.internalLinks, profile?.talentLinks, contacts, onlyTalentLinks]);
};

export const TalentInternalInfoBlock = ({
  profile,
  name,
  location,
  children,
  teamOwner,
  onlyTalentLinksAtt = false,
  onClickImpersonate,
}) => {
  const contacts = useContacts(profile);
  const internalLinks = useInternalLinks(profile, contacts, onlyTalentLinksAtt);
  const attachments = useMemo(
    () =>
      ((!onlyTalentLinksAtt && profile?.internalAttachments) || []).concat(
        profile?.talentAttachments || [],
      ),
    [
      profile?.internalAttachments,
      profile?.talentAttachments,
      onlyTalentLinksAtt,
    ],
  );

  return (
    <div className="drawer-section drawer-section-lock">
      <Lock className="drawer-section__icon" />
      <TalentProfileLayout.Row>
        <Space size="small">
          <Typography.Text>{name}</Typography.Text>
          {!!location && (
            <Typography.Text type="secondary">{location}</Typography.Text>
          )}
          {onClickImpersonate && (
            <Button type="link" onClick={onClickImpersonate}>
              Impersonate
            </Button>
          )}
        </Space>

        {profile?.internalRate && (
          <div className="ml-auto">
            Internal Rate:{` $${profile.internalRate}/h`}
          </div>
        )}
      </TalentProfileLayout.Row>

      {!!teamOwner && (
        <TalentProfileLayout.Row sm>
          <Typography.Text type="secondary">Commercial:</Typography.Text>
          <IconValue>{`${teamOwner.firstName} ${teamOwner.lastName}`}</IconValue>
          {!!teamOwner.phone && (
            <IconValue>{`${teamOwner.phone.code}${teamOwner.phone.number}`}</IconValue>
          )}
          <IconValue>{`${teamOwner.email}`}</IconValue>
        </TalentProfileLayout.Row>
      )}
      {!!contacts.length && (
        <TalentProfileLayout.Row sm>
          {!!teamOwner && (
            <Typography.Text type="secondary">Personal:</Typography.Text>
          )}
          {contacts
            .filter((c) => !!c && (c.includes('@') || /^[+0-9\- ]+$/.test(c)))
            .map((contact) => (
              <IconValue key={contact}>{contact}</IconValue>
            ))}
        </TalentProfileLayout.Row>
      )}

      {(!!internalLinks.length || !!attachments.length) && (
        <TalentProfileLayout.Row>
          {!!attachments.length && <AttachmentsList items={attachments} />}
          {!!internalLinks.length && <LinksList links={internalLinks} />}
        </TalentProfileLayout.Row>
      )}

      {children}
    </div>
  );
};
