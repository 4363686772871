import { useState } from 'react';
import { Button } from 'antd';
import styled from 'styled-components';

const LINE_HEIGHT = 19;

const TextShowMoreWrapper = styled.div`
  .ant-btn {
    padding: 0;
  }
`;

const TextShowMore = styled.div`
  width: 100%;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  max-width: 100%;
  font-size: 14px;
  line-height: ${LINE_HEIGHT}px;
  white-space: pre-wrap;
  ${(props) => {
    if (!props.full) {
      return `
        max-height: ${props.lines * LINE_HEIGHT + 1}px;
        -webkit-line-clamp: ${props.lines};
      `;
    }
  }};
`;

export const ShowMoreText = ({ text, lines = 3, ...props }) => {
  const [showMore, setShowMore] = useState(true);
  const [height, setTextHeight] = useState(0);

  const maxHeight = lines * LINE_HEIGHT;

  return (
    <TextShowMoreWrapper>
      <TextShowMore full={showMore} lines={lines} {...props}>
        <span
          ref={(el) => {
            if (el) {
              const clientHeight = el.getBoundingClientRect().height;
              if (height !== clientHeight) {
                setTextHeight(el.getBoundingClientRect().height);
                setShowMore(clientHeight <= maxHeight);
              }
            }
          }}>
          {text}
        </span>
      </TextShowMore>
      {height > maxHeight && (
        <Button type="link" onClick={() => setShowMore(!showMore)}>
          {showMore ? 'show less' : 'show more'}
        </Button>
      )}
    </TextShowMoreWrapper>
  );
};
