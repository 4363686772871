import { Typography } from 'antd';

import { RequestsSlider } from '@yougig/ui/components/elements/RequestsSlider';
import { TalentProfileLayout } from '@yougig/ui/components/talents/TalentProfileLayout';
import { useDrawerContext } from '@yougig/ui/components/common/DrawerProvider';
import { RequestService } from '@yougig/shared/actions/RequestService';

const options = { withBusinessName: true };

export const TalentProfileRequests = () => {
  const { profile } = useDrawerContext();

  const requests = RequestService.useProfileRequests(profile, options);

  return (
    <>
      <TalentProfileLayout.Row>
        <Typography.Title level={4}>Requests</Typography.Title>
      </TalentProfileLayout.Row>
      {requests.length > 0 && (
        <div className="drawer-section--slider">
          <RequestsSlider requests={requests} canEdit={() => false} />
        </div>
      )}
    </>
  );
};
