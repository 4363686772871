import { Form, Input } from 'formik-antd';
import { Form as AntForm } from 'antd';
import { Collapse } from 'antd';

import { PhoneField } from '@yougig/ui/components/form/PhoneField';
import { LinksField } from '@yougig/ui/components/form/LinksField';
import { AttachmentsField } from '@yougig/ui/components/form/AttachmentsField';
import { ArrowBottom, Availability, Location, Skills } from '@yougig/ui/icons';
import { LocationLanguagesFields } from '@yougig/ui/components/talents/LocationLanguagesFields';
import { AvailabilityFields } from '@yougig/ui/components/talents/AvailabilityFields';
import { SkillsToolsField } from '@yougig/ui/components/form/SkillsToolsField';

export const SpecialistFrom = ({ formState }) => {
  return (
    <>
      {formState === 'main' && (
        <>
          <div className="row">
            <Form.Item name="firstName" label="First Name" className="col-6">
              <Input name="firstName" placeholder="First Name" />
            </Form.Item>
            <Form.Item name="lastName" label="Last Name" className="col-6">
              <Input name="lastName" placeholder="Last Name" />
            </Form.Item>
            <Form.Item name="title" label="Position Title" className="col-6">
              <Input name="title" placeholder="Title" />
            </Form.Item>
          </div>
          <div className="row">
            <Form.Item name="email" label="Email" className="col-6">
              <Input name="email" placeholder="email@example.com" />
            </Form.Item>
            <PhoneField label="Mobile phone" name="phone" className="col-6" />
            <AntForm.Item label="Links" className="col-12 links--field">
              <LinksField name="talentLinks" />
            </AntForm.Item>
            <AttachmentsField
              name="talentAttachments"
              className="col-12"
              label={null}
              cta="Drag your CV PDF here"
            />
            <Form.Item
              name="password"
              label="Create Password"
              className="col-6">
              <Input.Password
                name="password"
                placeholder="Password"
                iconRender={(visible) =>
                  visible ? <span>Hide</span> : <span>Show</span>
                }
              />
            </Form.Item>
          </div>
        </>
      )}
      {formState === 'additional' && (
        <>
          <Collapse
            expandIcon={ArrowBottom}
            defaultActiveKey={['location', 'skills', 'availability']}
            expandIconPosition="right">
            <Collapse.Panel
              key="location"
              header={
                <>
                  <Location width={24} height={24} outlined={false} />
                  Location & Languages
                </>
              }>
              <LocationLanguagesFields />
            </Collapse.Panel>
            <Collapse.Panel
              key="skills"
              header={
                <>
                  <Skills />
                  Skills
                </>
              }>
              <div className="row">
                <SkillsToolsField name="skills" label="Skills / Tools" />
              </div>
            </Collapse.Panel>
            <Collapse.Panel
              key="availability"
              header={
                <>
                  <Availability />
                  Availability
                </>
              }>
              <AvailabilityFields />
            </Collapse.Panel>
          </Collapse>
        </>
      )}
    </>
  );
};
