export const Logout = (props) => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" {...props}>
    <g clipPath="url(#clip0)">
      <path
        d="M14 18.125L14 22.75C14 23.3023 13.5523 23.75 13 23.75L2.75 23.75C2.19772 23.75 1.75 23.3023 1.75 22.75L1.75 2.25C1.75 1.69772 2.19771 1.25 2.75 1.25L13 1.25C13.5523 1.25 14 1.69771 14 2.25L14 6.875"
        stroke="#270F56"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <g clipPath="url(#clip1)">
        <path
          d="M22.25 12.5L22.7268 13.0789C22.8998 12.9365 23 12.7241 23 12.5C23 12.2759 22.8998 12.0635 22.7268 11.9211L22.25 12.5ZM17.5232 15.4211C17.2035 15.6844 17.1577 16.157 17.4211 16.4768C17.6844 16.7965 18.157 16.8423 18.4768 16.5789L17.5232 15.4211ZM18.4768 8.42105C18.157 8.15773 17.6844 8.20348 17.4211 8.52322C17.1577 8.84296 17.2035 9.31563 17.5232 9.57895L18.4768 8.42105ZM11.75 11.75C11.3358 11.75 11 12.0858 11 12.5C11 12.9142 11.3358 13.25 11.75 13.25L11.75 11.75ZM21.7732 11.9211L17.5232 15.4211L18.4768 16.5789L22.7268 13.0789L21.7732 11.9211ZM17.5232 9.57895L21.7732 13.0789L22.7268 11.9211L18.4768 8.42105L17.5232 9.57895ZM11.75 13.25L22.25 13.25L22.25 11.75L11.75 11.75L11.75 13.25Z"
          fill="#270F56"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0 24.5) rotate(-90)"
        />
      </clipPath>
      <clipPath id="clip1">
        <rect
          width="12"
          height="12"
          fill="white"
          transform="translate(23 18.5) rotate(180)"
        />
      </clipPath>
    </defs>
  </svg>
);
