import { Tabs, Typography } from 'antd';
import styled from 'styled-components';

import services from '@yougig/shared/utils/services';

import countries from '../../utils/countries.json';
import { Flag, Location, Proven, Vetted } from '../../icons';
import { IconValue, IconValuesRow } from '../elements/IconValue';

export const TalentProfileLayout = styled.div`
  color: var(--c_text);
  font-size: 12px;
  display: flex;
  flex-direction: column;
  height: 100%;
  .ant-typography-success {
    color: var(--c_green);
  }
  .ant-typography-secondary {
    color: var(--c_description);
  }
  .ant-divider.ant-divider-horizontal {
    margin-top: 0;
  }
`;

const Header = styled.div`
  position: relative;
  margin-bottom: 32px;
  h3.ant-typography {
    margin-bottom: 6px;
    svg {
      color: var(--c_orange);
      margin-left: 8px;
      width: 16px;
      height: 16px;
    }
  }
`;

const Actions = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  gap: 16px;
`;

/**
 * Renders header for the talent profile drawer.
 *
 * @param {Object} props
 * @param {import('@yougig/shared/talents/Profile').Profile)} props.profile Talent profile
 * @returns {JSX.Element}
 */
const TalentProfileLayoutHeader = ({ profile, actions }) => {
  const isClientApp = services.get('config').PROJECT === 'yougig';
  return (
    <Header>
      <Typography.Title level={3}>
        {isClientApp ? profile.firstName : profile.fullName}
        {profile.worked_with_us ? <Proven /> : profile.vetted && <Vetted />}
      </Typography.Title>
      <IconValuesRow>
        {!!profile.countryName && (
          <IconValue secondary>
            <Location />
            {profile.countryName.length === 2
              ? countries.find((c) => c.cca2 === profile.countryCode)?.name
              : profile.countryName}
          </IconValue>
        )}
        {!!profile.languages?.length && (
          <IconValue secondary>
            <Flag outlined />
            {profile.languages
              .map(({ language, level }) => `${language} (${level})`)
              .join(', ')}
          </IconValue>
        )}
      </IconValuesRow>
      {actions && <Actions>{actions}</Actions>}
    </Header>
  );
};

TalentProfileLayout.Header = TalentProfileLayoutHeader;

TalentProfileLayout.Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${(props) => (props.sm ? '8px' : '10px 20px')};
  line-height: 16px;

  .ant-typography {
    align-self: center;
  }

  h4.ant-typography {
    margin: 0;
  }

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

TalentProfileLayout.Tabs = styled(Tabs)`
  .ant-tabs-nav {
    margin-bottom: 8px;
  }
  .ant-tabs-tab .ant-tabs-tab-btn {
    font-size: 14px;
    display: flex;
    svg {
      margin: 0 8px 3px 0;
    }
  }
  .updates {
    margin-top: 16px;
  }
`;

TalentProfileLayout.Title = styled(Typography.Title)`
  &.ant-typography {
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 4px !important;
  }
`;
