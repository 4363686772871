import { Form, Input, Radio } from 'formik-antd';
import { useFormikContext } from 'formik';

import {
  AVAILABILITY,
  AVAILABILITY_OPTIONS,
} from '@yougig/shared/candidates/constants';

import { DatePicker } from '../form/DatePicker';

export const AvailabilityFields = () => {
  const { values, setFieldValue } = useFormikContext();

  return (
    <>
      <div className="row">
        <Form.Item
          name="availability.status"
          label="Candidate is"
          className="col-6 mb-0">
          <Radio.Group
            name="availability.status"
            options={AVAILABILITY_OPTIONS}
            optionType="button"
            buttonStyle="solid"
          />
        </Form.Item>
      </div>
      {values.availability?.status === AVAILABILITY.AVAILABLE && (
        <div className="row">
          <Form.Item
            name="availability.fromDate"
            label="From"
            className="col-6">
            <DatePicker
              name="availability.fromDate"
              placeholder="Select Date"
              onChange={(value) =>
                setFieldValue('availability.fromDate', value)
              }
              value={values.availability?.fromDate}
              getPopupContainer={(node) => node.parentElement}
            />
          </Form.Item>
          <Form.Item
            name="availability.untilDate"
            label="Until"
            className="col-6">
            <DatePicker
              name="availability.untilDate"
              placeholder="Select Date"
              onChange={(value) =>
                setFieldValue('availability.untilDate', value)
              }
              value={values.availability?.untilDate}
              getPopupContainer={(node) => node.parentElement}
            />
          </Form.Item>
          <Form.Item
            name="availability.availability"
            label="Weekly capacity"
            className="col-6">
            <Input
              name="availability.availability"
              type="number"
              min={1}
              max={40}
              suffix="h/week"
              placeholder="40 = Full-Time"
            />
          </Form.Item>
        </div>
      )}
      {values.availability?.status === AVAILABILITY.NOT_AVAILABLE && (
        <div className="row">
          <Form.Item
            name="availability.notAvailableUntilDate"
            label="Until date (approximately)"
            className="col-6">
            <DatePicker
              name="availability.notAvailableUntilDate"
              placeholder="Select Date"
              onChange={(value) =>
                setFieldValue('availability.notAvailableUntilDate', value)
              }
              value={values.availability?.notAvailableUntilDate}
              getPopupContainer={(node) => node.parentElement}
            />
          </Form.Item>
        </div>
      )}
    </>
  );
};
