import React, { createContext, useContext, useMemo, useCallback } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import get from 'lodash/get';
import intersection from 'lodash/intersection';

import services from '../utils/services';
export const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [user, sessionLoading, authError] = useAuthState(services.get('auth'));
  const userRef = !!user && services.get('db').doc(`users/${user?.uid}`);
  const [profile, profileLoading] = useDocumentData(!!user && userRef, {
    idField: 'id',
  });
  const isAuthenticated = useMemo(() => !!user, [user]);

  const [tenant, tenantLoading] = useDocumentData(profile?.tenant, {
    idField: 'id',
  });

  const authorize = useCallback(async ({ email, password }, projectName) => {
    const emailCheck = await services
      .get('functions')
      .httpsCallable('validateUserAccess');
    const isValidAcc = await emailCheck({
      email: email.toLowerCase(),
      projectName: projectName || services.get('config').PROJECT,
    });

    if (!isValidAcc.data) {
      throw new Error('Invalid email or password.');
    }

    return await services
      .get('auth')
      .signInWithEmailAndPassword(email, password);
  }, []);

  const logout = () => {
    return services.get('auth').signOut();
  };

  const contextValue = useMemo(
    () => ({
      user,
      userRef,
      isAuthenticated,
      initializing:
        sessionLoading ||
        profileLoading ||
        (profile?.tenant && !tenant) ||
        tenantLoading ||
        (isAuthenticated && !profile),
      authError,
      profile,
      tenant,
      isInternal: profile?.internal || false,
      isClient: get(profile, 'roles', []).includes('client'),
      isAdmin: get(profile, 'roles', []).includes('admin'),
      isRecruiter: get(profile, 'roles', []).includes('recruiter'),
      isManager:
        intersection(get(profile, 'roles', []), ['admin', 'recruiter'])
          .length >= 1,
      isCandidate: get(profile, 'roles', []).includes('candidate'),
      isPartner: get(profile, 'roles', []).includes('partner'),
      isTalent:
        intersection(get(profile, 'roles', []), ['candidate', 'partner'])
          .length >= 1,
      authorize,
      logout,
    }),
    [
      user,
      userRef,
      sessionLoading,
      profileLoading,
      tenantLoading,
      isAuthenticated,
      authError,
      profile,
      tenant,
      authorize,
    ],
  );

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export const withAuth = (Component) => (props) =>
  (
    <AuthContext.Consumer>
      {(state) => <Component {...props} {...state} />}
    </AuthContext.Consumer>
  );
