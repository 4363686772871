import styled from 'styled-components';

export const Card = styled.div`
  display: block;
  background-color: #fff;
  border: 1px solid var(--c_light_grey);
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  padding: 32px 48px;

  &:not(:last-child) {
    margin-bottom: 32px;
  }

  @media (max-width: 479px) {
    padding: 16px;
  }
`;
