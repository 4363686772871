import styled from 'styled-components';

import { useAuth } from '@yougig/shared/auth/AuthProvider';

const RateCol = styled.div`
  flex: 0 0 auto;
`;

const RateStyled = styled.div`
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

const OldRate = styled.div`
  text-decoration: line-through;
  opacity: 0.5;
  margin-right: 12px;
`;

export const TalentRate = ({
  profile,
  discount = 0,
  discountLabel = 'Special price',
}) => {
  const { isAuthenticated } = useAuth();

  return (
    <RateCol>
      {isAuthenticated && !!profile.rate && (
        <RateStyled>
          {!!discount && (
            <div>
              <OldRate>
                ${profile.rate.min}-{profile.rate.max}/h
              </OldRate>
              <div>{discountLabel}</div>
            </div>
          )}
          <div>
            {!!discount
              ? `$${Math.ceil(profile.rate.min * (1 - discount))}-${Math.ceil(
                  profile.rate.max * (1 - discount),
                )}/h`
              : `$${profile.rate.min}-${profile.rate.max}/h`}
          </div>
        </RateStyled>
      )}
    </RateCol>
  );
};
