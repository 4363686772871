export const FormBlue = (props) => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g clipPath="url(#FormBlueClip)">
        <path d="M7.55007 8.4375L14 1V15H2L7.55007 8.4375Z" fill="#D6C1FF" />
        <rect
          x="1.83334"
          y="0.5"
          width="12.3333"
          height="15"
          rx="1.5"
          stroke="#573D8B"
        />
        <path
          d="M8.66666 11.3333L10 12.6667L12 10"
          stroke="#573D8B"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.66666 3.33334H11.3333"
          stroke="#573D8B"
          strokeLinecap="round"
        />
        <path d="M4.66666 6H11.3333" stroke="#573D8B" strokeLinecap="round" />
        <path d="M4.66666 8.66666H8" stroke="#573D8B" strokeLinecap="round" />
      </g>
      <defs>
        <clipPath id="FormBlueClip">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
