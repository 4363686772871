import { useMemo, useState } from 'react';
import { Typography, Button } from 'antd';
import styled from 'styled-components';
import uniq from 'lodash/uniq';

import { SkillsList } from '@yougig/ui/components/common/SkillsList';
import { Drawer } from '@yougig/ui/components/elements/Drawer';
import { PrescreeningScores } from '@yougig/ui/components/common/PrescreeningScores';

import { useTalent } from '../../../components/common/TalentProvider';
import { StartAssessments } from './StartAssessments';

const SkillsContainer = styled.div`
  font-size: 14px;
`;

const SkillsListStyled = styled(SkillsList)`
  margin-bottom: 16px;
`;

export const SkillsSection = () => {
  const { profile, candidate } = useTalent();
  const [isDrawerVisible, setDrawerVisibility] = useState(false);
  const quizes = useMemo(() => candidate?.prescreening || [], [candidate]);
  const assessments = useMemo(
    () => uniq(quizes.map((q) => q.assessment_id)),
    [quizes],
  );

  if (!profile?.skills?.length) {
    return null;
  }

  return (
    <>
      <Typography.Title level={3}>Skills</Typography.Title>
      <SkillsContainer>
        <PrescreeningScores items={quizes} />
        <SkillsListStyled skills={profile.skills} />
        <Button
          type="ghost"
          size="small"
          onClick={() => setDrawerVisibility(true)}>
          Take skill assessment
        </Button>
      </SkillsContainer>
      {isDrawerVisible && (
        <Drawer
          visible
          onClose={() => setDrawerVisibility(false)}
          className="drawer-no-padding"
          width={392}>
          <StartAssessments
            onDone={() => setDrawerVisibility(false)}
            taken={assessments}
          />
        </Drawer>
      )}
    </>
  );
};
