import services from '../utils/services';
import { Profile } from '../talents/Profile';
import { CV_STATUS } from '../talents/constants';

export class User {
  static async createClientAccount(values, parent) {
    const {
      email,
      password,
      firstName,
      lastName,
      title,
      businessPreferences,
      phone,
      businessName,
      canSign,
      analyticsEnabled,
      signPersonFirstName,
      signPersonLastName,
      signPersonEmail,
      signPersonTitle,
      signPersonPhone,
    } = values;

    const internal = email.includes('@yougig.test');

    const credentials = await services
      .get('auth')
      .createUserWithEmailAndPassword(email, password);
    await credentials.user.updateProfile({
      displayName: `${firstName} ${lastName}`,
    });
    await credentials.user.sendEmailVerification();

    const created = services.get('firestore').Timestamp.now();

    const userRef = services.get('db').doc(`users/${credentials.user.uid}`);

    const client = {
      businessName,
      businessPreferences,
      created,
      internal,
    };
    if (!canSign) {
      client.signPerson = {
        firstName: signPersonFirstName,
        lastName: signPersonLastName,
        title: signPersonTitle,
        email: signPersonEmail,
        phone: signPersonPhone,
      };
    }

    if (parent) {
      client.parent = services.get('db').doc(`clients/${parent}`);
    }

    const clientDoc = await services
      .get('db')
      .collection('clients')
      .add(client);

    await clientDoc.collection('users').add({
      user: userRef,
      status: 'active',
      role: canSign ? 'owner' : 'manager',
    });

    const profile = {
      tenant: clientDoc,
      email: email,
      firstName,
      lastName,
      phone,
      analyticsEnabled,
      roles: ['client'],
      created,
      internal,
    };

    if (title) {
      profile.title = title;
    }

    await userRef.set(profile);
    profile.id = credentials.user.uid;

    return { user: credentials.user, profile, client };
  }

  static async createCandidateAccount(
    data,
    additionalProfileFields,
    existingProfile,
  ) {
    const {
      email,
      password,
      firstName,
      lastName,
      skills,
      phone,
      title,
      ...profile
    } = data;

    const internal = email.includes('@youpro.test');

    const { user } = await services
      .get('auth')
      .createUserWithEmailAndPassword(email, password);
    await user.sendEmailVerification();
    let profileRef = null;
    const profileEntry = {
      firstName,
      lastName,
      email,
      phone,
      title,
      ...profile,
      ...additionalProfileFields,
      internal,
      skills,
      account: services.get('db').doc(`users/${user.uid}`),
      status: CV_STATUS.DRAFT,
    };
    if (existingProfile) {
      profileRef = services.get('db').doc(`profiles/${existingProfile.id}`);
      await profileRef.update(profileEntry);
    } else {
      profileRef = (await Profile.create(profileEntry)).ref;
    }

    await services
      .get('db')
      .collection('users')
      .doc(user.uid)
      .set({
        email,
        firstName,
        lastName,
        internal,
        phone,
        title,
        profile: profileRef,
        analyticsEnabled: true,
        roles: ['candidate'],
      });

    await user.updateProfile({
      displayName: `${firstName} ${lastName}`,
    });
  }

  static async createPartnerAccount(values) {
    const {
      firstName,
      lastName,
      email,
      password,
      skills,
      title,
      analyticsEnabled,
      team,
      phone,
      relocation,
      availability,
      country,
      businessTrip,
      languages,
      ...profile
    } = values;

    const internal = email.includes('@youpro.test');

    const { user } = await services
      .get('auth')
      .createUserWithEmailAndPassword(email, password);
    await user.sendEmailVerification();
    const userRef = services.get('db').doc(`users/${user.uid}`);

    const teamDoc = await services
      .get('db')
      .collection('teams')
      .add({ ...team, internal, timestamp: services.get('now')() });

    await teamDoc.collection('teamUsers').add({
      user: userRef,
      status: 'active',
      role: team.signPerson ? 'manager' : 'owner',
    });

    await services
      .get('db')
      .collection('users')
      .doc(user.uid)
      .set({
        email,
        firstName,
        lastName,
        internal,
        phone,
        ...profile,
        analyticsEnabled,
        tenant: teamDoc,
        roles: ['partner'],
      });

    await user.updateProfile({
      displayName: `${firstName} ${lastName}`,
    });
  }
}
