import services from './services';

export class EmailService {
  /**
   * Calls the Firebase Function to send email with a given template.
   *
   * @param {Array<string>} recipients List of recipients' emails
   * @param {string} template Name of email template to use
   * @param {any} templateVars Object with template variables
   */
  static sendEmail(recipients, template, templateVars) {
    return services.get('functions').httpsCallable('sendEmail')({
      templateVars,
      template,
      recipients,
    });
  }

  /**
   * Builds an invitation link via Firebase Dynamic Links and sends invitation email.
   *
   * @param {import('../talents/Profile').Profile} profile Profile instance to send invite for.
   * @param {string} email Optional email to send invite to (if not provided, profile's email will be taken)
   */
  static async sendTeamInvite(profile, email) {
    const teamSnap = await profile.team.get();
    const teamName = teamSnap.get('name') || 'YouPro';

    const config = services.get('config');
    const host = window.location?.host || 'jobs.yougig.work';

    const link = await services.get('dynamicLinks').buildShortLink({
      link: `https://${host}/registration?invitation=${
        profile.id
      }&team=${encodeURI(teamName)}`,
      domainUriPrefix: `https://${config.DYNAMIC_LINKS_DOMAIN}`,
      android: {
        packageName: config.APP_BUNDLE_ID,
      },
      ios: {
        bundleId: config.APP_BUNDLE_ID,
        appStoreId: config.IOS_APP_STORE_ID,
      },
    });

    return await EmailService.sendEmail(
      [email || profile.email],
      'team-invite',
      { teamName, link },
    );
  }
}
