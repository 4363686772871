import styled from 'styled-components';
import format from 'date-fns/format';
import { Typography } from 'antd';

import { WORK_FORMAT_OPTIONS } from '@yougig/shared/constants';
import { MoneyBlue, TimerBlue } from '@yougig/ui/icons';
import countries from '@yougig/ui/utils/countries.json';
import { JOB_TYPES_CONFIG } from '@yougig/shared/jobs/constants';
import { PROJECT_TERMS_OPTIONS } from '@yougig/shared/projects/constants';
import { Job } from '@yougig/shared/jobs/Job';

const Row = styled.div`
  display: flex;
  &:not(:last-child) {
    margin-bottom: 40px;
  }
  svg {
    margin-right: 16px;
    flex: 0 0 24px;
  }
`;

const AssignmentInfoStyled = styled.div`
  ${(props) =>
    props.row &&
    `
     display: flex;
     justify-content: space-between;
     max-width: 660px;
     gap: 24px;
     ${Row} {
        margin-bottom: 0;
     }
     @media (max-width: 767px) {
        flex-direction: column;
     }
     @media (max-width: 479px) {
       gap: 16px;
    }
  `};
`;

const InfoItem = styled.div`
  display: flex;
  &:not(:last-child) {
    margin-bottom: 16px;
  }
  .ant-typography {
    margin-right: 6px;
    white-space: nowrap;
  }
`;

export const AssignmentDetailsTopInfoCol = ({ data, row }) => {
  const termsValue = Job.isJob(data)
    ? JOB_TYPES_CONFIG.getLabel(data.type)
    : data.terms && PROJECT_TERMS_OPTIONS.getLabel(data.terms);

  return (
    <AssignmentInfoStyled row={row}>
      {(!!data.dueDate ||
        !!data.startDate ||
        !!data.duration ||
        !!termsValue) && (
        <Row>
          <TimerBlue />
          <div>
            {!!data.terms && (
              <InfoItem>
                <Typography.Text type="secondary">Terms:</Typography.Text>
                {termsValue}
              </InfoItem>
            )}
            {!!data.dueDate && (
              <InfoItem>
                <Typography.Text type="secondary">
                  Request Due Date:
                </Typography.Text>
                {format(data.dueDate, 'MM.dd.yyyy')}
              </InfoItem>
            )}
            {!!data.startDate && (
              <InfoItem>
                <Typography.Text type="secondary">Start Date:</Typography.Text>
                {format(data.startDate, 'MM.dd.yyyy')}
              </InfoItem>
            )}
            {!!data.duration && (
              <InfoItem>
                <Typography.Text type="secondary">Duration:</Typography.Text>
                {data.duration} months
              </InfoItem>
            )}
          </div>
        </Row>
      )}
      {(!!data.workFormat ||
        !!data.country ||
        data.regions?.length > 0 ||
        data.talentCountries?.length > 0) && (
        <Row>
          <MoneyBlue />
          <div>
            {!!data.workFormat && (
              <InfoItem>
                <Typography.Text type="secondary">Work Format:</Typography.Text>
                {WORK_FORMAT_OPTIONS.getLabel(data.workFormat)}
              </InfoItem>
            )}
            {!!data.location && (
              <InfoItem>
                <Typography.Text type="secondary">
                  Project Location:
                </Typography.Text>
                {countries.find((c) => c.cca2 === data.location)?.name}
              </InfoItem>
            )}
            {data.regions?.length > 0 && (
              <InfoItem>
                <Typography.Text type="secondary">
                  Talent Regions:
                </Typography.Text>
                {data.regions?.join(', ')}
              </InfoItem>
            )}
            {data.talentCountries?.length > 0 && (
              <InfoItem>
                <Typography.Text type="secondary">
                  Talent Countries:
                </Typography.Text>
                {countries
                  .filter(({ code }) => data.talentCountries.includes(code))
                  .map((country) => country.name)
                  .join(', ')}
              </InfoItem>
            )}
          </div>
        </Row>
      )}
    </AssignmentInfoStyled>
  );
};
