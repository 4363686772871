import { Space } from 'antd';

import { useDrawerContext } from '@yougig/ui/components/common/DrawerProvider';
import { useAuth } from '@yougig/shared/auth/AuthProvider';
import { ReportsService } from '@yougig/shared/reports/ReportsService';
import { Report as Reports } from '@yougig/ui/components/reports/Reports';

import { OverviewCardList } from './OverviewCardList';

const ReportCard = ({ report }) => {
  const { setDrawerView } = useDrawerContext();

  return (
    <Reports.Item
      report={report}
      key={report.id}
      secondaryText={
        <Space size="small">
          {!!report.totalTime && <span>{report.totalTime}h</span>}
          <span>{report.businessName}</span>
        </Space>
      }
      onClick={() => setDrawerView('report', { report })}
    />
  );
};

export const ReportsSection = () => {
  const { profile } = useAuth();
  const [reports] = ReportsService.useTalentReports(profile.profile, {
    limit: 4,
  });

  return (
    <OverviewCardList title="Reports" showAllViewMode="reports">
      {reports.map((report) => (
        <ReportCard key={report.id} report={report} />
      ))}
    </OverviewCardList>
  );
};
