import { useField } from 'formik';
import { Dropdown, Menu, Button } from 'antd';
import styled from 'styled-components';

import { Options } from '@yougig/shared/utils/Options';

import { ArrowBottom } from '../../icons';

const StatusButton = styled(Button)`
  & > svg {
    margin-left: 6px;
  }
  &.ant-dropdown-open > svg {
    transform: rotate(180deg);
  }
`;

export const StatusDropdown = ({
  name,
  options,
  className,
  variant = 'default',
}) => {
  const [{ value }, , { setValue }] = useField(name);
  return (
    <Dropdown
      placement="bottomLeft"
      trigger={['click']}
      getPopupContainer={(el) => el.parentElement}
      overlay={
        <Menu
          selectedKeys={[value]}
          onClick={({ key }) => {
            setValue(key);
          }}>
          {options.map((option) => (
            <Menu.Item key={option.value}>
              {new Options(option).getLabel(option.value, variant)}
            </Menu.Item>
          ))}
        </Menu>
      }>
      <StatusButton
        type="ghost"
        size="small"
        htmlType="button"
        className={className}>
        {new Options(...options).getLabel(value, variant)}
        <ArrowBottom width={12} height={12} />
      </StatusButton>
    </Dropdown>
  );
};
