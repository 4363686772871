import {
  object,
  string,
  boolean,
  number,
  mixed,
  linkedInCompany,
} from '../utils/validation';

const personSchema = {
  firstName: string().required(),
  lastName: string().required(),
  email: string().email().required(),
  phone: object()
    .required()
    .shape({
      code: string().required(),
      number: number().required().min(999),
    }),
};

const checkBoxesSchema = {
  privacy: mixed().oneOf([true], 'You have to agree to terms').required(),
  analyticsEnabled: boolean(),
  mailing: boolean(),
};

const passwordSchema = {
  password: string()
    /* eslint-disable-next-line no-template-curly-in-string */
    .min(6, 'Minimum password length - ${min} symbols')
    .required(),
};

export const teamSchema = object({
  name: string().required(),
  legalName: string().required(),
  location: string().required(),
  address: string(),
  city: string(),
  state: string(),
  zip: number(),
  speciality: object().nullable(),
  website: string().url().nullable(),
  linkedinCompany: linkedInCompany().nullable(),
  import: boolean(),
});

export const specialistFormSchema = object({
  ...personSchema,
  ...passwordSchema,
  ...checkBoxesSchema,
});

export const clientSchema = object({
  ...personSchema,
  businessName: string().required(),
  title: string().required(),
  businessLocation: string().required(),
  ...passwordSchema,
  ...checkBoxesSchema,
});

export const signPersonSchema = object({
  ...personSchema,
  title: string().required(),
});
