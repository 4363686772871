import { useParams } from 'react-router-dom';
import { useMemo } from 'react';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { Helmet } from 'react-helmet';

import { useAuth } from '@yougig/shared/auth/AuthProvider';
import { useApiJob } from '@yougig/shared/jobs/useApiJob';
import { AssignmentDetailsTopInfo } from '@yougig/ui/components/assignments/AssignmentDetailsTopInfo';
import { AssignmentDetails } from '@yougig/ui/components/assignments/AssignmentDetails';
import { TeamJobCandidatesProvider } from '@yougig/shared/teams/TeamJobCandidatesProvider';
import { DrawerProvider } from '@yougig/ui/components/common/DrawerProvider';
import { Candidate } from '@yougig/shared/candidates/Candidate';

import { JobApplyDrawer } from './JobApplyDrawer';
import { ConsultantApplyButton } from './components/ConsultantApplyButton';
import { PartnerApplyButton } from './components/PartnerApplyButton';
import { useTalent } from '../../components/common/TalentProvider';
import { AppliedConsultants } from './components/AppliedConsultants';

export const JobDetails = () => {
  const { isPartner, profile: account, userRef } = useAuth();
  const params = useParams();
  const [job] = useApiJob(params.jobId);
  const { clients = [], assignments, profile } = useTalent();

  const candidate = useMemo(() => {
    let c;
    if (job.client) {
      c = clients?.find((c) => c.client.isEqual(job.client));
    } else {
      c = assignments?.find((a) => a.jobId === job.key)?.candidate;
    }

    if (c) {
      return c;
    } else if (!profile || !account) {
      return null;
    } else {
      return new Candidate({
        consultant: profile.ref,
        approvedBy: userRef,
      });
    }
  }, [clients, job.client, job.key, assignments, profile, account, userRef]);

  const [assignment] = useDocumentData(
    candidate && candidate.getAssignmentsQuery()?.doc(job.key),
    { idField: 'jobId' },
  );

  const detailsWithDrawer = (
    <>
      <Helmet>
        <title>{job?.name || job?.title || 'Job Details'}</title>
        <meta name="robots" content="noindex,nofollow" />
      </Helmet>
      <DrawerProvider>
        <AssignmentDetails>
          <AssignmentDetailsTopInfo data={job}>
            {isPartner ? (
              <PartnerApplyButton />
            ) : (
              <ConsultantApplyButton
                job={job}
                candidate={candidate}
                applied={!!assignment?.applied}
              />
            )}
          </AssignmentDetailsTopInfo>
        </AssignmentDetails>
        <JobApplyDrawer />
      </DrawerProvider>
    </>
  );

  return isPartner ? (
    <TeamJobCandidatesProvider job={job}>
      {detailsWithDrawer}
      {isPartner && <AppliedConsultants />}
    </TeamJobCandidatesProvider>
  ) : (
    detailsWithDrawer
  );
};
