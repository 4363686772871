import { Input as AntInput, Form } from 'antd';
import { useField } from 'formik';
import { Input, Select } from 'formik-antd';
import uniqBy from 'lodash/uniqBy';
import uniq from 'lodash/uniq';

import countries from '../../utils/countries.json';
import { ArrowBottom } from '../../icons';

const phoneCodeOptions = uniqBy(
  countries
    .map(({ callingCode }) => ({
      value: callingCode,
      label: callingCode,
    }))
    .filter(({ value }) => Boolean(value))
    .sort((a, b) => a.value.localeCompare(b.value)),
  'value',
);

export const PhoneField = ({ name, disabled, ...props }) => {
  const [, { error, touched }] = useField(name);
  return (
    <Form.Item
      label="Mobile phone"
      {...props}
      validateStatus={touched && !!error ? 'error' : undefined}
      hasFeedback={touched && !!error}
      help={
        touched &&
        !!error &&
        uniq(Object.values(error)).map((e) => <li key={e}>{e}</li>)
      }>
      <AntInput.Group compact>
        <Select
          style={{ width: 120 }}
          options={phoneCodeOptions}
          suffixIcon={<ArrowBottom width={16} height={16} />}
          name={`${name}.code`}
          placeholder="+1"
          disabled={disabled}
        />
        <Input
          style={{ width: 'calc(100% - 120px)' }}
          type="text"
          placeholder="1234567890"
          name={`${name}.number`}
          disabled={disabled}
        />
      </AntInput.Group>
    </Form.Item>
  );
};
