import { Space, Typography } from 'antd';
import styled from 'styled-components';

import { ReportsService } from '@yougig/shared/reports/ReportsService';

import { TalentProfileLayout } from '../talents/TalentProfileLayout';
import { Slider } from '../elements/RequestsSlider';
import { Report } from './Reports';

// Use `!important` to override react-slick's inline styles with `display: inline-block;`
const ReportItem = styled(Report.Item)`
  display: inline-flex !important;
`;

/**
 *
 * @param {Object} props
 * @param {import('@yougig/shared/talent/Profile').Profile} props.profile
 * @param {(report: import('@yougig/shared/reports/Report').Report) => void} props.onReportClick
 * @returns {JSX.Element}
 */
export const TalentProfileReports = ({ profile, onReportClick }) => {
  const [reports] = ReportsService.useTalentReports(profile.ref);

  if (!reports?.length) {
    return null;
  }

  return (
    <>
      <TalentProfileLayout.Row>
        <Typography.Title level={4}>Reports</Typography.Title>
      </TalentProfileLayout.Row>
      {reports.length > 0 && (
        <div className="drawer-section--slider">
          <Slider>
            {reports.map((report) => (
              <ReportItem
                key={report.id}
                report={report}
                secondaryText={
                  <Space size="small">
                    {!!report.totalTime && <span>{report.totalTime}h</span>}
                    <span>{report.businessName}</span>
                  </Space>
                }
                onClick={() => onReportClick(report)}
              />
            ))}
          </Slider>
        </div>
      )}
    </>
  );
};
