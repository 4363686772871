import { Button, Typography } from 'antd';
import styled from 'styled-components';

const List = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const AssessmentList = ({ items, onClick }) => {
  return (
    <List>
      {items.map((item) => (
        <Row key={item.id}>
          <Typography.Text>{item.name}</Typography.Text>
          <Button type="ghost" size="small" onClick={() => onClick(item)}>
            Go
          </Button>
        </Row>
      ))}
    </List>
  );
};
