import merge from 'lodash/merge';
import { useCallback, useMemo } from 'react';
import { Button } from 'antd';
import { useParams } from 'react-router-dom';

import { Arrow } from '@yougig/ui/icons';
import { Pipeline } from '@yougig/ui/components/common/Pipeline';
import { Table } from '@yougig/ui/components/elements/Table';
import { Scoring } from '@yougig/ui/components/elements/Scoring';
import { useAuth } from '@yougig/shared/auth/AuthProvider';
import { useApiJob } from '@yougig/shared/jobs/useApiJob';
import { useTeamJobCandidatesContext } from '@yougig/shared/teams/TeamJobCandidatesProvider';

const baseColumns = [
  {
    title: 'Candidate',
    key: 'candidate',
    render: ({ profile }) => {
      return `${profile.firstName} ${profile.lastName}`;
    },
  },
  {
    title: 'Occupation',
    key: 'occupation',
    render: ({ profile }) => {
      return null;
    },
  },
  {
    title: 'Scoring',
    key: 'scoring',
    render: ({ profile }) => (
      <>
        <Scoring {...merge(profile?.scoring, profile?.manual_scoring || {})} />
      </>
    ),
  },
  {
    title: 'From',
    key: 'from',
    render: ({ profile }) => {
      return <span>{profile.country}</span>;
    },
  },
  {
    title: 'Rate',
    key: 'rate',
    render: ({ profile }) => {
      return profile.rate && <span>{`$${profile.rate}/h`}</span>;
    },
  },
];

export const AppliedConsultants = () => {
  const { userRef } = useAuth();
  const params = useParams();
  const [job] = useApiJob(params.jobId);
  const { appliedCandidates, loading, refresh } = useTeamJobCandidatesContext();

  const cancelAssignment = useCallback(
    (candidate) => {
      return candidate.cancelAssignment({ position: job, userRef });
    },
    [job, userRef],
  );

  const columns = useMemo(() => {
    return baseColumns.concat({
      title: '',
      key: 'cancel',
      render: (appliedCandidate) => {
        return (
          <Button
            type="ghost"
            size="small"
            onClick={async () => {
              await cancelAssignment(appliedCandidate.candidate);
              refresh();
            }}>
            Cancel Application
          </Button>
        );
      },
    });
  }, [cancelAssignment, refresh]);

  return (
    <Pipeline
      defaultActiveKey={['applied']}
      expandIconPosition="right"
      expandIcon={() => <Arrow />}>
      <Pipeline.Panel
        key="applied"
        header={`Applied (${appliedCandidates.length})`}>
        <Table
          dataSource={appliedCandidates}
          columns={columns}
          loading={loading}
          rowKey={(row) => row.profile.id}
          pagination={false}
        />
      </Pipeline.Panel>
    </Pipeline>
  );
};
