import React, {
  createContext,
  useContext,
  useMemo,
  useState,
  useEffect,
} from 'react';

import { useTeamCandidates } from './useTeamCandidates';

export const TeamJobCandidatesContext = createContext({});

export const TeamJobCandidatesProvider = ({ job: initialJob, children }) => {
  // we forced to store the job object in the provider's state
  // because in the mobile app the provider is wrapping the whole app
  // but the job is loaded inside a screen and will be used on other screens.
  const [job, setJob] = useState(initialJob);
  const { candidates = [], loading, refresh } = useTeamCandidates(job);

  // `job` prop of the provider is used only for web, so we have to update it here.
  useEffect(() => {
    setJob(initialJob);
  }, [initialJob, setJob]);

  const appliedCandidates = useMemo(() => {
    return candidates?.filter((candidate) => candidate && candidate.applied);
  }, [candidates]);

  const notAppliedCandidates = useMemo(() => {
    return candidates?.filter((candidate) => candidate && !candidate.applied);
  }, [candidates]);

  const contextValue = useMemo(
    () => ({
      job,
      setJob,
      candidates,
      loading,
      appliedCandidates,
      notAppliedCandidates,
      refresh,
    }),
    [
      candidates,
      loading,
      appliedCandidates,
      notAppliedCandidates,
      refresh,
      job,
      setJob,
    ],
  );

  return (
    <TeamJobCandidatesContext.Provider value={contextValue}>
      {children}
    </TeamJobCandidatesContext.Provider>
  );
};

export const useTeamJobCandidates = (job) => {
  const context = useContext(TeamJobCandidatesContext);
  const { job: contextJob, setJob } = context;

  // in the mobile app the provider is wrapping the whole app,
  // so we have to update provider's state using this hook inside the screen.
  useEffect(() => {
    if (
      !contextJob ||
      contextJob?.id !== job?.id ||
      contextJob?.client !== job?.client
    ) {
      setJob(job);
    }
  }, [contextJob, job, setJob]);

  return context;
};

export const useTeamJobCandidatesContext = () =>
  useContext(TeamJobCandidatesContext);
