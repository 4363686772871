import {
  createContext,
  useCallback,
  useReducer,
  useMemo,
  useContext,
} from 'react';

const initialState = { viewMode: null };

const reducer = (state = initialState, { viewMode, newData }) => {
  return viewMode ? { ...state, ...newData, viewMode } : initialState;
};

export const DrawerContext = createContext({
  viewMode: null,
  setDrawerView: () =>
    console.warn(
      'It seems you have forgot to wrap your page with DrawerProvider.',
    ),
});

export const DrawerProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer);

  const setDrawerView = useCallback(
    (viewMode, newData) => dispatch({ viewMode, newData }),
    [],
  );

  const closeDrawer = useCallback(() => dispatch({ viewMode: null }), []);

  const contextValue = useMemo(
    () => ({ ...state, setDrawerView, closeDrawer }),
    [state, setDrawerView, closeDrawer],
  );
  return (
    <DrawerContext.Provider value={contextValue}>
      {children}
    </DrawerContext.Provider>
  );
};

export const useDrawerContext = () => useContext(DrawerContext);
