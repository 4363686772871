import merge from 'lodash/merge';
import parseISO from 'date-fns/parseISO';
import isPlainObject from 'lodash/isPlainObject';

import services from '../utils/services';
import { Attachments } from '../common/Attachments';
import { JOB_TYPES } from './constants';
import { WORK_FORMAT } from '../constants';

export class Job {
  static initialValues = {
    name: '',
    type: JOB_TYPES.FULL_TIME,
    parent: null,
    workFormat: WORK_FORMAT.REMOTE,
    talentCountries: [],
    attachments: [],
    regions: [],
    seniority: 0,
    order: 0,
    published: true,
  };

  /**
   * @private
   */
  _apiData;
  /**
   * @private
   */
  _firebaseData;

  _id;
  _ref;

  /**
   * Wether this job is not saved yet in firebase.
   * @private
   */
  isDraft = true;

  constructor() {
    return new Proxy(this, {
      get(job, fieldName) {
        return fieldName in job
          ? job[fieldName]
          : job.firebaseData[fieldName] || job.apiData[fieldName];
      },
    });
  }

  /**
   * FirestoreDocumentConverter implementation
   */
  static converter = {
    /**
     * Converts Job instance into firestore document data.
     * @param {Job} job Job instance
     */
    toFirestore(job) {
      return job instanceof Job ? job.firebaseData : job;
    },
    /**
     * Creates Job instance from DocumentSnapshot.
     * @param {DocumentSnapshot} snapshot Firestore snapshot of the job document.
     */
    fromFirestore(snapshot) {
      return Job.fromSnapshot(snapshot);
    },
  };

  /**
   * Returns data of the firebase-stored profile.
   */
  get firebaseData() {
    return this._firebaseData || {};
  }

  /**
   * Set the data for firebase job.
   */
  set firebaseData({ id, __ref, _ref, ...data }) {
    for (let key in data) {
      if (data[key] instanceof services.get('firestore').Timestamp) {
        data[key] = data[key].toDate();
      }
    }

    this._firebaseData = data;

    if (id) {
      this._id = id;
    }
    if (__ref || _ref) {
      this._ref = (__ref || _ref).withConverter(Job.converter);
    }
    if (!this._ref && this._id) {
      this._ref = services
        .get('db')
        .doc(`jobs/${id}`)
        .withConverter(Job.converter);
    }
  }

  get apiData() {
    return this._apiData || {};
  }

  set apiData(value) {
    this._apiData = value;

    if (value.external_id && !this._id) {
      this._ref = services
        .get('db')
        .doc(`jobs/${value.external_id}`)
        .withConverter(Job.converter);
      this._id = value.external_id;
      this.isDraft = false;
    }
    if (!this._firebaseData) {
      this._firebaseData = {
        externalId: value.id,
        name: value.title,
        location: value.country_code || '',
        workload: value.workload || 40,
        quantity: value.quantity || 1,
      };
    }
  }

  get id() {
    return this._id;
  }

  get key() {
    return this._id || this.externalId;
  }

  get ref() {
    return this._ref;
  }

  /**
   * Creates new Job instance from the firestore's DocumentSnapshot.
   * @param {DocumentSnapshot} snapshot Firestore DocumentSnapshot of the job.
   */
  static fromSnapshot(snapshot) {
    const job = new Job();
    job.firebaseData = snapshot.data();
    job._id = snapshot.id;
    job._ref = snapshot.ref.withConverter(Job.converter);
    job.isDraft = false;

    return job;
  }

  /**
   * Creates Job instance from api data
   * @param   {Object}  data  Data from API
   * @return  {Job} Job instance
   */
  static fromApi(data) {
    const job = new Job();
    job.apiData = data;

    return job;
  }

  static isJob(job) {
    return (
      job instanceof Job ||
      (isPlainObject(job) && Object.values(JOB_TYPES).includes(job.type))
    );
  }

  /**
   * Upload attachments, and remove previous attachments from the storage if we update request.
   */
  async uploadAttachments(attachments) {
    const att = new Attachments(attachments, `jobs/${this.id}`);
    await att.uploadNew();
    if (!this.isDraft) {
      await att.removeOrphaned(this._firebaseData.attachments);
    }
    return att.toFirestore();
  }

  /**
   * Transform values and upload attachments
   * @param   {Object}  values  Raw values
   * @return  {Object} Transformed values
   */
  async transformValues(values) {
    const { attachments, quantity, workload, ...props } = values;
    if (this.isDraft) {
      this._ref = services
        .get('db')
        .collection('jobs')
        .doc()
        .withConverter(Job.converter);
      this._id = this._ref.id;
    }

    if (props.workFormat === WORK_FORMAT.ON_SITE) {
      if (this.isDraft) {
        delete props.regions;
        delete props.talentCountries;
      } else {
        props.regions = services.get('firestore').FieldValue.delete();
        props.talentCountries = services.get('firestore').FieldValue.delete();
      }
    }

    const uploadedAttachments = await this.uploadAttachments(attachments);
    return {
      ...props,
      quantity: Number(quantity) || 1,
      workload: Number(workload) || 40,
      attachments: uploadedAttachments,
      timestamp: services.get('now')(),
    };
  }

  /**
   * Creates or updates the profile in firebase.
   *
   * Creates the document and updates the id and _ref fields.
   *
   * @param {Object} values Payload of fields to write into firestore.
   * @return {Job} this
   */
  async save(values = {}) {
    const payload = await this.transformValues(
      // if it's not yet stored in firebase, we need to save also basic data from apiData.
      this.isDraft ? { ...this.firebaseData, ...values } : values,
    );

    if (this.isDraft) {
      await this._ref.withConverter(null).set(payload);
    } else {
      await this._ref.withConverter(null).update(payload);
    }
    this.firebaseData = merge({}, this.firebaseData, payload);

    this.isDraft = false;

    return this;
  }

  get startDate() {
    return (
      this.firebaseData.startDate ||
      (this.apiData.start_date ? parseISO(this.apiData.start_date) : null)
    );
  }
  get dueDate() {
    return (
      this.firebaseData.dueDate ||
      (this.apiData.due_date ? parseISO(this.apiData.due_date) : null)
    );
  }

  get externalId() {
    return this.firebaseData.externalId || this.apiData.id;
  }

  get workFormat() {
    return this.firebaseData.workFormat || this.apiData.work_format;
  }

  get location() {
    return this.firebaseData.location || this.apiData.country_code;
  }
}
