import { createContext, useContext, useMemo } from 'react';
import { useQuery } from 'react-query';

import { useAuth } from '@yougig/shared/auth/AuthProvider';
import {
  useTalentAssignments,
  useTalentProfile,
} from '@yougig/shared/candidates/useTalent';
import { useApi } from '@yougig/shared/api/Api';

export const TalentContext = createContext({});

export const TalentProvider = ({ children }) => {
  const { profile: account } = useAuth();
  const { apiClient } = useApi();
  const [profile] = useTalentProfile(account);
  const { data: candidate } = useQuery(
    ['candidates', account.profile?.id],
    async () => {
      const response = await apiClient.get('candidates/by-ids', {
        params: { id_list: account.profile?.id },
      });
      return response.data.result?.[0];
    },
    { enabled: !!account.profile?.id },
  );

  const { candidates: clients, assignments } = useTalentAssignments(profile);
  const contextValue = useMemo(
    () => ({ profile, clients, assignments, candidate }),
    [profile, clients, assignments, candidate],
  );

  return (
    <TalentContext.Provider value={contextValue}>
      {children}
    </TalentContext.Provider>
  );
};

export const useTalent = () => useContext(TalentContext);
