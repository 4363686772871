import { Options } from '../utils/Options';
import { COLORS } from '../constants';

export const PROJECT_STATUS = {
  DRAFT: 'draft',
  REQUESTED: 'requested',
  RECRUITING: 'recruiting',
  FINISHED: 'finished',
  WORKING: 'working',
  ARCHIVED: 'archived',
};

export const PROJECT_STATUS_OPTIONS = new Options(
  {
    value: PROJECT_STATUS.DRAFT,
    label: 'Draft',
    color: COLORS.DESCRIPTION,
    background: COLORS.LIGHT_GREY,
  },
  {
    value: PROJECT_STATUS.RECRUITING,
    label: 'Recruiting',
    color: COLORS.ORANGE,
    background: COLORS.LIGHT_ORANGE,
  },
  {
    value: PROJECT_STATUS.REQUESTED,
    label: 'Requested',
    color: COLORS.PERSIAN_BLUE,
    background: COLORS.LIGHT_BLUE,
  },
  {
    value: PROJECT_STATUS.ARCHIVED,
    label: 'Archived',
    color: COLORS.TEXT,
    background: COLORS.LIGHT_GREY,
  },
  {
    value: PROJECT_STATUS.FINISHED,
    label: 'Finished',
    color: COLORS.TEXT,
    background: COLORS.LIGHT_GREY,
  },
  {
    value: PROJECT_STATUS.WORKING,
    label: 'Working',
    color: COLORS.PRIMARY,
    background: COLORS.LIGHT_GREEN,
  },
);

export const PROJECT_TYPES = {
  PROJECT: 'project',
  TEAM: 'team',
};

export const PROJECT_TYPES_CONFIG = new Options(
  {
    label: 'Project',
    value: PROJECT_TYPES.PROJECT,
    type: 'project',
    icon: 'rocket',
  },
  { label: 'Team', value: PROJECT_TYPES.TEAM, type: 'project', icon: 'team' },
);

export const PROJECT_TERMS = {
  TIME_MATERIALS: 'time_materials',
  FIXED_BUDGET: 'fixed_budget',
};

export const PROJECT_TERMS_OPTIONS = new Options(
  {
    label: 'Time & Materials',
    value: PROJECT_TERMS.TIME_MATERIALS,
  },
  {
    label: 'Fixed Budget',
    value: PROJECT_TERMS.FIXED_BUDGET,
  },
);

export const EXPERIENCE_OPTIONS = new Options(
  { value: '1-2year', label: '1-2 years' },
  { value: '2-3year', label: '2-3 years' },
  { value: '3-5year', label: '3-5 years' },
  { value: '5-10year', label: '5-10 years' },
);
