import PropTypes from 'prop-types';
import { Dropdown, Menu, Button, Typography } from 'antd';
import { useCallback } from 'react';

import { CV_STATUS } from '@yougig/shared/talents/constants';
import { MoreDots } from '@yougig/ui/icons';
import { Profile } from '@yougig/shared/talents/Profile';
import { useAuth } from '@yougig/shared/auth/AuthProvider';
import { useDrawerContext } from '@yougig/ui/components/common/DrawerProvider';

/**
 * @param {{ profile: Profile }} props
 */
export const PeopleTableActions = ({ profile }) => {
  const { profile: account } = useAuth();
  const { setDrawerView } = useDrawerContext();

  const onMenuItemClick = useCallback(
    ({ key, domEvent }) => {
      domEvent.stopPropagation();
      switch (key) {
        case 'invite':
          setDrawerView('invite', { profile, width: 400 });
          break;
        case 'restore':
          profile.restore();
          break;
        case 'remove':
          profile.removeFromTeam(account.tenant);
          break;
        default:
          break;
      }
    },
    [profile, account.tenant, setDrawerView],
  );

  return (
    <Dropdown
      type="ghost"
      placement="bottomLeft"
      trigger="click"
      overlayClassName="ant-dropdown--fixed"
      overlay={
        <Menu onClick={onMenuItemClick}>
          {!profile.account && profile.status !== CV_STATUS.INVITED && (
            <Menu.Item key="invite">
              Invite{' '}
              <Typography.Text type="secondary">(self-manage)</Typography.Text>
            </Menu.Item>
          )}
          {profile.status === CV_STATUS.DELETED ? (
            <Menu.Item key="restore">Restore</Menu.Item>
          ) : (
            <Menu.Item key="remove">Remove from team</Menu.Item>
          )}
        </Menu>
      }>
      <Button
        type="link"
        size="small"
        icon={<MoreDots />}
        onClick={(e) => e.stopPropagation()}
      />
    </Dropdown>
  );
};

PeopleTableActions.propTypes = {
  profile: PropTypes.instanceOf(Profile).isRequired,
};
