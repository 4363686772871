import { Table as AntTable } from 'antd';
import styled from 'styled-components';

export const Table = styled(AntTable)`
  margin: 0 -16px;
  max-width: calc(100% + 32px);

  .ant-table {
    border-radius: 0;
    background-color: transparent;

    @media (max-width: 767px) {
      overflow: auto;
    }

    .ant-table-thead
      > tr
      > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
      background-color: transparent;
    }

    & table {
      border-radius: 10px;
      background-color: #fff;
      border: 3px solid var(--c_light_grey);
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
    }

    &-container {
      display: flex;
    }

    &-content {
      padding: 0 16px;
      flex-basis: 0;
      flex-grow: 1;
    }

    &-container table > thead {
      & > tr {
        & th {
          border: none;
          background-color: var(--c_ghost_white);
          text-transform: uppercase;
          color: var(--c_text);
          font-size: 12px;
          font-weight: 600;
          padding-top: 12px;
          padding-bottom: 10px;
        }
        &:first-child {
          th:first-child.cell-heading + th,
          th:first-child {
            border-radius: 10px 0 0;
            border-left-width: 3px;
          }
          th:last-child {
            border-radius: 0 10px 0 0;
            border-right-width: 3px;
          }
        }
      }
    }

    &-tbody > tr > td {
      border: none;
      padding-top: 20px;
      padding-bottom: 20px;
      color: var(--c_text);
      font-size: 12px;
      vertical-align: top;
      cursor: ${({ onRow }) => (onRow ? 'pointer' : 'default')};

      a[href^='tel'] {
        color: var(--c_description);
      }

      @media (max-width: 767px) {
        padding-top: 10px;
        padding-bottom: 10px;
        min-width: 100px;
      }
    }

    .ant-table-row--sub-heading > td:first-child {
      padding: 12px 40px;
      font-size: 12px;
      color: var(--c_description);
      text-transform: uppercase;
      &:hover {
        background: none;
      }
    }

    .ant-table-row {
      & > td {
        border-top: 1px solid transparent;
        border-bottom: 1px solid var(--c_light_grey);
        &:first-child {
          border-left: 3px solid transparent;
          padding-left: 13px;
        }
        &:last-child {
          border-right: 3px solid transparent;
          padding-right: 13px;
        }
      }

      &:last-child {
        > td {
          border-bottom-width: 0;
          &:first-child {
            border-bottom-left-radius: 10px;
          }
          &:last-child {
            border-bottom-right-radius: 10px;
          }
        }
      }

      &.ant-table-row--selected {
        > td {
          border-color: var(--c_primary) !important;
        }
      }
    }

    .icon-expanded {
      margin-right: 8px;
      transition: all 0.2s;
      vertical-align: top;
      cursor: pointer;
      color: var(--c_hint);
      margin-top: 4px;
      &.active {
        transform: rotate(180deg);
      }
      &.hidden {
        opacity: 0;
      }
    }

    th.ant-table-cell.cell-heading {
      display: none;
    }

    .ant-table-cell {
      &.cell-candidate {
        width: 250px;
      }
      &.cell-occupation {
        width: 160px;
      }
      &.cell-scoring {
        width: 100px;
      }
      &.cell-location {
        width: 80px;
      }
      &.cell-rate {
        width: 120px;
        &:last-child {
          width: auto;
        }
      }
      &.cell-skills,
      &.cell-notes {
        width: auto;
      }
      &.cell-availability {
        width: 115px;
      }
      &.cell-date {
        width: 115px;
      }
      &.cell-actions-stat {
        width: 140px;
      }
      &.actions {
        width: 190px;
        white-space: nowrap;
      }
      @media (max-width: 1024px) {
        &.cell-scoring {
          width: 60px;
        }
        &.cell-location {
          width: 60px;
        }
      }
      @media (max-width: 991px) {
        &.cell-occupation {
          width: 110px;
        }
        &.cell-candidate {
          width: 150px;
          max-width: 150px;
        }
      }
      @media (max-width: 767px) {
        &.cell-occupation {
          width: 160px;
        }
        &.cell-skills,
        &.cell-notes {
          min-width: 200px;
        }
      }
    }
  }

  td.actions {
    text-align: right;
    position: relative;
    .ant-btn + .ant-btn {
      margin-left: 10px;
    }
  }

  td span.info {
    color: var(--c_description);
  }

  @media (max-width: 767px) {
    .ant-table-thead > tr > th,
    .ant-table-tbody > tr > td,
    .ant-table tfoot > tr > th,
    .ant-table tfoot > tr > td {
      &:not(:first-child) {
        padding-left: 8px;
      }

      &:not(:last-child) {
        padding-right: 8px;
      }
    }
  }
`;
