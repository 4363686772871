import { useMemo, useEffect, useState } from 'react';

import services from '../utils/services';
import { useSortRequests } from './useSortRequests';
import { Request } from './Request';
import { REQUEST_STATUS, REQUEST_TYPES } from './constants';

/**
 * Get all talent's requests(actions, interviews) from firestore .
 * @param {Candidate} profile Profile instance
 * @param {Object} [options] Set of options for the hook.
 * @param {DocumentReference} [options.sortByClient] If provided, requests of this client will be placed at the start.
 * @returns {Array<Request | Interview>} List of requests and interviews
 */
function useTalentRequests(profile, { sortByClient } = {}) {
  const [actions, setActions] = useState([]);

  useEffect(() => {
    return services
      .get('db')
      .collection('actions')
      .where('consultant', '==', profile.ref)
      .withConverter(Request.converter)
      .onSnapshot(async (snaps) => {
        const actions = await Promise.all(
          snaps.docs.map(async (snap) => {
            const action = snap.data();
            if (action.client) {
              const clientSnap = await action.client.get();
              action.companyName = clientSnap.get('businessName');
            }
            return action;
          }),
        );
        setActions(actions);
      });
  }, [profile]);

  const sortedRequests = useSortRequests(actions);

  return useMemo(() => {
    return sortedRequests.sort((a, b) => {
      if (a.companyName === b.companyName && a.client?.isEqual(sortByClient)) {
        return 0;
      } else if (a.client?.isEqual(sortByClient)) {
        return -1;
      } else {
        return 1;
      }
    });
  }, [sortedRequests, sortByClient]);
}

/**
 * Get all candidate's requests(actions, interviews) from firestore .
 * @param {import('@yougig/shared/talents/Profile').Profile} profile Profile instance
 * @param {Object} options Query options
 * @param {boolean} [options.withBusinessName] Whether to include business name in the request object
 * @param {DocumentReference} [options.clientRef] Filter by client.
 * @param {REQUEST_TYPES} [options.type] Filter by request type.
 * @returns {Array<Request>} List of requests and interviews
 */
function useProfileRequests(profile, options) {
  const [actions, setActions] = useState([]);

  const query = useMemo(() => {
    if (!profile?.ref) {
      return;
    }

    let baseQuery = services
      .get('db')
      .collection('actions')
      .where('consultant', '==', profile.ref)
      .where(
        'status',
        'in',
        Object.values(REQUEST_STATUS).filter((r) => r !== REQUEST_STATUS.DRAFT),
      )
      .withConverter(Request.converter);

    if (options.clientRef) {
      baseQuery = baseQuery.where('client', '==', options.clientRef);
    }

    if (options.type) {
      baseQuery = baseQuery.where('type', '==', options.type);
    }

    return baseQuery;
  }, [options, profile]);

  useEffect(() => {
    return (
      query &&
      query.onSnapshot(async (snaps) => {
        const actions = await Promise.all(
          snaps.docs.map(async (snap) => {
            const action = snap.data();
            if (action.client) {
              const clientSnap = await action.client.get();
              action.companyName = clientSnap.get('businessName');
            }
            return action;
          }),
        );
        setActions(actions);
      })
    );
  }, [query, options]);

  return useSortRequests(actions);
}

/**
 * Create new Request.
 * @param {import('@yougig/shared/talents/Profile').Profile} profile Profile instance
 * @param {DocumentReference} sentBy Reference to the firestore document of the user
 * @param {REQUEST_TYPES} type Request type ('info', 'interview')
 * @returns {Request} Request instance (not saved to DB)
 */
const createRequest = (
  profile,
  sentBy,
  type = REQUEST_TYPES.INFO,
  client = null,
) => {
  if (profile.isDraft) {
    throw new Error('Profile should be saved before creating request.');
  }

  return new Request({
    sentBy,
    client,
    consultant: profile.ref,
    type,
  });
};

export class RequestService {
  static useTalentRequests = useTalentRequests;
  static useProfileRequests = useProfileRequests;
  static createRequest = createRequest;
}
