import { Link } from 'react-router-dom';
import { Popover, Typography } from 'antd';
import formatDate from 'date-fns/format';
import addMonths from 'date-fns/addMonths';

import { Card } from '@yougig/ui/components/elements/Card';
import { Location, Skills } from '@yougig/ui/icons';

export const JobCard = ({ job }) => (
  <Card className="job" as={Link} to={`/jobs/${job.key}`}>
    <Typography.Title level={3} ellipsis>
      {job.title}
    </Typography.Title>
    {!!job.country && (
      <div className="job__location">
        <Location />
        <Typography.Text ellipsis>{job.country}</Typography.Text>
      </div>
    )}
    {!!job.description && (
      <Popover content={job.description} arrowPointAtCenter placement="topLeft">
        <Typography.Paragraph
          className="job__description"
          ellipsis={{
            rows: 2,
            expandable: false,
          }}>
          {job.description}
        </Typography.Paragraph>
      </Popover>
    )}
    <div className="job__details">
      {!!job.due_date && (
        <div className="job__info-item">
          <div className="job__info-item__label">Request Due Date:</div>
          <div className="job__info-item__value">
            {formatDate(new Date(job.due_date), 'dd.MM.yyyy')}
          </div>
        </div>
      )}
      {(job.startDate || job.duration) && (
        <div className="job__info-item">
          <div className="job__info-item__label">Period:</div>
          <div className="job__info-item__value">
            {!!job.startDate &&
              formatDate(new Date(job.startDate), 'dd.MM.yyyy')}
            {!!job.startDate && !!job.duration && ' — '}
            {!!job.startDate &&
              !!job.duration &&
              formatDate(
                addMonths(new Date(job.startDate), job.duration),
                'dd.MM.yyyy',
              )}
            {!job.startDate && !!job.duration && `${job.duration} months`}
          </div>
        </div>
      )}
    </div>
    {job.skills?.length > 0 && (
      <Popover
        content={
          <div className="job__skills__popover">{job.skills.join(', ')}</div>
        }
        arrowPointAtCenter
        placement="topLeft">
        <div className="job__skills">
          <Skills />
          <Typography.Paragraph ellipsis={{ rows: 2 }}>
            {job.skills.join(', ')}
          </Typography.Paragraph>
        </div>
      </Popover>
    )}
  </Card>
);
