import { useState, useEffect, useMemo } from 'react';

import { useAuth } from '../auth/AuthProvider';
import { Request } from '../actions/Request';
import { Profile } from '../talents/Profile';
import { useCandidates } from '../candidates/CandidatesProvider';
import services from '../utils/services';
import { REQUEST_TYPES } from '../actions/constants';

// we cannot define this base query as a constant because
// services are not instantiated when the module is loaded first time.
const getInterviewsBaseQuery = () =>
  services
    .get('db')
    .collection('actions')
    .where('type', '==', REQUEST_TYPES.INTERVIEW)
    .orderBy('dateTime', 'asc')
    .withConverter(Request.converter);

const useInterviewsTransform = (query) => {
  const { getCandidateById } = useCandidates();
  const { profile, isCandidate } = useAuth();

  const [interviews, setInterviews] = useState([]);

  useEffect(() => {
    const unsubscribe = (
      isCandidate
        ? query.where('consultant', '==', profile.profile)
        : query.where('client', '==', profile.tenant)
    ).onSnapshot(async (snap) => {
      if (!snap) {
        return;
      }

      try {
        const data = await Promise.all(
          snap.docs.map(async (interviewSnap) => {
            const interview = interviewSnap.data();

            if (isCandidate) {
              const businessName = interview.client
                ? (await interview.client.get()).get('businessName')
                : '';
              const dateTime = interview.dateTime;
              return { interview, businessName, dateTime };
            } else {
              const _profile =
                getCandidateById(interview.consultant.id)?.profile ||
                (
                  await interview.consultant
                    .withConverter(Profile.converter)
                    .get()
                ).data();

              return { profile: _profile, interview };
            }
          }),
        );

        setInterviews(data.filter(Boolean));
      } catch (e) {
        console.error(e);
      }
    });

    return () => unsubscribe();
  }, [isCandidate, getCandidateById, query, profile?.tenant, profile?.profile]);

  return interviews;
};

export const useComingInterviews = (limit = 5) => {
  const query = useMemo(
    () =>
      getInterviewsBaseQuery()
        .where('dateTime', '>', services.get('now')())
        .limit(limit),
    [limit],
  );

  return useInterviewsTransform(query);
};

export const usePastInterviews = () => {
  const query = useMemo(
    () =>
      getInterviewsBaseQuery()
        .where('dateTime', '<', services.get('now')())
        .limit(1),
    [],
  );

  return useInterviewsTransform(query);
};

export const useInterviews = () => {
  const interviews = useInterviewsTransform(getInterviewsBaseQuery());

  const comingInterviews = useMemo(
    () => interviews.filter(({ interview }) => interview.dateTime > Date.now()),
    [interviews],
  );

  const pastInterviews = useMemo(
    () =>
      interviews
        .filter(({ interview }) => interview.dateTime < Date.now())
        .sort((a, b) => b.interview.dateTime - a.interview.dateTime),
    [interviews],
  );

  const onlyPast = comingInterviews.length === 0 && pastInterviews.length > 0;

  return useMemo(
    () => ({ interviews, comingInterviews, pastInterviews, onlyPast }),
    [interviews, comingInterviews, pastInterviews, onlyPast],
  );
};
